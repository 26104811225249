import { features } from "process";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import {
  configDataConstants,
  configurationConstants,
} from "../store/actions/types";
import { Feature, Option, Rule, UserConfiguration } from "../types/types";
const mco = "Modesty Options";
const LegsFeatureName = "Legs";
const TableTopFeatureName = "Table Top";
const ElectricalFeatureName = "Wire Management";
const PrivacyFeatureName = "Privacy";
const sps = "Return Privacy Screen";
const tc = "Tile Color";
const spco = "Side Privacy Color Option";
const cl = "Cover Leg";
const Raceway = "RACEWAY";
const pco = "Privacy Return Color Option";
const pmo = "Privacy Main Color Option";
const AccessoriesFeatureName = "Accessories";
const MetalFeatureName = "Powder Coat Options";
const mpco = "Main Privacy Color Option";
const PowderCoating = "Powdercoating";
const ColorOptionsPrivacy = "Color Options Privacy";
const GlassesFeatureName = "Glass Color";
const FabricFeatureName = "Fabric Color";
const Material = "Material";
const Size = "Size";
const eps = "End Privacy Screen";
const mps = "Main Privacy Screen";
const rms = "Raceway Mounted Screen";
const tms = "Table Top Mounted Screen";
const Shutter = "Shutter";
const LamColor = "Laminate Options";
const _1Comp = "1 Compartment";
const _2Comp = "2 Compartment";
const _3Comp = "3 Compartment";
const System = "System";
const Dimensions = "Dimensions";
const PowdercoatFront = "Primary Color";
const PowdercoatSide = "Secondary Color";
const WoodFeatureName = "Laminate";
const PedestalFeatureName = "Pedestal";
const Facia = "Facia Powder Coat";
const Carcass = "Carcass Powder Coat";
const PedestalFeatureLegName = "Leg";
const StoragesHandle = "Handle";
const PedestalFeatureHandleName = "Handles";
const PedestalFeatureLockName = "Lock";
const PedestalFeatureCushionName = "Cushion";
const PedestalFeatureDrawerWoodName = "Facia Wood";
const PedestalFeatureSideWoodName = "Carcass Wood";
const PedestalFeatureTopWoodName = "Top Wood";
const PedestalFeatureFabricName = "Fabric";
const SideTableFeatureName = "Side Table";
const LegColorFeatureName = "Color Options";

console.log("done");
const useUserConfiguration = () => {
  const dispatch = useAppDispatch();

  const userConfiguration2: any = useAppSelector(
    (state) => state.configuration.userConfiguration2
  );

  const specs: Feature[] = useAppSelector((state) => state.configData.current);
  const specsOriginal: Feature[] = useAppSelector(
    (state) => state.configData.original
  );

  const enableDisableOptions = (option: Option) => {
    const { disableFeatures, enableFeatures, disableOptions, enableOptions } =
      option;
    if (disableFeatures) {
      dispatch({
        type: configurationConstants.DISABLE_FEATURES,
        payload: disableFeatures,
      });
    }

    if (enableFeatures) {
      dispatch({
        type: configurationConstants.ENABLE_FEATURES,
        payload: enableFeatures,
      });
    }

    if (disableOptions) {
      dispatch({
        type: configurationConstants.DISABLE_OPTIONS,
        payload: disableOptions,
      });
    }

    if (enableOptions) {
      dispatch({
        type: configurationConstants.ENABLE_OPTIONS,
        payload: enableOptions,
      });
    }
  };

  const removeDependentFeatures = (value: Feature & { option: Option }) => {
    (value.option?.options as Feature[] | undefined)?.forEach((subFeature) => {
      const option =
        userConfiguration2[subFeature.name as keyof UserConfiguration];
      if (option) {
        !(subFeature.options as Option[])
          .map((_option) => _option.name)
          .includes(option) &&
          dispatch({
            type: configurationConstants.DELETE_PROPERTY,
            payload: subFeature.name,
          });
      }
    });
  };

  const updateUserConfiguration = (
    featureName: string,
    value: Feature & { option: Option }
  ) => {
    enableDisableOptions(value.option);
    removeDependentFeatures(value);
    console.log(featureName);
    console.log(value);
    switch (featureName) {
      case "System":
        console.log("came");
        const newConfigData = specsOriginal.map((item: any) => {
          const filteredOptions = item.options
            .filter((option: any) => {
              if (item.name === "System") return true;
              return option.name === value.name;
            })
            .map((option: any) => ({ ...option }));
          if (item.name === "System")
            return { ...item, options: filteredOptions };

          return {
            ...item,
            options: filteredOptions[0]?.options,
            rules: filteredOptions[0]?.rules,
          };
        });

        dispatch({
          type: configDataConstants.SET_CURRENT_CONFIG_DATA,
          payload: newConfigData,
        });

        dispatch({
          type: configurationConstants.UPDATE_CONFIGURATION2,
          payload: {
            type: value.name,
            [value.name]: value.option.name,
            noOfSeats: value.option.size,
            ...(value.option.id && { id: value.option.id }),
            ...(value.option?.name && { name: value.option?.name }),
            ...(value.option.Length && { Length: value.option.Length }),
            ...(value.option.Depth && { Depth: value.option.Depth }),
            ...(value.option.Dia && { Dia: value.option.Dia }),
            ...(value.option.table && { table: value.option.table }),
          },
        });

        break;
      case "Dimensions":
        dispatch({
          type: configurationConstants.UPDATE_CONFIGURATION2,
          payload: { [value.name]: value.option.value },
        });
        break;
      case AccessoriesFeatureName:
      case MetalFeatureName:
      case GlassesFeatureName:
      case FabricFeatureName:
      case Raceway:
      case _1Comp:
      case _2Comp:
      case _3Comp:
      case Facia:
      case Carcass:
      case PowdercoatFront:
      case LamColor:

      case Shutter:
      case Material:
      case PowdercoatSide:
      case StoragesHandle:
      case PedestalFeatureLegName:
      case PedestalFeatureLockName:
      case PedestalFeatureCushionName:
      case PedestalFeatureDrawerWoodName:
      case PedestalFeatureSideWoodName:
      case PedestalFeatureTopWoodName:
      case PedestalFeatureFabricName:
      case LegColorFeatureName:
      case ColorOptionsPrivacy:

      case SideTableFeatureName:
        let _value = value.option.name;

        if (value.multiSelect) {
          _value = userConfiguration2[featureName]?.includes(value.option.name)
            ? userConfiguration2[featureName].filter(
                (_value: string) => _value !== value.option.name
              )
            : [...(userConfiguration2[featureName] || []), value.option.name];
        }
        dispatch({
          type: configurationConstants.UPDATE_CONFIGURATION2,
          payload: { [featureName]: _value },
        });

        break;
      case spco:
      case pco:
      case tc:
      case pmo:
      case ElectricalFeatureName:
      case LegsFeatureName:
        dispatch({
          type: configurationConstants.UPDATE_CONFIGURATION2,
          payload: { [value.name]: value.option.name },
        });

        return;
      case TableTopFeatureName: /////
        if (value.option.name == "25 MM PLPB STANDARD TOP") {
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { [value.name]: value.option.name },
          });

          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Wire Manager for Chamfer Top": null },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Wire Manager for Postform Top": null },
          });

          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Wire Manager for Standard Top": "none" },
          });
        } else if (value.option.name == "25 MM PLPB CHAMFER EDGE WTOP") {
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { [value.name]: value.option.name },
          });

          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Wire Manager for Chamfer Top": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Wire Manager for Postform Top": null },
          });

          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Wire Manager for Standard Top": null },
          });
        } else if (value.option.name == "25 MM PLPB POSTFORM TOP") {
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { [value.name]: value.option.name },
          });

          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Wire Manager for Chamfer Top": null },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Wire Manager for Postform Top": "none" },
          });

          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Wire Manager for Standard Top": null },
          });
        } else {
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { [value.name]: value.option.name },
          });
        }

        return;
      case Size:
      case Dimensions:
      case System:
      case PowderCoating:
      case PedestalFeatureHandleName:
      case PedestalFeatureName:

      case "Pedestal Features":
      case "Prelam Pedestal Color Options":
      case "Metal Pedestal Color Options":
      case WoodFeatureName:
      case mco:
      case mps: {
        console.log(value.name);
        if (
          value.name == "30mm Frame Screen" ||
          value.name == "Frame Screen Main" ||
          value.name == "Sleek Screen Aluminum Beading Main" ||
          value.name == "Screen with Nylon Beading" ||
          value.name == "Glass Main" ||
          value.name == "Xbench Main" ||
          value.name == "Xbench with rail Main" ||
          value.name == "Full Panel Main" ||
          value.name == "Curvivo Panel Main" ||
          value.name == "Livo Panel Main"
        ) {
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "30mm Frame Screen": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Frame Screen Main": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Sleek Screen Aluminum Beading Main": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Screen with Nylon Beading": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Glass Main": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Xbench Main": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Xbench with rail Main": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Full Panel Main": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Curvivo Panel Main": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Livo Panel Main": "none" },
          });
        }
        dispatch({
          type: configurationConstants.UPDATE_CONFIGURATION2,
          payload: { [value.name]: value.option.name },
        });
        return;
      }
      case eps: {
        if (
          value.name == "30mm Frame Screen End" ||
          value.name == "30mm Frame Screen End Single" ||
          value.name == "Sleek Screen Aluminum Beading End" ||
          value.name == "Sleek Screen Aluminum Beading End Single" ||
          value.name == "Sleek Screen Nylon Beading End" ||
          value.name == "Sleek Screen Nylon Beading End Single" ||
          value.name == "Glass End" ||
          value.name == "Glass End Single" ||
          value.name == "Xbench End" ||
          value.name == "Xbench with rail End" ||
          value.name == "Full Panel End" ||
          value.name == "Curvivo Panel End" ||
          value.name == "Livo Panel End" ||
          value.name == "Livo Panel End Single" ||
          value.name == "Hanging Panel End"
        ) {
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "30mm Frame Screen End": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "30mm Frame Screen End Single": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Sleek Screen Aluminum Beading End": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Sleek Screen Aluminum Beading End Single": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Sleek Screen Nylon Beading End": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Sleek Screen Nylon Beading End Single": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Glass End": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Glass End Single": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Xbench End": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Xbench with rail End": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Full Panel End": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Curvivo Panel End": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Livo Panel End": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Livo Panel End Single": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Hanging Panel End": "none" },
          });
        }
        dispatch({
          type: configurationConstants.UPDATE_CONFIGURATION2,
          payload: { [value.name]: value.option.name },
        });
        return;
      }
      case sps: {
        if (
          value.name == "30mm Return Frame Screen" ||
          value.name == "30mm Return Frame Screen Single" ||
          value.name == "Sleek Screen Aluminum Beading Return" ||
          value.name == "Sleek Screen Aluminum Beading Return Single" ||
          value.name == "Sleek Screen Nylon Beading Return" ||
          value.name == "Sleek Screen Nylon Beading Return Single" ||
          value.name == "Glass Return" ||
          value.name == "Glass Return Single" ||
          value.name == "Xbench Return" ||
          value.name == "Xbench with rail Return" ||
          value.name == "Full Panel Return" ||
          value.name == "Curvivo Panel Return" ||
          value.name == "Livo Panel Return"
        ) {
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "30mm Return Frame Screen": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "30mm Return Frame Screen Single": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Sleek Screen Aluminum Beading Return": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Sleek Screen Aluminum Beading Return Single": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Sleek Screen Nylon Beading Return": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Sleek Screen Nylon Beading Return Single": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Glass Return": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Glass Return Single": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Xbench Return": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Xbench with rail Return": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Full Panel Return": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Curvivo Panel Return": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Livo Panel Return": "none" },
          });
          dispatch({
            type: configurationConstants.UPDATE_CONFIGURATION2,
            payload: { "Livo Panel Return Single": "none" },
          });
        }
        dispatch({
          type: configurationConstants.UPDATE_CONFIGURATION2,
          payload: { [value.name]: value.option.name },
        });
        return;
      }
      case mpco:
      case spco:
      case cl:
      case PrivacyFeatureName:
        dispatch({
          type: configurationConstants.UPDATE_CONFIGURATION2,
          payload: { [value.name]: value.option.name },
        });
        dispatch({
          type: configurationConstants.UPDATE_CONFIGURATION2,
          payload: {
            [AccessoriesFeatureName]: [],
          },
        });
        return;
      // case AccessoriesFeatureName:
      //   dispatch({
      //     type: configurationConstants.UPDATE_CONFIGURATION2,
      //     payload: { [value.name]: value.option.name },
      //   });
      //   dispatch({
      //     type: configurationConstants.UPDATE_CONFIGURATION2,
      //     payload: {
      //       [AccessoriesFeatureName]: [],
      //     },
      //   });
      //   return;

      default:
        break;
    }
  };

  const getSelectedOption = (
    featureName: string,
    subFeatureName: string
  ): Option[] => {
    console.log("yes", featureName, subFeatureName);
    const Feature: Feature | undefined = specs.find(
      (x: any) => x.name === featureName
    );
    if (!Feature) return [];
    switch (featureName) {
      case "System":
        if (subFeatureName !== userConfiguration2.type) {
          return [];
        }

        const system = Feature;
        const sharingOrNonSharing = (system.options as Feature[]).find(
          (x) => x.name === userConfiguration2.type
        );

        const selectedOption = (sharingOrNonSharing?.options as Option[]).find(
          (option) => option.size === userConfiguration2.noOfSeats
        );
        console.log(selectedOption);
        return selectedOption ? [selectedOption] : [];

      case "Dimensions":
        const subFeature: Option | undefined = (
          Feature.options as Option[]
        )?.find((x: any) => x.name === subFeatureName);
        const selectedOption2 = (
          subFeature?.options as Option[] | undefined
        )?.find(
          (option) =>
            option.value === userConfiguration2[subFeature?.name || ""]
        );
        console.log(selectedOption2);
        return selectedOption2 ? [selectedOption2] : [];

      case AccessoriesFeatureName:

      case SideTableFeatureName:
      case ColorOptionsPrivacy:
      case MetalFeatureName:
      case GlassesFeatureName:
      case FabricFeatureName:
      case Facia:
      case Carcass:
      case Raceway:
      case _1Comp:
      case _2Comp:
      case _3Comp:
      case Material:
      case Shutter:
      case PowdercoatFront:
      case LamColor:
      case PowdercoatSide:

      case StoragesHandle:
      case PedestalFeatureLegName:
      case PedestalFeatureLockName:
      case PedestalFeatureCushionName:
      case PedestalFeatureDrawerWoodName:
      case PedestalFeatureSideWoodName:
      case PedestalFeatureTopWoodName:
      case PedestalFeatureFabricName:
        if (Feature.multiSelect) {
          const selectedOption3 = (Feature.options as Option[]).filter(
            (x: any) => userConfiguration2[Feature.name]?.includes(x.name)
          );
          return selectedOption3;
        } else {
          const selectedOption3 = (
            Feature.options as Option[] | undefined
          )?.find((option) => option.name === userConfiguration2[Feature.name]);
          return selectedOption3 ? [selectedOption3] : [];
        }
      case Size:
      case System: {
        console.log(featureName);
        return [];
      }
      case Dimensions:
      case WoodFeatureName:
      case mco:
      case mps:
      case eps:
      case sps:
      case PowderCoating:
      case PrivacyFeatureName:
      case cl:
      case mpco:
      case spco:
      case PedestalFeatureName:
      case "Pedestal Features":
      case "Prelam Pedestal Color Options":
      case "Metal Pedestal Color Options":
      case PedestalFeatureHandleName:
      case "System":
      // case AccessoriesFeatureName:
      case pco:
      case tc:
      case pmo:
      case ElectricalFeatureName:
      case LegsFeatureName:

      case TableTopFeatureName:
        let subFeature2: Option | undefined = (
          Feature.options as Option[]
        )?.find((x: any) => x.name === subFeatureName);
        if (!subFeature2) {
          Feature.options?.forEach((x) => {
            if (getSelectedOption(featureName, x.name) instanceof Array) {
              getSelectedOption(featureName, x.name)?.forEach((option) => {
                option?.options?.forEach((z) => {
                  if (z.name === subFeatureName) {
                    subFeature2 = z as Option;
                  }
                });
              });
            }
          });
          //   console.log(subFeature2);
        }
        const selectedOption4 = (
          subFeature2?.options as Option[] | undefined
        )?.find((option) => {
          return option.name === userConfiguration2[subFeature2?.name || ""];
        });
        return selectedOption4 ? [selectedOption4] : [];

      default:
        return [];
    }
  };

  const getRules = () => {
    const rules: { [key: string]: Rule[] } = {};
    console.log(Object);
    Object.keys(userConfiguration2).forEach((key) => {
      console.log(key);
      switch (key) {
        case AccessoriesFeatureName:
        case MetalFeatureName:
        case GlassesFeatureName:
        case FabricFeatureName:
        case StoragesHandle:
        case PedestalFeatureLegName:
        case PedestalFeatureLockName:
        case PedestalFeatureCushionName:
        case Raceway:
        case _1Comp:

        case _2Comp:
        case Facia:
        case Carcass:
        case _3Comp:
        case Shutter:
        case Material:
        case PowdercoatFront:
        case LamColor:
        case PowdercoatSide:
        case PedestalFeatureDrawerWoodName:
        case PedestalFeatureSideWoodName:
        case PedestalFeatureTopWoodName:
        case PedestalFeatureFabricName:

        case SideTableFeatureName:
          console.log(key);
          const legs = specs.find((x: any) => x.name === key);
          const r1 = legs?.rules;
          const r2 = getSelectedOption(key, "")?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );

          rules[key] = [...(r1 || []), ...(r2 || [])];
          //append screen size to nodes

          // if (key === AccessoriesFeatureName) {
          //   const mainScreen = getSelectedOption(
          //     PrivacyFeatureName,
          //     "Main screen"
          //   );
          //   if (mainScreen && mainScreen.length > 0) {
          //     let screenSize =
          //       mainScreen &&
          //       mainScreen[0]?.name?.substr(mainScreen[0]?.name.length - 4, 4);
          //     screenSize = screenSize?.split("H")[0];

          //     rules[key] = rules[key].map((rule) => {
          //       return {
          //         ...rule,
          //         node: rule.node.map(
          //           (nodeName) => nodeName + "_" + screenSize
          //         ),
          //       };
          //     });
          //   } else {
          //     rules[key] = rules[key].map((rule) => {
          //       return {
          //         ...rule,
          //         node: rule.node.map((nodeName) => nodeName),
          //       };
          //     });
          //   }
          // }

          break;

        case "Raceway":
          const electrical = specs.find(
            (x: any) => x.name === ElectricalFeatureName
          );
          const r3 = (electrical?.options as Option[] | undefined)?.find(
            (x: any) => x.name === "Raceway"
          )?.rules;
          const r4 = getSelectedOption(
            ElectricalFeatureName,
            "Raceway"
          )?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[ElectricalFeatureName] = [...(r3 || []), ...(r4 || [])];
          return;

        case "Wire Access":
        case "Wire Entry":
          const emt = getSelectedOption(ElectricalFeatureName, "Raceway");
          const emtOption =
            emt &&
            (emt[0]?.options as Feature[] | undefined)?.find(
              (x: any) => x.name === key
            );
          const r5 = emtOption?.rules as Rule[] | undefined;
          const r6 = getSelectedOption(ElectricalFeatureName, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[ElectricalFeatureName] = [
            ...(rules[ElectricalFeatureName] || []),
            ...(r5 || []),
            ...(r6 || []),
          ];
          return;
        case "Legs":
          //  console.log("came");
          const emtLeg = getSelectedOption(LegsFeatureName, "Legs");
          const emtOptionLeg =
            emtLeg &&
            (emtLeg[0]?.options as Feature[] | undefined)?.find(
              (x: any) => x.name === key
            );
          const r7 = emtOptionLeg?.rules as Rule[] | undefined;
          const r8 = getSelectedOption(LegsFeatureName, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[LegsFeatureName] = [
            ...(rules[LegsFeatureName] || []),
            ...(r7 || []),
            ...(r8 || []),
          ];
          return;
        case "SHARING":
        case "NON SHARING":
          const privac1yytts = specs.find((x) => x.name === "System");
          const ms1stts = (privac1yytts?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          console.log(privac1yytts);
          console.log(ms1stts);
          const r19tts = ms1stts?.rules as Rule[] | undefined;
          const r110tts = getSelectedOption("System", key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          console.log(r19tts);
          console.log(r110tts);
          rules["System"] = [
            ...(rules["System"] || []),
            ...(r19tts || []),
            ...(r110tts || []),
          ];
          return;
        case "Table Top":
        case "Wire Manager for Chamfer Top":
        case "Wire Manager for Postform Top":
        case "Waterfall Raceway Options":
        case "Wire Manager for Standard Top":
          console.log(key);
          const privac1yytt = specs.find((x) => x.name === TableTopFeatureName);
          const ms1stt = (privac1yytt?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          console.log(privac1yytt);
          console.log(ms1stt);
          const r19tt = ms1stt?.rules as Rule[] | undefined;
          const r110tt = getSelectedOption(TableTopFeatureName, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          console.log(r19tt);
          console.log(r110tt);
          rules[TableTopFeatureName] = [
            ...(rules[TableTopFeatureName] || []),
            ...(r19tt || []),
            ...(r110tt || []),
          ];
          return;
        case "Pedestaltt":
          // console.log("came");
          const emtPed = getSelectedOption("Pedestal", "Pedestal");
          const emtOptionPed =
            emtPed &&
            (emtPed[0]?.options as Feature[] | undefined)?.find(
              (x: any) => x.name === key
            );
          const r77 = emtOptionPed?.rules as Rule[] | undefined;
          const r88 = getSelectedOption("Pedestal", key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[LegsFeatureName] = [
            ...(rules["Pedestal"] || []),
            ...(r77 || []),
            ...(r88 || []),
          ];
          return;

        case LegColorFeatureName:
          const emtLegColor = getSelectedOption(
            LegsFeatureName,
            LegColorFeatureName
          );
          const emtOptionLegColor =
            emtLegColor &&
            (emtLegColor[0]?.options as Feature[] | undefined)?.find(
              (x: any) => x.name === key
            );
          const r11 = emtOptionLegColor?.rules as Rule[] | undefined;
          const r12 = getSelectedOption(LegsFeatureName, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[LegsFeatureName] = [
            ...(rules[LegsFeatureName] || []),
            ...(r11 || []),
            ...(r12 || []),
          ];
          return;

        case ColorOptionsPrivacy:
          const fabricColor = getSelectedOption(
            LegsFeatureName,
            LegColorFeatureName
          );
          const fabricOptionLegColor =
            fabricColor &&
            (fabricColor[0]?.options as Feature[] | undefined)?.find(
              (x: any) => x.name === key
            );
          const r111 = fabricOptionLegColor?.rules as Rule[] | undefined;
          const r122 = getSelectedOption("Privacy", key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules["Privacy"] = [
            ...(rules["Privacy"] || []),
            ...(r111 || []),
            ...(r122 || []),
          ];
          return;
        case "Handle":
          console.log("came", key);
          const privacyyyH = specs.find(
            (x) => x.name === PedestalFeatureHandleName
          );
          //  console.log(privacyy);
          const msssH = (privacyyyH?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          // console.log(mss);
          const r909H = msssH?.rules as Rule[] | undefined;
          const r1009H = getSelectedOption(
            PedestalFeatureHandleName,
            key
          )?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          //  console.log(r90, r100);
          rules[PedestalFeatureHandleName] = [
            ...(rules[PedestalFeatureHandleName] || []),
            ...(r909H || []),
            ...(r1009H || []),
          ];
          //  console.log(rules);

          return;
        case "Fabric's COlor":
          const privacyyyPPChh = specs.find((x) => x.name === pmo);
          //  console.log(privacyy);
          const msssPPChh = (
            privacyyyPPChh?.options as Option[] | undefined
          )?.find((x) => x.name === key);
          // console.log(mss);
          const r909PPChh = msssPPChh?.rules as Rule[] | undefined;
          const r1009PPChh = getSelectedOption(pmo, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          //  console.log(r90, r100);
          rules[pmo] = [
            ...(rules[pmo] || []),
            ...(r909PPChh || []),
            ...(r1009PPChh || []),
          ];
          //  console.log(rules);
          return;
        case "Livo Highlighter's Color":
        case "Strip PowderCoat Color":
          const privacyyyPPCha = specs.find((x) => x.name === "Legs");
          //  console.log(privacyy);
          const msssPPCha = (
            privacyyyPPCha?.options as Option[] | undefined
          )?.find((x) => x.name === key);
          // console.log(mss);
          const r909PPCha = msssPPCha?.rules as Rule[] | undefined;
          const r1009PPCha = getSelectedOption("Legs", key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          //  console.log(r90, r100);
          rules["Legs"] = [
            ...(rules["Legs"] || []),
            ...(r909PPCha || []),
            ...(r1009PPCha || []),
          ];
          //  console.log(rules);
          return;
        case "Laminate":
        case "Fabric's Color":
        case "Powder Coating":
          const privacyyyPPChtc = specs.find((x) => x.name === tc);
          //  console.log(privacyy);
          const msssPPChtc = (
            privacyyyPPChtc?.options as Option[] | undefined
          )?.find((x) => x.name === key);
          // console.log(mss);
          const r909PPChtc = msssPPChtc?.rules as Rule[] | undefined;
          const r1009PPChtc = getSelectedOption(tc, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          //  console.log(r90, r100);
          rules[tc] = [
            ...(rules[tc] || []),
            ...(r909PPChtc || []),
            ...(r1009PPChtc || []),
          ];
          //  console.log(rules);
          return;
        case "Fabric's Color":
        case "Fabrics":
        case "Glass's Color":
          const privacyyyPPCh = specs.find((x) => x.name === pco);
          //  console.log(privacyy);
          const msssPPCh = (
            privacyyyPPCh?.options as Option[] | undefined
          )?.find((x) => x.name === key);
          // console.log(mss);
          const r909PPCh = msssPPCh?.rules as Rule[] | undefined;
          const r1009PPCh = getSelectedOption(pco, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          //  console.log(r90, r100);
          rules[pco] = [
            ...(rules[pco] || []),
            ...(r909PPCh || []),
            ...(r1009PPCh || []),
          ];
          //  console.log(rules);
          return;
        case "Carcass Powder CoAt":
        case "Facia Powder CoAt":
          console.log("came");
          const privacyyyPPCm = specs.find(
            (x) => x.name === "Metal Pedestal Color Options"
          );
          //  console.log(privacyy);
          const msssPPCm = (
            privacyyyPPCm?.options as Option[] | undefined
          )?.find((x) => x.name === key);
          // console.log(mss);
          const r909PPCm = msssPPCm?.rules as Rule[] | undefined;
          const r1009PPCm = getSelectedOption(
            "Metal Pedestal Color Options",
            key
          )?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          //  console.log(r90, r100);
          rules["Metal Pedestal Color Options"] = [
            ...(rules["Metal Pedestal Color Options"] || []),
            ...(r909PPCm || []),
            ...(r1009PPCm || []),
          ];
          //  console.log(rules);
          return;
        case "Facia Woods":

        case "Carcass Woods":
          console.log("came");
          const privacyyyPPC = specs.find(
            (x) => x.name === "Prelam Pedestal Color Options"
          );
          //  console.log(privacyy);
          const msssPPC = (privacyyyPPC?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          // console.log(mss);
          const r909PPC = msssPPC?.rules as Rule[] | undefined;
          const r1009PPC = getSelectedOption(
            "Prelam Pedestal Color Options",
            key
          )?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          //  console.log(r90, r100);
          rules["Prelam Pedestal Color Options"] = [
            ...(rules["Prelam Pedestal Color Options"] || []),
            ...(r909PPC || []),
            ...(r1009PPC || []),
          ];
          //  console.log(rules);
          return;

        case "Prelam Flip Up Features":
        case "Flip Up Features":
          const privacyyyPPf = specs.find((x) => x.name === "Wire Management");
          //  console.log(privacyy);
          const msssPPf = (privacyyyPPf?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          // console.log(mss);
          const r909PPf = msssPPf?.rules as Rule[] | undefined;
          const r1009PPf = getSelectedOption("Wire Management", key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          //  console.log(r90, r100);
          rules["Wire Management"] = [
            ...(rules["Wire Management"] || []),
            ...(r909PPf || []),
            ...(r1009PPf || []),
          ];
          //  console.log(rules);
          return;
        case "Handles":
        case "Fabrics":
        case "Locks":
        case "LeGs":
          console.log("came");
          const privacyyyPP = specs.find((x) => x.name === "Pedestal Features");
          //  console.log(privacyy);
          const msssPP = (privacyyyPP?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          // console.log(mss);
          const r909PP = msssPP?.rules as Rule[] | undefined;
          const r1009PP = getSelectedOption("Pedestal Features", key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          //  console.log(r90, r100);
          rules["Pedestal Features"] = [
            ...(rules["Pedestal Features"] || []),
            ...(r909PP || []),
            ...(r1009PP || []),
          ];
          //  console.log(rules);
          return;
        case "Pedestal":
          console.log("came");
          const privacyyyP = specs.find((x) => x.name === PedestalFeatureName);
          //  console.log(privacyy);
          const msssP = (privacyyyP?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          // console.log(mss);
          const r909P = msssP?.rules as Rule[] | undefined;
          const r1009P = getSelectedOption(PedestalFeatureName, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          //  console.log(r90, r100);
          rules[PedestalFeatureName] = [
            ...(rules[PedestalFeatureName] || []),
            ...(r909P || []),
            ...(r1009P || []),
          ];
          //  console.log(rules);
          return;
        case "Front":
        case "Side":
          const privacyyy = specs.find((x) => x.name === PowderCoating);
          //  console.log(privacyy);
          const msss = (privacyyy?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          // console.log(mss);
          const r909 = msss?.rules as Rule[] | undefined;
          const r1009 = getSelectedOption(PowderCoating, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          //  console.log(r90, r100);
          rules[PowderCoating] = [
            ...(rules[PowderCoating] || []),
            ...(r909 || []),
            ...(r1009 || []),
          ];
          //  console.log(rules);
          return;
        case "Prelam Storages":
          console.log(key);
          const privacyyS = specs.find((x) => x.name === System);
          console.log(privacyyS);
          const mssS = (privacyyS?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          console.log(mssS);
          const r90S = mssS?.rules as Rule[] | undefined;
          const r100S = getSelectedOption(System, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );

          rules[System] = [
            ...(rules[System] || []),
            ...(r90S || []),
            ...(r100S || []),
          ];
          //  console.log(rules);
          return;

        case "Width":
        case "Depth":
        case "Height":
          console.log(key);
          const privacyy = specs.find((x) => x.name === Dimensions);
          console.log(privacyy);
          const mss = (privacyy?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          console.log(mss);
          const r90 = mss?.rules as Rule[] | undefined;
          const r100 = getSelectedOption(Dimensions, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );

          rules[Dimensions] = [
            ...(rules[Dimensions] || []),
            ...(r90 || []),
            ...(r100 || []),
          ];
          //  console.log(rules);
          return;
        case "Laminate":
        case "Top Wood Laminate":
        case "Side Wood Laminate":
        case "Drawer Wood Laminate":
          console.log("cameeeeeeeeeeeeeeeeeeeeeeeeeee");
          const privac1yy = specs.find((x) => x.name === WoodFeatureName);
          const ms1s = (privac1yy?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          const r19 = ms1s?.rules as Rule[] | undefined;
          const r110 = getSelectedOption(WoodFeatureName, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[WoodFeatureName] = [
            ...(rules[WoodFeatureName] || []),
            ...(r19 || []),
            ...(r110 || []),
          ];
          return;
        case "Main screen":
        case "Return screen":
        case "Main Modesty":
        case "Main Privacy Screen":
        case "Raceway Mounted Screen":
        case "Table Top Mounted Screen":
        case "Privacy Panels":
        case "End Panel Options":
        case "Aluminium Panel Options":
        case "Side Panel Options":
        case "Table Modesty":
        case "Leg Modesty":
        case "Fabric Panel Accessories":
          // console.log("came");
          const privacy = specs.find((x) => x.name === PrivacyFeatureName);
          const ms = (privacy?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          const r9 = ms?.rules as Rule[] | undefined;
          const r10 = getSelectedOption(PrivacyFeatureName, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[PrivacyFeatureName] = [
            ...(rules[PrivacyFeatureName] || []),
            ...(r9 || []),
            ...(r10 || []),
          ];
          return;
        case "30mm Frame Screen":
        case "Frame Screen Main":
        case "Sleek Screen Aluminum Beading Main":
        case "Screen with Nylon Beading":
        case "Glass Main":
        case "Xbench Main":
        case "Xbench with rail Main":
        case "Acoustic fabric":
        case "Full Panel Main":
        case "Curvivo Panel Main":
        case "Livo Panel Main":
        case "Main screen":
          const privacycamm = specs.find((x) => x.name === mps);
          const mscamm = (privacycamm?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          const r9camm = mscamm?.rules as Rule[] | undefined;
          const r10camm = getSelectedOption(mps, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          console.log(r9camm);
          console.log(r10camm);
          rules[mps] = [
            ...(rules[mps] || []),
            ...(r9camm || []),
            ...(r10camm || []),
          ];
          return;
        case "30mm Frame Screen End":
        case "30mm Frame Screen End Single":
        case "Sleek Screen Aluminum Beading End":
        case "Sleek Screen Aluminum Beading End Single":
        case "Sleek Screen Nylon Beading End":
        case "Sleek Screen Nylon Beading End Single":
        case "Glass End":
        case "Glass End Single":
        case "Xbench End":
        case "Xbench with rail End":
        case "Full Panel End":
        case "Curvivo Panel End":
        case "Acoustic fabric":
        case "Livo Panel End":
        case "Livo Panel End Single":
        case "Hanging Panel End":
        case "Livo Highlighter's Color End":
          const privacycamme = specs.find((x) => x.name === eps);
          const mscamme = (privacycamme?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          const r9camme = mscamme?.rules as Rule[] | undefined;
          const r10camme = getSelectedOption(eps, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[eps] = [
            ...(rules[eps] || []),
            ...(r9camme || []),
            ...(r10camme || []),
          ];
          return;

        case "30mm Return Frame Screen":
        case "30mm Return Frame Screen Single":
        case "Sleek Screen Aluminum Beading Return":
        case "Sleek Screen Aluminum Beading Return Single":
        case "Sleek Screen Nylon Beading Return":
        case "Sleek Screen Nylon Beading Return Single":
        case "Glass Return":
        case "Glass Return Single":
        case "Xbench Return":
        case "Xbench with rail Return":
        case "Full Panel Return":
        case "Acoustic fabric":
        case "Curvivo Panel Return":
        case "Livo Panel Return":
        case "Livo Panel Return Single":
        case "Hanging Panel Return":
        case "Livo Highlighter's Color Return":
        case "Return screen":
          const privacycammm = specs.find((x) => x.name === sps);
          const mscammm = (privacycammm?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          const r9cammm = mscammm?.rules as Rule[] | undefined;
          const r10cammm = getSelectedOption(sps, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[sps] = [
            ...(rules[sps] || []),
            ...(r9cammm || []),
            ...(r10cammm || []),
          ];
          return;

        case "Wood Color Options for Modesty":
        case "PowderCoat  Options for Modesty":
        case "MID Modesty":
        case "Side Modesty":
          const privacyca = specs.find((x) => x.name === mco);
          const msca = (privacyca?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          const r9ca = msca?.rules as Rule[] | undefined;
          const r10ca = getSelectedOption(mco, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[mco] = [
            ...(rules[mco] || []),
            ...(r9ca || []),
            ...(r10ca || []),
          ];
          return;

        case "Main Glass Color":
        case "Main Fabric Color":
        case "Acoustic Fabric":
          const privacycam = specs.find((x) => x.name === mpco);
          const mscam = (privacycam?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          const r9cam = mscam?.rules as Rule[] | undefined;
          const r10cam = getSelectedOption(mpco, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[mpco] = [
            ...(rules[mpco] || []),
            ...(r9cam || []),
            ...(r10cam || []),
          ];
          return;
        case "Side Glass Color":
        case "Side Fabric Color":
          const privacycams = specs.find((x) => x.name === spco);
          const mscams = (privacycams?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          const r9cams = mscams?.rules as Rule[] | undefined;
          const r10cams = getSelectedOption(spco, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[spco] = [
            ...(rules[spco] || []),
            ...(r9cams || []),
            ...(r10cams || []),
          ];
          return;

        case "Raiser Leg 1":

        case "Raiser Leg 2":

        case "Raiser Leg 3":

        case "Raiser Leg 4":

        case "Raiser Leg 5":

        case "Raiser Leg 6":

        case "Raiser Leg 7":

        case "Raiser Leg 8":

        case "Raiser Leg 9":

        case "Raiser Leg 10":

        case "Raiser Leg 11":

        case "Raiser Leg 12":
          const privacycamsgl = specs.find((x) => x.name === cl);
          const mscamsgl = (
            privacycamsgl?.options as Option[] | undefined
          )?.find((x) => x.name === key);
          const r9camsgl = mscamsgl?.rules as Rule[] | undefined;
          const r10camsgl = getSelectedOption(cl, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[cl] = [
            ...(rules[cl] || []),
            ...(r9camsgl || []),
            ...(r10camsgl || []),
          ];
          return;

        // case "Keyboard Trays":
        // case "CPU Stands":
        //     const accessories = specs.find(
        //       (x) => x.name === AccessoriesFeatureName
        //     );
        //     const ams = (accessories?.options as Option[] | undefined)?.find(
        //       (x) => x.name === key
        //     );
        //     const ar9 = ams?.rules as Rule[] | undefined;
        //     const ar10 = getSelectedOption(AccessoriesFeatureName, key)?.reduce(
        //       (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
        //       []
        //     );
        //     rules[AccessoriesFeatureName] = [
        //       ...(rules[AccessoriesFeatureName] || []),
        //       ...(ar9 || []),
        //       ...(ar10 || []),
        //     ];
        //     return;

        default:
          break;
      }
    });

    const evaluateMultipleConditions = (
      multipleConditions: any,
      userConfig: any
    ): boolean => {
      console.log(userConfig, multipleConditions);
      let result = true;
      let currentOperator: "AND" | "OR" = "AND";

      for (let i = 0; i < multipleConditions.length; i++) {
        const condition = multipleConditions[i];

        if ("operator" in condition) {
          currentOperator = condition.operator;
        } else {
          const conditionMet = condition.values.includes(
            userConfig[condition.feature]
          );

          if (currentOperator === "AND") {
            result = result && conditionMet;
          } else if (currentOperator === "OR") {
            result = result || conditionMet;
          }
        }
      }

      return result;
    };

    const parseConditions = (rules: Rule[]) => {
      console.log(rules);
      let cc = rules.filter((rule: any) => {
        let filter = true;
        console.log(rule.condition);
        rule.condition?.forEach((condition: any) => {
          console.log(condition);
          console.log(condition.feature);
          console.log(userConfiguration2);
          condition.values.includes(userConfiguration2[condition.feature])
            ? (filter = true)
            : (filter = false);
        });

        if (rule.multipleConditions) {
          console.log(rule.multipleConditions);
          console.log(userConfiguration2);

          filter = evaluateMultipleConditions(
            rule.multipleConditions,
            userConfiguration2
          );
          console.log(filter, rule);
        }

        return filter;
      });
      console.log(cc);
      return cc;
    };
    Object.keys(rules).forEach((key) => {
      console.log(parseConditions(rules[key]), rules[key], key);

      rules[key] = parseConditions(rules[key]);
    });
    console.log(rules);
    return rules;
  };

  return {
    updateUserConfiguration,
    userConfiguration2,
    getSelectedOption,
    getRules,
  };
};

export default useUserConfiguration;
