import { features } from "process";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hide = "hide";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const show = "show";
const replacePart = "replacePart";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const applyMaterial = "applyMaterial";

const bindPart = "bindPart";
const unbind = "unbind";
const sideTableLegRules = [{}];
export const ConfData3 = Object.freeze([
  //basically for desking workstations

  {
    name: "System",
    multiSelect: false,

    options: [
      {
        name: "SHARING",
        required: false,

        options: [
          {
            name: "2 SEATER",
            size: 2,
            thumbnail: "/assets/afc/thumnail/lks/sharing/2seater.png",
            productId: "664df007c2435f4579b6ef2b",

            rules: [
              {
                type: hide,
                node: [
                  "cabletray_flipup",
                  "cabletray_grommet",
                  "chamferedge_flipup",
                  "flipup",
                  "postformedge_flipup",
                  "alumfullfabric",
                  "alumfullfabricwithborder",
                  "alumfullfabricwithborder_nobrackets",
                  "alumhalfandhalf",
                  "fullfabricrounded",
                  "fullfabricrounded_nobrackets",
                  "fullpanels",
                  "halffabrichalfglass",
                  "livoscreen",
                  "panelwithplants",
                  "roundedglass",
                  "Xbench",
                  "Xbench_nobrackets",
                  "Xbenchrail",
                  "Xbenchrail_nobrackets",
                  "cpu_stand",
                  "hangingcpustand",
                  "hangingmetalbaghook",
                  "hangingmetalmarker_1",
                  "haningmetalpenholder",
                  "keyboardmousetray",
                  "keyboardtray",
                  "panelmountednameplate",
                  "metalmodesty",
                  "metalmodesty_proceed",
                  "metalmodesty_side",
                  "metalmodesty_slant",
                  "metalmodesty_sleek",
                  "metalmodesty_sleek_livo",
                  "woodmodesty",
                  "woodmodesty_proceed",
                  "woodmodesty_side",
                  "woodmodesty_slant",
                  "woodmodesty_sleek",
                  "woodmodesty_sleek_livo",
                  "chamfer_edge",
                  "postform_edge",
                  "grommet",
                  "legs_30*60_strip",
                  "legs_40*40_loop",
                  "legs_40*40_slanted",
                  "legs_curvivo",
                  "legs_deskpro_loop",
                  "legs_deskpro_slant",
                  "legs_deskpro_straight",
                  "legs_Livo",
                  "legs_livo_slanted",
                  "legs_proceed",
                  "legs_sleek",
                  "legs_triangular_loop",
                  "legs_triangular_slant",
                  "legs_triangular_straight",
                  "alumfullfabric_1",
                  "alumfullfabricwithborder_1",
                  "alumfullfabricwithborder_1_nobrackets",
                  "fullfabricrounded_1",
                  "fullfabricrounded_1_nobrackets",
                  "fullpanels_1",
                  "livoscreen_1",
                  "roundedglass_1",
                  "chamfered_dcut",
                  "postform_dcut",
                  "straightedge_dcut",
                  "chamferedge_flipup_RHS",
                  "flipup_RHS",
                  "postformedge_flipup_RHS",
                  "chamferedge_flipup_LHS",
                  "flipup_LHS",
                  "postformedge_flipup_LHS",
                  "alumfullfabric_end",
                  "alumfullfabricwithborder__end",
                  "alumfullfabricwithborder_nobrackets_end",
                  "fullfabricrounded_end",
                  "fullfabricrounded_nobrackets_end",
                  "livoscreen_end",
                  "hanging_aluminium",
                  "hanging_framed",
                  "hanging_nylon",
                  "alumfullfabric_end_single",
                  "alumfullfabricwithborder__end_single",
                  "fullfabricrounded_end_single",
                  "alumfullfabricwithborder_nobrackets_end_single",
                  "roundedglass_end_single",
                  "Xbench_end_single",
                  "fullfabricrounded_nobrackets_end_single",
                  "Xbench_nobrackets_end_single",
                  "alumfullfabric_1_single",
                  "alumfullfabricwithborder_1_single",
                  "alumfullfabricwithborder_1_nobrackets_single",
                  "fullfabricrounded_1_nobrackets",
                  "fullfabricrounded_1_nobrackets_single",
                  "livoscreen_1_single",
                  "roundedglass_end",
                  "alumfullfabricwithborder__end",
                  "alumfullfabricwithborder_end",
                  "roundedglass_end",
                ],
              },
            ],
            disableFeatures: [
              "Handles",
              "Locks",
              "Facia Woods",
              "Fabrics",
              "LeGs",
              "Carcass Woods",
              "Facia Powder CoAt",
              "Carcass Powder CoAt",
              "Raiser Leg 1",
              "Raiser Leg 2",
              "Raiser Leg 3",
              "Raiser Leg 4",
              "Raiser Leg 5",
              "Raiser Leg 6",
              "Raiser Leg 7",
              "Raiser Leg 8",
              "Raiser Leg 9",
              "Raiser Leg 10",
              "Raiser Leg 11",
              "Raiser Leg 12",
              "Wire Manager for Standard Top",
              "Wire Manager for Chamfer Top",
              "Wire Manager for Postform Top",
              "Waterfall Raceway Options",
              "Livo Highlighter's Color",
              "Strip PowderCoat Color",
              "Acoustic fabric",
              "Frame Screen Return",
              "Sleek Screen Aluminum Beading Return",
              "Sleek Screen Nylon Beading Return",
              "Glass Return",
              "Full Panel Return",
              "Livo Panel Return",
              "Livo Highlighter's Color",
              "Acoustic fabric",
            ],
            disableOptions: [
              "HANGING METAL MARKER 450WX300H",
              "HANGING METAL MARKER",
              "PANEL MOUNTED NAME PLATE",
              "HANGING METAL PAPER TRAY 222L",
              "HANGING METAL PEN HOLDER 70L",
              "HANGING METAL BAG HOOK",
              "HANGING METAL MARKER 300WX300H",
              "METAL SINGLE PAPER HOLDER",
            ],
            enableOptions: [],
            tableTop: [],
            intermediateLegs: [],
          },
          {
            name: "4 SEATER",
            size: 4,
            thumbnail: "/assets/afc/thumnail/lks/sharing/4seater.png",
            productId: "664deb3e4bb8588cd0932d08",
            rules: [
              {
                type: hide,
                node: [
                  "cabletray_flipup",
                  "cabletray_grommet",
                  "chamferedge_flipup",
                  "flipup",
                  "postformedge_flipup",
                  "alumfullfabric",
                  "alumfullfabricwithborder",
                  "alumfullfabricwithborder_nobrackets",
                  "alumhalfandhalf",
                  "fullfabricrounded",
                  "fullfabricrounded_nobrackets",
                  "fullpanels",
                  "halffabrichalfglass",
                  "livoscreen",
                  "panelwithplants",
                  "roundedglass",
                  "Xbench",
                  "Xbench_nobrackets",
                  "Xbenchrail",
                  "Xbenchrail_nobrackets",
                  "cpu_stand",
                  "hangingcpustand",
                  "hangingmetalbaghook",
                  "hangingmetalmarker_1",
                  "haningmetalpenholder",
                  "keyboardmousetray",
                  "keyboardtray",
                  "panelmountednameplate",
                  "metalmodesty",
                  "metalmodesty_proceed",
                  "metalmodesty_side",
                  "metalmodesty_slant",
                  "metalmodesty_sleek",
                  "metalmodesty_sleek_livo",
                  "woodmodesty",
                  "woodmodesty_proceed",
                  "woodmodesty_side",
                  "woodmodesty_slant",
                  "woodmodesty_sleek",
                  "woodmodesty_sleek_livo",
                  "chamfer_edge",
                  "postform_edge",
                  "grommet",
                  "legs_30*60_strip",
                  "legs_40*40_loop",
                  "legs_40*40_slanted",
                  "legs_curvivo",
                  "legs_deskpro_loop",
                  "legs_deskpro_slant",
                  "legs_deskpro_straight",
                  "legs_Livo",
                  "legs_livo_slanted",
                  "legs_proceed",
                  "legs_sleek",
                  "legs_triangular_loop",
                  "legs_triangular_slant",
                  "legs_triangular_straight",
                  "alumfullfabric_1",
                  "alumfullfabricwithborder_1",
                  "alumfullfabricwithborder_1_nobrackets",
                  "fullfabricrounded_1",
                  "fullfabricrounded_1_nobrackets",
                  "fullpanels_1",
                  "livoscreen_1",
                  "roundedglass_1",
                  "chamfered_dcut",
                  "postform_dcut",
                  "straightedge_dcut",
                  "chamferedge_flipup_RHS",
                  "flipup_RHS",
                  "postformedge_flipup_RHS",
                  "chamferedge_flipup_LHS",
                  "flipup_LHS",
                  "postformedge_flipup_LHS",
                  "alumfullfabric_end",
                  "alumfullfabricwithborder__end",
                  "alumfullfabricwithborder_nobrackets_end",
                  "fullfabricrounded_end",
                  "fullfabricrounded_nobrackets_end",
                  "livoscreen_end",
                  "hanging_aluminium",
                  "hanging_framed",
                  "hanging_nylon",
                  "alumfullfabric_end_single",
                  "alumfullfabricwithborder__end_single",
                  "fullfabricrounded_end_single",
                  "alumfullfabricwithborder_nobrackets_end_single",
                  "roundedglass_end_single",
                  "Xbench_end_single",
                  "fullfabricrounded_nobrackets_end_single",
                  "Xbench_nobrackets_end_single",
                  "alumfullfabric_1_single",
                  "alumfullfabricwithborder_1_single",
                  "alumfullfabricwithborder_1_nobrackets_single",
                  "fullfabricrounded_1_nobrackets",
                  "fullfabricrounded_1_nobrackets_single",
                  "livoscreen_1_single",
                  "roundedglass_end",
                  "alumfullfabricwithborder__end",
                  "alumfullfabricwithborder_end",
                  "roundedglass_end",
                  "alumfullfabric_end_single",
                  "alumfullfabricwithborder__end_single",
                  "alumfullfabricwithborder_nobrackets_end_single",
                  "fullfabricrounded_end_single",
                  "fullfabricrounded_nobrackets_end_single",
                  "livoscreen_end_single",
                  "roundedglass_end_single",
                  "alumfullfabric_1_single",
                  "alumfullfabricwithborder_1_nobrackets_single",
                  "alumfullfabricwithborder_1_single",
                  "fullfabricrounded_1_nobrackets_single",
                  "fullfabricrounded_1_single",
                  "livoscreen_1_single",
                  "roundedglass_1_single",
                ],
              },
            ],
            disableFeatures: [
              "Handles",
              "Locks",
              "Facia Woods",
              "Fabrics",
              "LeGs",
              "Carcass Woods",
            ],
            disableOptions: [
              "HANGING METAL MARKER 450WX300H",
              "HANGING METAL MARKER",
              "PANEL MOUNTED NAME PLATE",
              "HANGING METAL PAPER TRAY 222L",
              "HANGING METAL PEN HOLDER 70L",
              "HANGING METAL BAG HOOK",
              "HANGING METAL MARKER 300WX300H",
              "METAL SINGLE PAPER HOLDER",
            ],
            enableFeatures: [
              "Frame Screen Return",
              "Sleek Screen Aluminum Beading Return",
              "Sleek Screen Nylon Beading Return",
              "Glass Return",
              "Full Panel Return",
              "Livo Panel Return",
              "Livo Highlighter's Color",
              "Acoustic fabric",
            ],
          },
          {
            name: "6 SEATER",
            size: 6,
            thumbnail: "/assets/afc/thumnail/lks/sharing/6seater.png",
            productId: "664ddfac42fb3029f1ee5a6a",
            enableFeatures: [
              "Frame Screen Return",
              "Sleek Screen Aluminum Beading Return",
              "Sleek Screen Nylon Beading Return",
              "Glass Return",
              "Full Panel Return",
              "Livo Panel Return",
              "Livo Highlighter's Color",
              "Acoustic fabric",
              "Raiser Leg 1",
              "Raiser Leg 2",
            ],
            rules: [
              {
                type: hide,
                node: [
                  "cabletray_flipup",
                  "cabletray_grommet",
                  "chamferedge_flipup",
                  "flipup",
                  "postformedge_flipup",
                  "alumfullfabric",
                  "alumfullfabricwithborder",
                  "alumfullfabricwithborder_nobrackets",
                  "alumhalfandhalf",
                  "fullfabricrounded",
                  "fullfabricrounded_nobrackets",
                  "fullpanels",
                  "halffabrichalfglass",
                  "livoscreen",
                  "panelwithplants",
                  "roundedglass",
                  "Xbench",
                  "Xbench_nobrackets",
                  "Xbenchrail",
                  "Xbenchrail_nobrackets",
                  "cpu_stand",
                  "hangingcpustand",
                  "hangingmetalbaghook",
                  "hangingmetalmarker_1",
                  "haningmetalpenholder",
                  "keyboardmousetray",
                  "keyboardtray",
                  "panelmountednameplate",
                  "metalmodesty",
                  "metalmodesty_proceed",
                  "metalmodesty_side",
                  "metalmodesty_slant",
                  "metalmodesty_sleek",
                  "metalmodesty_sleek_livo",
                  "woodmodesty",
                  "woodmodesty_proceed",
                  "woodmodesty_side",
                  "woodmodesty_slant",
                  "woodmodesty_sleek",
                  "woodmodesty_sleek_livo",
                  "chamfer_edge",
                  "postform_edge",
                  "grommet",
                  "legs_30*60_strip",
                  "legs_40*40_loop",
                  "legs_40*40_slanted",
                  "legs_curvivo",
                  "legs_deskpro_loop",
                  "legs_deskpro_slant",
                  "legs_deskpro_straight",
                  "legs_Livo",
                  "legs_livo_slanted",
                  "legs_proceed",
                  "legs_sleek",
                  "legs_triangular_loop",
                  "legs_triangular_slant",
                  "legs_triangular_straight",
                  "alumfullfabric_1",
                  "alumfullfabricwithborder_1",
                  "alumfullfabricwithborder_1_nobrackets",
                  "fullfabricrounded_1",
                  "fullfabricrounded_1_nobrackets",
                  "fullpanels_1",
                  "livoscreen_1",
                  "roundedglass_1",
                  "chamfered_dcut",
                  "postform_dcut",
                  "straightedge_dcut",
                  "chamferedge_flipup_RHS",
                  "flipup_RHS",
                  "postformedge_flipup_RHS",
                  "chamferedge_flipup_LHS",
                  "flipup_LHS",
                  "postformedge_flipup_LHS",
                  "alumfullfabric_end",
                  "alumfullfabricwithborder__end",
                  "alumfullfabricwithborder_nobrackets_end",
                  "fullfabricrounded_end",
                  "fullfabricrounded_nobrackets_end",
                  "livoscreen_end",
                  "hanging_aluminium",
                  "hanging_framed",
                  "hanging_nylon",
                  "alumfullfabric_end_single",
                  "alumfullfabricwithborder__end_single",
                  "fullfabricrounded_end_single",
                  "alumfullfabricwithborder_nobrackets_end_single",
                  "roundedglass_end_single",
                  "Xbench_end_single",
                  "fullfabricrounded_nobrackets_end_single",
                  "Xbench_nobrackets_end_single",
                  "alumfullfabric_1_single",
                  "alumfullfabricwithborder_1_single",
                  "alumfullfabricwithborder_1_nobrackets_single",
                  "fullfabricrounded_1_nobrackets",
                  "fullfabricrounded_1_nobrackets_single",
                  "livoscreen_1_single",
                  "roundedglass_end",
                  "alumfullfabricwithborder__end",
                  "alumfullfabricwithborder_end",
                  "roundedglass_end",
                  "alumfullfabric_end_single",
                  "alumfullfabricwithborder__end_single",
                  "alumfullfabricwithborder_nobrackets_end_single",
                  "fullfabricrounded_end_single",
                  "fullfabricrounded_nobrackets_end_single",
                  "livoscreen_end_single",
                  "roundedglass_end_single",
                  "alumfullfabric_1_single",
                  "alumfullfabricwithborder_1_nobrackets_single",
                  "alumfullfabricwithborder_1_single",
                  "fullfabricrounded_1_nobrackets_single",
                  "fullfabricrounded_1_single",
                  "livoscreen_1_single",
                  "roundedglass_1_single",
                ],
              },
            ],
            disableFeatures: [
              "Handles",
              "Locks",
              "Facia Woods",
              "Fabrics",
              "LeGs",
              "Carcass Woods",
              "Facia Powder CoAt",
              "Carcass Powder CoAt",
              "Raiser Leg 1",
              "Raiser Leg 2",
              "Raiser Leg 3",
              "Raiser Leg 4",
              "Raiser Leg 5",
              "Raiser Leg 6",
              "Raiser Leg 7",
              "Raiser Leg 8",
              "Raiser Leg 9",
              "Raiser Leg 10",
              "Raiser Leg 11",
              "Raiser Leg 12",
              "Wire Manager for Standard Top",
              "Wire Manager for Chamfer Top",
              "Wire Manager for Postform Top",
              "Waterfall Raceway Options",
              "Livo Highlighter's Color",
              "Strip PowderCoat Color",
              "Acoustic fabric",
            ],
            disableOptions: [
              "HANGING METAL MARKER 450WX300H",
              "HANGING METAL MARKER",
              "PANEL MOUNTED NAME PLATE",
              "HANGING METAL PAPER TRAY 222L",
              "HANGING METAL PEN HOLDER 70L",
              "HANGING METAL BAG HOOK",
              "HANGING METAL MARKER 300WX300H",
              "METAL SINGLE PAPER HOLDER",
            ],

            // enableFeatures: ["Raiser Leg 1", "Raiser Leg 2"],

            enableOptions: [],
            tableTop: [],
            intermediateLegs: [],
          },
          {
            name: "8 SEATER",
            size: 8,
            thumbnail: "/assets/afc/thumnail/lks/sharing/8seater.png",
            productId: "664dd72ebee1d1e7b4bf8ee0",
            enableFeatures: [
              "Frame Screen Return",
              "Sleek Screen Aluminum Beading Return",
              "Sleek Screen Nylon Beading Return",
              "Glass Return",
              "Full Panel Return",
              "Livo Panel Return",
              "Livo Highlighter's Color",
              "Acoustic fabric",
            ],
            rules: [
              {
                type: hide,
                node: [
                  "cabletray_flipup",
                  "cabletray_grommet",
                  "chamferedge_flipup",
                  "flipup",
                  "postformedge_flipup",
                  "alumfullfabric",
                  "alumfullfabricwithborder",
                  "alumfullfabricwithborder_nobrackets",
                  "alumhalfandhalf",
                  "fullfabricrounded",
                  "fullfabricrounded_nobrackets",
                  "fullpanels",
                  "halffabrichalfglass",
                  "livoscreen",
                  "panelwithplants",
                  "roundedglass",
                  "Xbench",
                  "Xbench_nobrackets",
                  "Xbenchrail",
                  "Xbenchrail_nobrackets",
                  "cpu_stand",
                  "hangingcpustand",
                  "hangingmetalbaghook",
                  "hangingmetalmarker_1",
                  "haningmetalpenholder",
                  "keyboardmousetray",
                  "keyboardtray",
                  "panelmountednameplate",
                  "metalmodesty",
                  "metalmodesty_proceed",
                  "metalmodesty_side",
                  "metalmodesty_slant",
                  "metalmodesty_sleek",
                  "metalmodesty_sleek_livo",
                  "woodmodesty",
                  "woodmodesty_proceed",
                  "woodmodesty_side",
                  "woodmodesty_slant",
                  "woodmodesty_sleek",
                  "woodmodesty_sleek_livo",
                  "chamfer_edge",
                  "postform_edge",
                  "grommet",
                  "legs_30*60_strip",
                  "legs_40*40_loop",
                  "legs_40*40_slanted",
                  "legs_curvivo",
                  "legs_deskpro_loop",
                  "legs_deskpro_slant",
                  "legs_deskpro_straight",
                  "legs_Livo",
                  "legs_livo_slanted",
                  "legs_proceed",
                  "legs_sleek",
                  "legs_triangular_loop",
                  "legs_triangular_slant",
                  "legs_triangular_straight",
                  "alumfullfabric_1",
                  "alumfullfabricwithborder_1",
                  "alumfullfabricwithborder_1_nobrackets",
                  "fullfabricrounded_1",
                  "fullfabricrounded_1_nobrackets",
                  "fullpanels_1",
                  "livoscreen_1",
                  "roundedglass_1",
                  "chamfered_dcut",
                  "postform_dcut",
                  "straightedge_dcut",
                  "chamferedge_flipup_RHS",
                  "flipup_RHS",
                  "postformedge_flipup_RHS",
                  "chamferedge_flipup_LHS",
                  "flipup_LHS",
                  "postformedge_flipup_LHS",
                  "alumfullfabric_end",
                  "alumfullfabricwithborder__end",
                  "alumfullfabricwithborder_nobrackets_end",
                  "fullfabricrounded_end",
                  "fullfabricrounded_nobrackets_end",
                  "livoscreen_end",
                  "hanging_aluminium",
                  "hanging_framed",
                  "hanging_nylon",
                  "alumfullfabric_end_single",
                  "alumfullfabricwithborder__end_single",
                  "fullfabricrounded_end_single",
                  "alumfullfabricwithborder_nobrackets_end_single",
                  "roundedglass_end_single",
                  "Xbench_end_single",
                  "fullfabricrounded_nobrackets_end_single",
                  "Xbench_nobrackets_end_single",
                  "alumfullfabric_1_single",
                  "alumfullfabricwithborder_1_single",
                  "alumfullfabricwithborder_1_nobrackets_single",
                  "fullfabricrounded_1_nobrackets",
                  "fullfabricrounded_1_nobrackets_single",
                  "livoscreen_1_single",
                  "roundedglass_end",
                  "alumfullfabricwithborder__end",
                  "alumfullfabricwithborder_end",
                  "roundedglass_end",
                  "alumfullfabric_end_single",
                  "alumfullfabricwithborder__end_single",
                  "alumfullfabricwithborder_nobrackets_end_single",
                  "fullfabricrounded_end_single",
                  "fullfabricrounded_nobrackets_end_single",
                  "livoscreen_end_single",
                  "roundedglass_end_single",
                  "alumfullfabric_1_single",
                  "alumfullfabricwithborder_1_nobrackets_single",
                  "alumfullfabricwithborder_1_single",
                  "fullfabricrounded_1_nobrackets_single",
                  "fullfabricrounded_1_single",
                  "livoscreen_1_single",
                  "roundedglass_1_single",
                ],
              },
            ],
            disableFeatures: [
              "Handles",
              "Locks",
              "Facia Woods",
              "Fabrics",
              "LeGs",
              "Carcass Woods",
              "Facia Powder CoAt",
              "Carcass Powder CoAt",
              "Raiser Leg 1",
              "Raiser Leg 2",
              "Raiser Leg 3",
              "Raiser Leg 4",
              "Raiser Leg 5",
              "Raiser Leg 6",
              "Raiser Leg 7",
              "Raiser Leg 8",
              "Raiser Leg 9",
              "Raiser Leg 10",
              "Raiser Leg 11",
              "Raiser Leg 12",
              "Wire Manager for Standard Top",
              "Wire Manager for Chamfer Top",
              "Wire Manager for Postform Top",
              "Waterfall Raceway Options",
              "Livo Highlighter's Color",
              "Strip PowderCoat Color",
              "Acoustic fabric",
            ],
            disableOptions: [
              "HANGING METAL MARKER 450WX300H",
              "HANGING METAL MARKER",
              "PANEL MOUNTED NAME PLATE",
              "HANGING METAL PAPER TRAY 222L",
              "HANGING METAL PEN HOLDER 70L",
              "HANGING METAL BAG HOOK",
              "HANGING METAL MARKER 300WX300H",
              "METAL SINGLE PAPER HOLDER",
            ],

            enableOptions: [],
            tableTop: [],
            intermediateLegs: [],
          },
          {
            name: "10 SEATER",
            size: 10,
            thumbnail: "/assets/afc/thumnail/lks/sharing/10seater.png",
            productId: "664dc72fe24c826e131f3a58",
            enableFeatures: [
              "Frame Screen Return",
              "Sleek Screen Aluminum Beading Return",
              "Sleek Screen Nylon Beading Return",
              "Glass Return",
              "Full Panel Return",
              "Livo Panel Return",
              "Livo Highlighter's Color",
              "Acoustic fabric",
            ],
            rules: [
              {
                type: hide,
                node: [
                  "cabletray_flipup",
                  "cabletray_grommet",
                  "chamferedge_flipup",
                  "flipup",
                  "postformedge_flipup",
                  "alumfullfabric",
                  "alumfullfabricwithborder",
                  "alumfullfabricwithborder_nobrackets",
                  "alumhalfandhalf",
                  "fullfabricrounded",
                  "fullfabricrounded_nobrackets",
                  "fullpanels",
                  "halffabrichalfglass",
                  "livoscreen",
                  "panelwithplants",
                  "roundedglass",
                  "Xbench",
                  "Xbench_nobrackets",
                  "Xbenchrail",
                  "Xbenchrail_nobrackets",
                  "cpu_stand",
                  "hangingcpustand",
                  "hangingmetalbaghook",
                  "hangingmetalmarker_1",
                  "haningmetalpenholder",
                  "keyboardmousetray",
                  "keyboardtray",
                  "panelmountednameplate",
                  "metalmodesty",
                  "metalmodesty_proceed",
                  "metalmodesty_side",
                  "metalmodesty_slant",
                  "metalmodesty_sleek",
                  "metalmodesty_sleek_livo",
                  "woodmodesty",
                  "woodmodesty_proceed",
                  "woodmodesty_side",
                  "woodmodesty_slant",
                  "woodmodesty_sleek",
                  "woodmodesty_sleek_livo",
                  "chamfer_edge",
                  "postform_edge",
                  "grommet",
                  "legs_30*60_strip",
                  "legs_40*40_loop",
                  "legs_40*40_slanted",
                  "legs_curvivo",
                  "legs_deskpro_loop",
                  "legs_deskpro_slant",
                  "legs_deskpro_straight",
                  "legs_Livo",
                  "legs_livo_slanted",
                  "legs_proceed",
                  "legs_sleek",
                  "legs_triangular_loop",
                  "legs_triangular_slant",
                  "legs_triangular_straight",
                  "alumfullfabric_1",
                  "alumfullfabricwithborder_1",
                  "alumfullfabricwithborder_1_nobrackets",
                  "fullfabricrounded_1",
                  "fullfabricrounded_1_nobrackets",
                  "fullpanels_1",
                  "livoscreen_1",
                  "roundedglass_1",
                  "chamfered_dcut",
                  "postform_dcut",
                  "straightedge_dcut",
                  "chamferedge_flipup_RHS",
                  "flipup_RHS",
                  "postformedge_flipup_RHS",
                  "chamferedge_flipup_LHS",
                  "flipup_LHS",
                  "postformedge_flipup_LHS",
                  "alumfullfabric_end",
                  "alumfullfabricwithborder__end",
                  "alumfullfabricwithborder_nobrackets_end",
                  "fullfabricrounded_end",
                  "fullfabricrounded_nobrackets_end",
                  "livoscreen_end",
                  "hanging_aluminium",
                  "hanging_framed",
                  "hanging_nylon",
                  "alumfullfabric_end_single",
                  "alumfullfabricwithborder__end_single",
                  "fullfabricrounded_end_single",
                  "alumfullfabricwithborder_nobrackets_end_single",
                  "roundedglass_end_single",
                  "Xbench_end_single",
                  "fullfabricrounded_nobrackets_end_single",
                  "Xbench_nobrackets_end_single",
                  "alumfullfabric_1_single",
                  "alumfullfabricwithborder_1_single",
                  "alumfullfabricwithborder_1_nobrackets_single",
                  "fullfabricrounded_1_nobrackets",
                  "fullfabricrounded_1_nobrackets_single",
                  "livoscreen_1_single",
                  "roundedglass_end",
                  "alumfullfabricwithborder__end",
                  "alumfullfabricwithborder_end",
                  "roundedglass_end",
                  "alumfullfabric_end_single",
                  "alumfullfabricwithborder__end_single",
                  "alumfullfabricwithborder_nobrackets_end_single",
                  "fullfabricrounded_end_single",
                  "fullfabricrounded_nobrackets_end_single",
                  "livoscreen_end_single",
                  "roundedglass_end_single",
                  "alumfullfabric_1_single",
                  "alumfullfabricwithborder_1_nobrackets_single",
                  "alumfullfabricwithborder_1_single",
                  "fullfabricrounded_1_nobrackets_single",
                  "fullfabricrounded_1_single",
                  "livoscreen_1_single",
                  "roundedglass_1_single",
                ],
              },
            ],
            disableFeatures: [
              "Handles",
              "Locks",
              "Facia Woods",
              "Fabrics",
              "LeGs",
              "Carcass Woods",
              "Facia Powder CoAt",
              "Carcass Powder CoAt",
              "Raiser Leg 1",
              "Raiser Leg 2",
              "Raiser Leg 3",
              "Raiser Leg 4",
              "Raiser Leg 5",
              "Raiser Leg 6",
              "Raiser Leg 7",
              "Raiser Leg 8",
              "Raiser Leg 9",
              "Raiser Leg 10",
              "Raiser Leg 11",
              "Raiser Leg 12",
              "Wire Manager for Standard Top",
              "Wire Manager for Chamfer Top",
              "Wire Manager for Postform Top",
              "Waterfall Raceway Options",
              "Livo Highlighter's Color",
              "Strip PowderCoat Color",
              "Acoustic fabric",
            ],
            disableOptions: [
              "HANGING METAL MARKER 450WX300H",
              "HANGING METAL MARKER",
              "PANEL MOUNTED NAME PLATE",
              "HANGING METAL PAPER TRAY 222L",
              "HANGING METAL PEN HOLDER 70L",
              "HANGING METAL BAG HOOK",
              "HANGING METAL MARKER 300WX300H",
              "METAL SINGLE PAPER HOLDER",
            ],

            enableOptions: [],
            tableTop: [],
            intermediateLegs: [],
          },
          {
            name: "12 SEATER",
            size: 12,
            thumbnail: "/assets/afc/thumnail/lks/sharing/12seater.png",
            productId: "664dc481141d6073de406f7a",
            enableFeatures: [
              "Frame Screen Return",
              "Sleek Screen Aluminum Beading Return",
              "Sleek Screen Nylon Beading Return",
              "Glass Return",
              "Full Panel Return",
              "Livo Panel Return",
              "Livo Highlighter's Color",
              "Acoustic fabric",
            ],
            rules: [
              {
                type: hide,
                node: [
                  "cabletray_flipup",
                  "cabletray_grommet",
                  "chamferedge_flipup",
                  "flipup",
                  "postformedge_flipup",
                  "alumfullfabric",
                  "alumfullfabricwithborder",
                  "alumfullfabricwithborder_nobrackets",
                  "alumhalfandhalf",
                  "fullfabricrounded",
                  "fullfabricrounded_nobrackets",
                  "fullpanels",
                  "halffabrichalfglass",
                  "livoscreen",
                  "panelwithplants",
                  "roundedglass",
                  "Xbench",
                  "Xbench_nobrackets",
                  "Xbenchrail",
                  "Xbenchrail_nobrackets",
                  "cpu_stand",
                  "hangingcpustand",
                  "hangingmetalbaghook",
                  "hangingmetalmarker_1",
                  "haningmetalpenholder",
                  "keyboardmousetray",
                  "keyboardtray",
                  "panelmountednameplate",
                  "metalmodesty",
                  "metalmodesty_proceed",
                  "metalmodesty_side",
                  "metalmodesty_slant",
                  "metalmodesty_sleek",
                  "metalmodesty_sleek_livo",
                  "woodmodesty",
                  "woodmodesty_proceed",
                  "woodmodesty_side",
                  "woodmodesty_slant",
                  "woodmodesty_sleek",
                  "woodmodesty_sleek_livo",
                  "chamfer_edge",
                  "postform_edge",
                  "grommet",
                  "legs_30*60_strip",
                  "legs_40*40_loop",
                  "legs_40*40_slanted",
                  "legs_curvivo",
                  "legs_deskpro_loop",
                  "legs_deskpro_slant",
                  "legs_deskpro_straight",
                  "legs_Livo",
                  "legs_livo_slanted",
                  "legs_proceed",
                  "legs_sleek",
                  "legs_triangular_loop",
                  "legs_triangular_slant",
                  "legs_triangular_straight",
                  "alumfullfabric_1",
                  "alumfullfabricwithborder_1",
                  "alumfullfabricwithborder_1_nobrackets",
                  "fullfabricrounded_1",
                  "fullfabricrounded_1_nobrackets",
                  "fullpanels_1",
                  "livoscreen_1",
                  "roundedglass_1",
                  "chamfered_dcut",
                  "postform_dcut",
                  "straightedge_dcut",
                  "chamferedge_flipup_RHS",
                  "flipup_RHS",
                  "postformedge_flipup_RHS",
                  "chamferedge_flipup_LHS",
                  "flipup_LHS",
                  "postformedge_flipup_LHS",
                  "alumfullfabric_end",
                  "alumfullfabricwithborder__end",
                  "alumfullfabricwithborder_nobrackets_end",
                  "fullfabricrounded_end",
                  "fullfabricrounded_nobrackets_end",
                  "livoscreen_end",
                  "hanging_aluminium",
                  "hanging_framed",
                  "hanging_nylon",
                  "alumfullfabric_end_single",
                  "alumfullfabricwithborder__end_single",
                  "fullfabricrounded_end_single",
                  "alumfullfabricwithborder_nobrackets_end_single",
                  "roundedglass_end_single",
                  "Xbench_end_single",
                  "fullfabricrounded_nobrackets_end_single",
                  "Xbench_nobrackets_end_single",
                  "alumfullfabric_1_single",
                  "alumfullfabricwithborder_1_single",
                  "alumfullfabricwithborder_1_nobrackets_single",
                  "fullfabricrounded_1_nobrackets",
                  "fullfabricrounded_1_nobrackets_single",
                  "livoscreen_1_single",
                  "roundedglass_end",
                  "alumfullfabricwithborder__end",
                  "alumfullfabricwithborder_end",
                  "roundedglass_end",
                  "alumfullfabric_end_single",
                  "alumfullfabricwithborder__end_single",
                  "alumfullfabricwithborder_nobrackets_end_single",
                  "fullfabricrounded_end_single",
                  "fullfabricrounded_nobrackets_end_single",
                  "livoscreen_end_single",
                  "roundedglass_end_single",
                  "alumfullfabric_1_single",
                  "alumfullfabricwithborder_1_nobrackets_single",
                  "alumfullfabricwithborder_1_single",
                  "fullfabricrounded_1_nobrackets_single",
                  "fullfabricrounded_1_single",
                  "livoscreen_1_single",
                  "roundedglass_1_single",
                ],
              },
            ],
            disableFeatures: [
              "Handles",
              "Locks",
              "Facia Woods",
              "Fabrics",
              "LeGs",
              "Carcass Woods",
            ],
            disableOptions: [
              "HANGING METAL MARKER 450WX300H",
              "HANGING METAL MARKER",
              "PANEL MOUNTED NAME PLATE",
              "HANGING METAL PAPER TRAY 222L",
              "HANGING METAL PEN HOLDER 70L",
              "HANGING METAL BAG HOOK",
              "HANGING METAL MARKER 300WX300H",
              "METAL SINGLE PAPER HOLDER",
            ],

            enableOptions: [],
            tableTop: [],
            intermediateLegs: [],
          },
          {
            name: "14 SEATER",
            size: 14,
            thumbnail: "/assets/afc/thumnail/lks/sharing/14seater.png",
            productId: "664db57fbf76f40c4391542c",
            enableFeatures: [
              "Frame Screen Return",
              "Sleek Screen Aluminum Beading Return",
              "Sleek Screen Nylon Beading Return",
              "Glass Return",
              "Full Panel Return",
              "Livo Panel Return",
              "Livo Highlighter's Color",
              "Acoustic fabric",
            ],
            rules: [
              {
                type: hide,
                node: [
                  "cabletray_flipup",
                  "cabletray_grommet",
                  "chamferedge_flipup",
                  "flipup",
                  "postformedge_flipup",
                  "alumfullfabric",
                  "alumfullfabricwithborder",
                  "alumfullfabricwithborder_nobrackets",
                  "alumhalfandhalf",
                  "fullfabricrounded",
                  "fullfabricrounded_nobrackets",
                  "fullpanels",
                  "halffabrichalfglass",
                  "livoscreen",
                  "panelwithplants",
                  "roundedglass",
                  "Xbench",
                  "Xbench_nobrackets",
                  "Xbenchrail",
                  "Xbenchrail_nobrackets",
                  "cpu_stand",
                  "hangingcpustand",
                  "hangingmetalbaghook",
                  "hangingmetalmarker_1",
                  "haningmetalpenholder",
                  "keyboardmousetray",
                  "keyboardtray",
                  "panelmountednameplate",
                  "metalmodesty",
                  "metalmodesty_proceed",
                  "metalmodesty_side",
                  "metalmodesty_slant",
                  "metalmodesty_sleek",
                  "metalmodesty_sleek_livo",
                  "woodmodesty",
                  "woodmodesty_proceed",
                  "woodmodesty_side",
                  "woodmodesty_slant",
                  "woodmodesty_sleek",
                  "woodmodesty_sleek_livo",
                  "chamfer_edge",
                  "postform_edge",
                  "grommet",
                  "legs_30*60_strip",
                  "legs_40*40_loop",
                  "legs_40*40_slanted",
                  "legs_curvivo",
                  "legs_deskpro_loop",
                  "legs_deskpro_slant",
                  "legs_deskpro_straight",
                  "legs_Livo",
                  "legs_livo_slanted",
                  "legs_proceed",
                  "legs_sleek",
                  "legs_triangular_loop",
                  "legs_triangular_slant",
                  "legs_triangular_straight",
                  "alumfullfabric_1",
                  "alumfullfabricwithborder_1",
                  "alumfullfabricwithborder_1_nobrackets",
                  "fullfabricrounded_1",
                  "fullfabricrounded_1_nobrackets",
                  "fullpanels_1",
                  "livoscreen_1",
                  "roundedglass_1",
                  "chamfered_dcut",
                  "postform_dcut",
                  "straightedge_dcut",
                  "chamferedge_flipup_RHS",
                  "flipup_RHS",
                  "postformedge_flipup_RHS",
                  "chamferedge_flipup_LHS",
                  "flipup_LHS",
                  "postformedge_flipup_LHS",
                  "alumfullfabric_end",
                  "alumfullfabricwithborder__end",
                  "alumfullfabricwithborder_nobrackets_end",
                  "fullfabricrounded_end",
                  "fullfabricrounded_nobrackets_end",
                  "livoscreen_end",
                  "hanging_aluminium",
                  "hanging_framed",
                  "hanging_nylon",
                  "alumfullfabric_end_single",
                  "alumfullfabricwithborder__end_single",
                  "fullfabricrounded_end_single",
                  "alumfullfabricwithborder_nobrackets_end_single",
                  "roundedglass_end_single",
                  "Xbench_end_single",
                  "fullfabricrounded_nobrackets_end_single",
                  "Xbench_nobrackets_end_single",
                  "alumfullfabric_1_single",
                  "alumfullfabricwithborder_1_single",
                  "alumfullfabricwithborder_1_nobrackets_single",
                  "fullfabricrounded_1_nobrackets",
                  "fullfabricrounded_1_nobrackets_single",
                  "livoscreen_1_single",
                  "roundedglass_end",
                  "alumfullfabricwithborder__end",
                  "alumfullfabricwithborder_end",
                  "roundedglass_end",
                  "alumfullfabric_end_single",
                  "alumfullfabricwithborder__end_single",
                  "alumfullfabricwithborder_nobrackets_end_single",
                  "fullfabricrounded_end_single",
                  "fullfabricrounded_nobrackets_end_single",
                  "livoscreen_end_single",
                  "roundedglass_end_single",
                  "alumfullfabric_1_single",
                  "alumfullfabricwithborder_1_nobrackets_single",
                  "alumfullfabricwithborder_1_single",
                  "fullfabricrounded_1_nobrackets_single",
                  "fullfabricrounded_1_single",
                  "livoscreen_1_single",
                  "roundedglass_1_single",
                ],
              },
            ],
            disableFeatures: [
              "Handles",
              "Locks",
              "Facia Woods",
              "Fabrics",
              "LeGs",
              "Carcass Woods",
            ],
            disableOptions: [
              "HANGING METAL MARKER 450WX300H",
              "HANGING METAL MARKER",
              "PANEL MOUNTED NAME PLATE",
              "HANGING METAL PAPER TRAY 222L",
              "HANGING METAL PEN HOLDER 70L",
              "HANGING METAL BAG HOOK",
              "HANGING METAL MARKER 300WX300H",
              "METAL SINGLE PAPER HOLDER",
            ],
            enableOptions: [],
            tableTop: [],
            intermediateLegs: [],
          },
          {
            name: "16 SEATER",
            size: 16,
            thumbnail: "/assets/afc/thumnail/lks/sharing/16seater.png",
            productId: "664da855b9780401dc9a902f",
            enableFeatures: [
              "Frame Screen Return",
              "Sleek Screen Aluminum Beading Return",
              "Sleek Screen Nylon Beading Return",
              "Glass Return",
              "Full Panel Return",
              "Livo Panel Return",
              "Livo Highlighter's Color",
              "Acoustic fabric",
            ],
            rules: [
              {
                type: hide,
                node: [
                  "cabletray_flipup",
                  "cabletray_grommet",
                  "chamferedge_flipup",
                  "flipup",
                  "postformedge_flipup",
                  "alumfullfabric",
                  "alumfullfabricwithborder",
                  "alumfullfabricwithborder_nobrackets",
                  "alumhalfandhalf",
                  "fullfabricrounded",
                  "fullfabricrounded_nobrackets",
                  "fullpanels",
                  "halffabrichalfglass",
                  "livoscreen",
                  "panelwithplants",
                  "roundedglass",
                  "Xbench",
                  "Xbench_nobrackets",
                  "Xbenchrail",
                  "Xbenchrail_nobrackets",
                  "cpu_stand",
                  "hangingcpustand",
                  "hangingmetalbaghook",
                  "hangingmetalmarker_1",
                  "haningmetalpenholder",
                  "keyboardmousetray",
                  "keyboardtray",
                  "panelmountednameplate",
                  "metalmodesty",
                  "metalmodesty_proceed",
                  "metalmodesty_side",
                  "metalmodesty_slant",
                  "metalmodesty_sleek",
                  "metalmodesty_sleek_livo",
                  "woodmodesty",
                  "woodmodesty_proceed",
                  "woodmodesty_side",
                  "woodmodesty_slant",
                  "woodmodesty_sleek",
                  "woodmodesty_sleek_livo",
                  "chamfer_edge",
                  "postform_edge",
                  "grommet",
                  "legs_30*60_strip",
                  "legs_40*40_loop",
                  "legs_40*40_slanted",
                  "legs_curvivo",
                  "legs_deskpro_loop",
                  "legs_deskpro_slant",
                  "legs_deskpro_straight",
                  "legs_Livo",
                  "legs_livo_slanted",
                  "legs_proceed",
                  "legs_sleek",
                  "legs_triangular_loop",
                  "legs_triangular_slant",
                  "legs_triangular_straight",
                  "alumfullfabric_1",
                  "alumfullfabricwithborder_1",
                  "alumfullfabricwithborder_1_nobrackets",
                  "fullfabricrounded_1",
                  "fullfabricrounded_1_nobrackets",
                  "fullpanels_1",
                  "livoscreen_1",
                  "roundedglass_1",
                  "chamfered_dcut",
                  "postform_dcut",
                  "straightedge_dcut",
                  "chamferedge_flipup_RHS",
                  "flipup_RHS",
                  "postformedge_flipup_RHS",
                  "chamferedge_flipup_LHS",
                  "flipup_LHS",
                  "postformedge_flipup_LHS",
                  "alumfullfabric_end",
                  "alumfullfabricwithborder__end",
                  "alumfullfabricwithborder_nobrackets_end",
                  "fullfabricrounded_end",
                  "fullfabricrounded_nobrackets_end",
                  "livoscreen_end",
                  "hanging_aluminium",
                  "hanging_framed",
                  "hanging_nylon",
                  "alumfullfabric_end_single",
                  "alumfullfabricwithborder__end_single",
                  "fullfabricrounded_end_single",
                  "alumfullfabricwithborder_nobrackets_end_single",
                  "roundedglass_end_single",
                  "Xbench_end_single",
                  "fullfabricrounded_nobrackets_end_single",
                  "Xbench_nobrackets_end_single",
                  "alumfullfabric_1_single",
                  "alumfullfabricwithborder_1_single",
                  "alumfullfabricwithborder_1_nobrackets_single",
                  "fullfabricrounded_1_nobrackets",
                  "fullfabricrounded_1_nobrackets_single",
                  "livoscreen_1_single",
                  "roundedglass_end",
                  "alumfullfabricwithborder__end",
                  "alumfullfabricwithborder_end",
                  "roundedglass_end",
                  "alumfullfabric_end_single",
                  "alumfullfabricwithborder__end_single",
                  "alumfullfabricwithborder_nobrackets_end_single",
                  "fullfabricrounded_end_single",
                  "fullfabricrounded_nobrackets_end_single",
                  "livoscreen_end_single",
                  "roundedglass_end_single",
                  "alumfullfabric_1_single",
                  "alumfullfabricwithborder_1_nobrackets_single",
                  "alumfullfabricwithborder_1_single",
                  "fullfabricrounded_1_nobrackets_single",
                  "fullfabricrounded_1_single",
                  "livoscreen_1_single",
                  "roundedglass_1_single",
                ],
              },
            ],
            disableFeatures: [
              "Handles",
              "Locks",
              "Facia Woods",
              "Fabrics",
              "LeGs",
              "Carcass Woods",
            ],
            disableOptions: [
              "HANGING METAL MARKER 450WX300H",
              "HANGING METAL MARKER",
              "PANEL MOUNTED NAME PLATE",
              "HANGING METAL PAPER TRAY 222L",
              "HANGING METAL PEN HOLDER 70L",
              "HANGING METAL BAG HOOK",
              "HANGING METAL MARKER 300WX300H",
              "METAL SINGLE PAPER HOLDER",
            ],
            enableOptions: [],
            tableTop: [],
            intermediateLegs: [],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "1 SEATER",
            size: 1,
            thumbnail: "/assets/afc/thumnail/lks/nonsharing/1seater.png",
            productId: "664ecbcc73e5f22dcc6ed2a7", //612fccfb5c51e05fa805f84e
            tableTop: ["table_top_nonshared"],
            rules: [
              {
                type: hide,
                node: [
                  "cabletray_flipup",
                  "cabletray_grommet",
                  "chamferedge_flipup",
                  "flipup",
                  "postformedge_flipup",
                  "alumfullfabric",
                  "alumfullfabricwithborder",
                  "alumfullfabricwithborder_nobrackets",
                  "alumhalfandhalf",
                  "fullfabricrounded",
                  "fullfabricrounded_nobrackets",
                  "fullpanels",
                  "halffabrichalfglass",
                  "livoscreen",
                  "panelwithplants",
                  "roundedglass",
                  "Xbench",
                  "Xbench_nobrackets",
                  "Xbenchrail",
                  "Xbenchrail_nobrackets",
                  "cpu_stand",
                  "hangingcpustand",
                  "hangingmetalbaghook",
                  "hangingmetalmarker_1",
                  "haningmetalpenholder",
                  "keyboardmousetray",
                  "keyboardtray",
                  "panelmountednameplate",
                  "metalmodesty",
                  "metalmodesty_proceed",
                  "metalmodesty_side",
                  "metalmodesty_slant",
                  "metalmodesty_sleek",
                  "metalmodesty_sleek_livo",
                  "woodmodesty",
                  "woodmodesty_proceed",
                  "woodmodesty_side",
                  "woodmodesty_slant",
                  "woodmodesty_sleek",
                  "woodmodesty_sleek_livo",
                  "chamfer_edge",
                  "postform_edge",
                  "grommet",
                  "legs_30*60_strip",
                  "legs_40*40_loop",
                  "legs_40*40_slanted",
                  "legs_curvivo",
                  "legs_deskpro_loop",
                  "legs_deskpro_slant",
                  "legs_deskpro_straight",
                  "legs_Livo",
                  "legs_livo_slanted",
                  "legs_proceed",
                  "legs_sleek",
                  "legs_triangular_loop",
                  "legs_triangular_slant",
                  "legs_triangular_straight",
                  "alumfullfabric_1",
                  "alumfullfabricwithborder_1",
                  "alumfullfabricwithborder_1_nobrackets",
                  "fullfabricrounded_1",
                  "fullfabricrounded_1_nobrackets",
                  "fullpanels_1",
                  "livoscreen_1",
                  "roundedglass_1",
                  "chamfered_dcut",
                  "postform_dcut",
                  "straightedge_dcut",
                  "chamferedge_flipup_RHS",
                  "flipup_RHS",
                  "postformedge_flipup_RHS",
                  "chamferedge_flipup_LHS",
                  "flipup_LHS",
                  "postformedge_flipup_LHS",
                  "alumfullfabric_end",
                  "alumfullfabricwithborder__end",
                  "alumfullfabricwithborder_nobrackets_end",
                  "fullfabricrounded_end",
                  "fullfabricrounded_nobrackets_end",
                  "livoscreen_end",
                  "hanging_aluminium",
                  "hanging_framed",
                  "hanging_nylon",
                  "alumfullfabric_end_single",
                  "alumfullfabricwithborder__end_single",
                  "fullfabricrounded_end_single",
                  "alumfullfabricwithborder_nobrackets_end_single",
                  "roundedglass_end_single",
                  "Xbench_end_single",
                  "fullfabricrounded_nobrackets_end_single",
                  "Xbench_nobrackets_end_single",
                  "alumfullfabric_1_single",
                  "alumfullfabricwithborder_1_single",
                  "alumfullfabricwithborder_1_nobrackets_single",
                  "fullfabricrounded_1_nobrackets",
                  "fullfabricrounded_1_nobrackets_single",
                  "livoscreen_1_single",
                  "roundedglass_end",
                  "alumfullfabricwithborder__end",
                  "alumfullfabricwithborder_end",
                  "roundedglass_end",
                ],
              },
            ],
            disableFeatures: [
              "Handles",
              "Locks",
              "Facia Woods",
              "Fabrics",
              "LeGs",
              "Carcass Woods",
              "Frame Screen Return",
              "Sleek Screen Aluminum Beading Return",
              "Sleek Screen Nylon Beading Return",
              "Glass Return",
              "Full Panel Return",
              "Livo Panel Return",
              "Livo Highlighter's Color",
              "Acoustic fabric",
            ],

            disableOptions: [
              "HANGING METAL MARKER 450WX300H",
              "HANGING METAL MARKER",
              "PANEL MOUNTED NAME PLATE",
              "HANGING METAL PAPER TRAY 222L",
              "HANGING METAL PEN HOLDER 70L",
              "HANGING METAL BAG HOOK",
              "HANGING METAL MARKER 300WX300H",
              "METAL SINGLE PAPER HOLDER",
            ],
            rightLegs: ["right_leg"],
            intermediateLegs: ["intermediate_wireentry"],
          },
          {
            name: "2 SEATER",
            size: 2,
            thumbnail: "/assets/afc/thumnail/lks/nonsharing/2seater.png",
            productId: "664ee8e80b33fa67708c55fd", //612fcf025c51e05fa805f854
            tableTop: ["table_top_nonshared"],
            leftLegs: ["left_leg"],
            rules: [
              {
                type: hide,
                node: [
                  "cabletray_flipup",
                  "cabletray_grommet",
                  "chamferedge_flipup",
                  "flipup",
                  "postformedge_flipup",
                  "alumfullfabric",
                  "alumfullfabricwithborder",
                  "alumfullfabricwithborder_nobrackets",
                  "alumhalfandhalf",
                  "fullfabricrounded",
                  "fullfabricrounded_nobrackets",
                  "fullpanels",
                  "halffabrichalfglass",
                  "livoscreen",
                  "panelwithplants",
                  "roundedglass",
                  "Xbench",
                  "Xbench_nobrackets",
                  "Xbenchrail",
                  "Xbenchrail_nobrackets",
                  "cpu_stand",
                  "hangingcpustand",
                  "hangingmetalbaghook",
                  "hangingmetalmarker_1",
                  "haningmetalpenholder",
                  "keyboardmousetray",
                  "keyboardtray",
                  "panelmountednameplate",
                  "metalmodesty",
                  "metalmodesty_proceed",
                  "metalmodesty_side",
                  "metalmodesty_slant",
                  "metalmodesty_sleek",
                  "metalmodesty_sleek_livo",
                  "woodmodesty",
                  "woodmodesty_proceed",
                  "woodmodesty_side",
                  "woodmodesty_slant",
                  "woodmodesty_sleek",
                  "woodmodesty_sleek_livo",
                  "chamfer_edge",
                  "postform_edge",
                  "grommet",
                  "legs_30*60_strip",
                  "legs_40*40_loop",
                  "legs_40*40_slanted",
                  "legs_curvivo",
                  "legs_deskpro_loop",
                  "legs_deskpro_slant",
                  "legs_deskpro_straight",
                  "legs_Livo",
                  "legs_livo_slanted",
                  "legs_proceed",
                  "legs_sleek",
                  "legs_triangular_loop",
                  "legs_triangular_slant",
                  "legs_triangular_straight",
                  "alumfullfabric_1",
                  "alumfullfabricwithborder_1",
                  "alumfullfabricwithborder_1_nobrackets",
                  "fullfabricrounded_1",
                  "fullfabricrounded_1_nobrackets",
                  "fullpanels_1",
                  "livoscreen_1",
                  "roundedglass_1",
                  "chamfered_dcut",
                  "postform_dcut",
                  "straightedge_dcut",
                  "chamferedge_flipup_RHS",
                  "flipup_RHS",
                  "postformedge_flipup_RHS",
                  "chamferedge_flipup_LHS",
                  "flipup_LHS",
                  "postformedge_flipup_LHS",
                  "alumfullfabric_end",
                  "alumfullfabricwithborder__end",
                  "alumfullfabricwithborder_nobrackets_end",
                  "fullfabricrounded_end",
                  "fullfabricrounded_nobrackets_end",
                  "livoscreen_end",
                  "hanging_aluminium",
                  "hanging_framed",
                  "hanging_nylon",
                  "alumfullfabric_end_single",
                  "alumfullfabricwithborder__end_single",
                  "fullfabricrounded_end_single",
                  "alumfullfabricwithborder_nobrackets_end_single",
                  "roundedglass_end_single",
                  "Xbench_end_single",
                  "fullfabricrounded_nobrackets_end_single",
                  "Xbench_nobrackets_end_single",
                  "alumfullfabric_1_single",
                  "alumfullfabricwithborder_1_single",
                  "alumfullfabricwithborder_1_nobrackets_single",
                  "fullfabricrounded_1_nobrackets",
                  "fullfabricrounded_1_nobrackets_single",
                  "livoscreen_1_single",
                  "roundedglass_end",
                  "alumfullfabricwithborder__end",
                  "alumfullfabricwithborder_end",
                  "roundedglass_end",
                ],
              },
            ],
            disableFeatures: [
              "Handles",
              "Locks",
              "Facia Woods",
              "Fabrics",
              "LeGs",
              "Carcass Woods",
            ],
            disableOptions: [
              "HANGING METAL MARKER 450WX300H",
              "HANGING METAL MARKER",
              "PANEL MOUNTED NAME PLATE",
              "HANGING METAL PAPER TRAY 222L",
              "HANGING METAL PEN HOLDER 70L",
              "HANGING METAL BAG HOOK",
              "HANGING METAL MARKER 300WX300H",
              "METAL SINGLE PAPER HOLDER",
            ],
            enableFeatures: [
              "Frame Screen Return",
              "Sleek Screen Aluminum Beading Return",
              "Sleek Screen Nylon Beading Return",
              "Glass Return",
              "Full Panel Return",
              "Livo Panel Return",
              "Livo Highlighter's Color",
              "Acoustic fabric",
            ],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
          },
          {
            name: "3 SEATER",
            size: 3,
            thumbnail: "/assets/afc/thumnail/lks/nonsharing/3seater.png",
            productId: "664f09e2e5556bb1236201a4",
            tableTop: ["table_top_nonshared"],
            leftLegs: ["left_leg"],
            enableFeatures: [
              "Frame Screen Return",
              "Sleek Screen Aluminum Beading Return",
              "Sleek Screen Nylon Beading Return",
              "Glass Return",
              "Full Panel Return",
              "Livo Panel Return",
              "Livo Highlighter's Color",
              "Acoustic fabric",
            ],
            rules: [
              {
                type: hide,
                node: [
                  "cabletray_flipup",
                  "cabletray_grommet",
                  "chamferedge_flipup",
                  "flipup",
                  "postformedge_flipup",
                  "alumfullfabric",
                  "alumfullfabricwithborder",
                  "alumfullfabricwithborder_nobrackets",
                  "alumhalfandhalf",
                  "fullfabricrounded",
                  "fullfabricrounded_nobrackets",
                  "fullpanels",
                  "halffabrichalfglass",
                  "livoscreen",
                  "panelwithplants",
                  "roundedglass",
                  "Xbench",
                  "Xbench_nobrackets",
                  "Xbenchrail",
                  "Xbenchrail_nobrackets",
                  "cpu_stand",
                  "hangingcpustand",
                  "hangingmetalbaghook",
                  "hangingmetalmarker_1",
                  "haningmetalpenholder",
                  "keyboardmousetray",
                  "keyboardtray",
                  "panelmountednameplate",
                  "metalmodesty",
                  "metalmodesty_proceed",
                  "metalmodesty_side",
                  "metalmodesty_slant",
                  "metalmodesty_sleek",
                  "metalmodesty_sleek_livo",
                  "woodmodesty",
                  "woodmodesty_proceed",
                  "woodmodesty_side",
                  "woodmodesty_slant",
                  "woodmodesty_sleek",
                  "woodmodesty_sleek_livo",
                  "chamfer_edge",
                  "postform_edge",
                  "grommet",
                  "legs_30*60_strip",
                  "legs_40*40_loop",
                  "legs_40*40_slanted",
                  "legs_curvivo",
                  "legs_deskpro_loop",
                  "legs_deskpro_slant",
                  "legs_deskpro_straight",
                  "legs_Livo",
                  "legs_livo_slanted",
                  "legs_proceed",
                  "legs_sleek",
                  "legs_triangular_loop",
                  "legs_triangular_slant",
                  "legs_triangular_straight",
                  "alumfullfabric_1",
                  "alumfullfabricwithborder_1",
                  "alumfullfabricwithborder_1_nobrackets",
                  "fullfabricrounded_1",
                  "fullfabricrounded_1_nobrackets",
                  "fullpanels_1",
                  "livoscreen_1",
                  "roundedglass_1",
                  "chamfered_dcut",
                  "postform_dcut",
                  "straightedge_dcut",
                  "chamferedge_flipup_RHS",
                  "flipup_RHS",
                  "postformedge_flipup_RHS",
                  "chamferedge_flipup_LHS",
                  "flipup_LHS",
                  "postformedge_flipup_LHS",
                  "alumfullfabric_end",
                  "alumfullfabricwithborder__end",
                  "alumfullfabricwithborder_nobrackets_end",
                  "fullfabricrounded_end",
                  "fullfabricrounded_nobrackets_end",
                  "livoscreen_end",
                  "hanging_aluminium",
                  "hanging_framed",
                  "hanging_nylon",
                  "alumfullfabric_end_single",
                  "alumfullfabricwithborder__end_single",
                  "fullfabricrounded_end_single",
                  "alumfullfabricwithborder_nobrackets_end_single",
                  "roundedglass_end_single",
                  "Xbench_end_single",
                  "fullfabricrounded_nobrackets_end_single",
                  "Xbench_nobrackets_end_single",
                  "alumfullfabric_1_single",
                  "alumfullfabricwithborder_1_single",
                  "alumfullfabricwithborder_1_nobrackets_single",
                  "fullfabricrounded_1_nobrackets",
                  "fullfabricrounded_1_nobrackets_single",
                  "livoscreen_1_single",
                  "roundedglass_end",
                  "alumfullfabricwithborder__end",
                  "alumfullfabricwithborder_end",
                  "roundedglass_end",
                  "alumfullfabric_end_single",
                  "alumfullfabricwithborder__end_single",
                  "alumfullfabricwithborder_nobrackets_end_single",
                  "fullfabricrounded_end_single",
                  "fullfabricrounded_nobrackets_end_single",
                  "livoscreen_end_single",
                  "roundedglass_end_single",
                  "alumfullfabric_1_single",
                  "alumfullfabricwithborder_1_nobrackets_single",
                  "alumfullfabricwithborder_1_single",
                  "fullfabricrounded_1_nobrackets_single",
                  "fullfabricrounded_1_single",
                  "livoscreen_1_single",
                  "roundedglass_1_single",
                ],
              },
            ],
            disableFeatures: [
              "Handles",
              "Locks",
              "Facia Woods",
              "Fabrics",
              "LeGs",
              "Carcass Woods",
              "Facia Powder CoAt",
              "Carcass Powder CoAt",
              "Raiser Leg 1",
              "Raiser Leg 2",
              "Raiser Leg 3",
              "Raiser Leg 4",
              "Raiser Leg 5",
              "Raiser Leg 6",
              "Raiser Leg 7",
              "Raiser Leg 8",
              "Raiser Leg 9",
              "Raiser Leg 10",
              "Raiser Leg 11",
              "Raiser Leg 12",
              "Wire Manager for Standard Top",
              "Wire Manager for Chamfer Top",
              "Wire Manager for Postform Top",
              "Waterfall Raceway Options",
              "Livo Highlighter's Color",
              "Strip PowderCoat Color",
              "Acoustic fabric",
            ],
            disableOptions: [
              "HANGING METAL MARKER 450WX300H",
              "HANGING METAL MARKER",
              "PANEL MOUNTED NAME PLATE",
              "HANGING METAL PAPER TRAY 222L",
              "HANGING METAL PEN HOLDER 70L",
              "HANGING METAL BAG HOOK",
              "HANGING METAL MARKER 300WX300H",
              "METAL SINGLE PAPER HOLDER",
            ],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
          },
          {
            name: "4 SEATER",
            size: 4,
            thumbnail: "/assets/afc/thumnail/lks/nonsharing/4seater.png",
            productId: "6650483e127637d9e30bbd8f", //612fd2be34bcffa99edb8e93
            tableTop: ["table_top_nonshared"],
            enableFeatures: [
              "Frame Screen Return",
              "Sleek Screen Aluminum Beading Return",
              "Sleek Screen Nylon Beading Return",
              "Glass Return",
              "Full Panel Return",
              "Livo Panel Return",
              "Livo Highlighter's Color",
              "Acoustic fabric",
            ],
            leftLegs: ["left_leg"],
            rules: [
              {
                type: hide,
                node: [
                  "cabletray_flipup",
                  "cabletray_grommet",
                  "chamferedge_flipup",
                  "flipup",
                  "postformedge_flipup",
                  "alumfullfabric",
                  "alumfullfabricwithborder",
                  "alumfullfabricwithborder_nobrackets",
                  "alumhalfandhalf",
                  "fullfabricrounded",
                  "fullfabricrounded_nobrackets",
                  "fullpanels",
                  "halffabrichalfglass",
                  "livoscreen",
                  "panelwithplants",
                  "roundedglass",
                  "Xbench",
                  "Xbench_nobrackets",
                  "Xbenchrail",
                  "Xbenchrail_nobrackets",
                  "cpu_stand",
                  "hangingcpustand",
                  "hangingmetalbaghook",
                  "hangingmetalmarker_1",
                  "haningmetalpenholder",
                  "keyboardmousetray",
                  "keyboardtray",
                  "panelmountednameplate",
                  "metalmodesty",
                  "metalmodesty_proceed",
                  "metalmodesty_side",
                  "metalmodesty_slant",
                  "metalmodesty_sleek",
                  "metalmodesty_sleek_livo",
                  "woodmodesty",
                  "woodmodesty_proceed",
                  "woodmodesty_side",
                  "woodmodesty_slant",
                  "woodmodesty_sleek",
                  "woodmodesty_sleek_livo",
                  "chamfer_edge",
                  "postform_edge",
                  "grommet",
                  "legs_30*60_strip",
                  "legs_40*40_loop",
                  "legs_40*40_slanted",
                  "legs_curvivo",
                  "legs_deskpro_loop",
                  "legs_deskpro_slant",
                  "legs_deskpro_straight",
                  "legs_Livo",
                  "legs_livo_slanted",
                  "legs_proceed",
                  "legs_sleek",
                  "legs_triangular_loop",
                  "legs_triangular_slant",
                  "legs_triangular_straight",
                  "alumfullfabric_1",
                  "alumfullfabricwithborder_1",
                  "alumfullfabricwithborder_1_nobrackets",
                  "fullfabricrounded_1",
                  "fullfabricrounded_1_nobrackets",
                  "fullpanels_1",
                  "livoscreen_1",
                  "roundedglass_1",
                  "chamfered_dcut",
                  "postform_dcut",
                  "straightedge_dcut",
                  "chamferedge_flipup_RHS",
                  "flipup_RHS",
                  "postformedge_flipup_RHS",
                  "chamferedge_flipup_LHS",
                  "flipup_LHS",
                  "postformedge_flipup_LHS",
                  "alumfullfabric_end",
                  "alumfullfabricwithborder__end",
                  "alumfullfabricwithborder_nobrackets_end",
                  "fullfabricrounded_end",
                  "fullfabricrounded_nobrackets_end",
                  "livoscreen_end",
                  "hanging_aluminium",
                  "hanging_framed",
                  "hanging_nylon",
                  "alumfullfabric_end_single",
                  "alumfullfabricwithborder__end_single",
                  "fullfabricrounded_end_single",
                  "alumfullfabricwithborder_nobrackets_end_single",
                  "roundedglass_end_single",
                  "Xbench_end_single",
                  "fullfabricrounded_nobrackets_end_single",
                  "Xbench_nobrackets_end_single",
                  "alumfullfabric_1_single",
                  "alumfullfabricwithborder_1_single",
                  "alumfullfabricwithborder_1_nobrackets_single",
                  "fullfabricrounded_1_nobrackets",
                  "fullfabricrounded_1_nobrackets_single",
                  "livoscreen_1_single",
                  "roundedglass_end",
                  "alumfullfabricwithborder__end",
                  "alumfullfabricwithborder_end",
                  "roundedglass_end",
                  "alumfullfabric_end_single",
                  "alumfullfabricwithborder__end_single",
                  "alumfullfabricwithborder_nobrackets_end_single",
                  "fullfabricrounded_end_single",
                  "fullfabricrounded_nobrackets_end_single",
                  "livoscreen_end_single",
                  "roundedglass_end_single",
                  "alumfullfabric_1_single",
                  "alumfullfabricwithborder_1_nobrackets_single",
                  "alumfullfabricwithborder_1_single",
                  "fullfabricrounded_1_nobrackets_single",
                  "fullfabricrounded_1_single",
                  "livoscreen_1_single",
                  "roundedglass_1_single",
                ],
              },
            ],
            disableFeatures: [
              "Handles",
              "Locks",
              "Facia Woods",
              "Fabrics",
              "LeGs",
              "Carcass Woods",
              "Facia Powder CoAt",
              "Carcass Powder CoAt",
              "Raiser Leg 1",
              "Raiser Leg 2",
              "Raiser Leg 3",
              "Raiser Leg 4",
              "Raiser Leg 5",
              "Raiser Leg 6",
              "Raiser Leg 7",
              "Raiser Leg 8",
              "Raiser Leg 9",
              "Raiser Leg 10",
              "Raiser Leg 11",
              "Raiser Leg 12",
              "Wire Manager for Standard Top",
              "Wire Manager for Chamfer Top",
              "Wire Manager for Postform Top",
              "Waterfall Raceway Options",
              "Livo Highlighter's Color",
              "Strip PowderCoat Color",
              "Acoustic fabric",
            ],
            disableOptions: [
              "HANGING METAL MARKER 450WX300H",
              "HANGING METAL MARKER",
              "PANEL MOUNTED NAME PLATE",
              "HANGING METAL PAPER TRAY 222L",
              "HANGING METAL PEN HOLDER 70L",
              "HANGING METAL BAG HOOK",
              "HANGING METAL MARKER 300WX300H",
              "METAL SINGLE PAPER HOLDER",
            ],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
          },
          {
            name: "5 SEATER",
            size: 5,
            thumbnail: "/assets/afc/thumnail/lks/nonsharing/5seater.png",
            productId: "665046c1224e5de0089b65b2", //6650483e127637d9e30bbd8f
            tableTop: ["table_top_nonshared"],
            enableFeatures: [
              "Frame Screen Return",
              "Sleek Screen Aluminum Beading Return",
              "Sleek Screen Nylon Beading Return",
              "Glass Return",
              "Full Panel Return",
              "Livo Panel Return",
              "Livo Highlighter's Color",
              "Acoustic fabric",
            ],
            leftLegs: ["left_leg"],

            rules: [
              {
                type: hide,
                node: [
                  "cabletray_flipup",
                  "cabletray_grommet",
                  "chamferedge_flipup",
                  "flipup",
                  "postformedge_flipup",
                  "alumfullfabric",
                  "alumfullfabricwithborder",
                  "alumfullfabricwithborder_nobrackets",
                  "alumhalfandhalf",
                  "fullfabricrounded",
                  "fullfabricrounded_nobrackets",
                  "fullpanels",
                  "halffabrichalfglass",
                  "livoscreen",
                  "panelwithplants",
                  "roundedglass",
                  "Xbench",
                  "Xbench_nobrackets",
                  "Xbenchrail",
                  "Xbenchrail_nobrackets",
                  "cpu_stand",
                  "hangingcpustand",
                  "hangingmetalbaghook",
                  "hangingmetalmarker_1",
                  "haningmetalpenholder",
                  "keyboardmousetray",
                  "keyboardtray",
                  "panelmountednameplate",
                  "metalmodesty",
                  "metalmodesty_proceed",
                  "metalmodesty_side",
                  "metalmodesty_slant",
                  "metalmodesty_sleek",
                  "metalmodesty_sleek_livo",
                  "woodmodesty",
                  "woodmodesty_proceed",
                  "woodmodesty_side",
                  "woodmodesty_slant",
                  "woodmodesty_sleek",
                  "woodmodesty_sleek_livo",
                  "chamfer_edge",
                  "postform_edge",
                  "grommet",
                  "legs_30*60_strip",
                  "legs_40*40_loop",
                  "legs_40*40_slanted",
                  "legs_curvivo",
                  "legs_deskpro_loop",
                  "legs_deskpro_slant",
                  "legs_deskpro_straight",
                  "legs_Livo",
                  "legs_livo_slanted",
                  "legs_proceed",
                  "legs_sleek",
                  "legs_triangular_loop",
                  "legs_triangular_slant",
                  "legs_triangular_straight",
                  "alumfullfabric_1",
                  "alumfullfabricwithborder_1",
                  "alumfullfabricwithborder_1_nobrackets",
                  "fullfabricrounded_1",
                  "fullfabricrounded_1_nobrackets",
                  "fullpanels_1",
                  "livoscreen_1",
                  "roundedglass_1",
                  "chamfered_dcut",
                  "postform_dcut",
                  "straightedge_dcut",
                  "chamferedge_flipup_RHS",
                  "flipup_RHS",
                  "postformedge_flipup_RHS",
                  "chamferedge_flipup_LHS",
                  "flipup_LHS",
                  "postformedge_flipup_LHS",
                  "alumfullfabric_end",
                  "alumfullfabricwithborder__end",
                  "alumfullfabricwithborder_nobrackets_end",
                  "fullfabricrounded_end",
                  "fullfabricrounded_nobrackets_end",
                  "livoscreen_end",
                  "hanging_aluminium",
                  "hanging_framed",
                  "hanging_nylon",
                  "alumfullfabric_end_single",
                  "alumfullfabricwithborder__end_single",
                  "fullfabricrounded_end_single",
                  "alumfullfabricwithborder_nobrackets_end_single",
                  "roundedglass_end_single",
                  "Xbench_end_single",
                  "fullfabricrounded_nobrackets_end_single",
                  "Xbench_nobrackets_end_single",
                  "alumfullfabric_1_single",
                  "alumfullfabricwithborder_1_single",
                  "alumfullfabricwithborder_1_nobrackets_single",
                  "fullfabricrounded_1_nobrackets",
                  "fullfabricrounded_1_nobrackets_single",
                  "livoscreen_1_single",
                  "roundedglass_end",
                  "alumfullfabricwithborder__end",
                  "alumfullfabricwithborder_end",
                  "roundedglass_end",
                  "alumfullfabric_end_single",
                  "alumfullfabricwithborder__end_single",
                  "alumfullfabricwithborder_nobrackets_end_single",
                  "fullfabricrounded_end_single",
                  "fullfabricrounded_nobrackets_end_single",
                  "livoscreen_end_single",
                  "roundedglass_end_single",
                  "alumfullfabric_1_single",
                  "alumfullfabricwithborder_1_nobrackets_single",
                  "alumfullfabricwithborder_1_single",
                  "fullfabricrounded_1_nobrackets_single",
                  "fullfabricrounded_1_single",
                  "livoscreen_1_single",
                  "roundedglass_1_single",
                ],
              },
            ],
            disableFeatures: [
              "Handles",
              "Locks",
              "Facia Woods",
              "Fabrics",
              "LeGs",
              "Carcass Woods",
              "Facia Powder CoAt",
              "Carcass Powder CoAt",
              "Raiser Leg 1",
              "Raiser Leg 2",
              "Raiser Leg 3",
              "Raiser Leg 4",
              "Raiser Leg 5",
              "Raiser Leg 6",
              "Raiser Leg 7",
              "Raiser Leg 8",
              "Raiser Leg 9",
              "Raiser Leg 10",
              "Raiser Leg 11",
              "Raiser Leg 12",
              "Wire Manager for Standard Top",
              "Wire Manager for Chamfer Top",
              "Wire Manager for Postform Top",
              "Waterfall Raceway Options",
              "Livo Highlighter's Color",
              "Strip PowderCoat Color",
              "Acoustic fabric",
            ],
            disableOptions: [
              "HANGING METAL MARKER 450WX300H",
              "HANGING METAL MARKER",
              "PANEL MOUNTED NAME PLATE",
              "HANGING METAL PAPER TRAY 222L",
              "HANGING METAL PEN HOLDER 70L",
              "HANGING METAL BAG HOOK",
              "HANGING METAL MARKER 300WX300H",
              "METAL SINGLE PAPER HOLDER",
            ],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
          },
          {
            name: "6 SEATER",
            size: 6,
            thumbnail: "/assets/afc/thumnail/lks/nonsharing/6seater.png",
            productId: "6650459b0aed6bc3aaacc870", //612fd493daa6800c05b5ea77
            tableTop: ["table_top_nonshared"],
            enableFeatures: [
              "Frame Screen Return",
              "Sleek Screen Aluminum Beading Return",
              "Sleek Screen Nylon Beading Return",
              "Glass Return",
              "Full Panel Return",
              "Livo Panel Return",
              "Livo Highlighter's Color",
              "Acoustic fabric",
            ],
            leftLegs: ["left_leg"],
            rules: [
              {
                type: hide,
                node: [
                  "cabletray_flipup",
                  "cabletray_grommet",
                  "chamferedge_flipup",
                  "flipup",
                  "postformedge_flipup",
                  "alumfullfabric",
                  "alumfullfabricwithborder",
                  "alumfullfabricwithborder_nobrackets",
                  "alumhalfandhalf",
                  "fullfabricrounded",
                  "fullfabricrounded_nobrackets",
                  "fullpanels",
                  "halffabrichalfglass",
                  "livoscreen",
                  "panelwithplants",
                  "roundedglass",
                  "Xbench",
                  "Xbench_nobrackets",
                  "Xbenchrail",
                  "Xbenchrail_nobrackets",
                  "cpu_stand",
                  "hangingcpustand",
                  "hangingmetalbaghook",
                  "hangingmetalmarker_1",
                  "haningmetalpenholder",
                  "keyboardmousetray",
                  "keyboardtray",
                  "panelmountednameplate",
                  "metalmodesty",
                  "metalmodesty_proceed",
                  "metalmodesty_side",
                  "metalmodesty_slant",
                  "metalmodesty_sleek",
                  "metalmodesty_sleek_livo",
                  "woodmodesty",
                  "woodmodesty_proceed",
                  "woodmodesty_side",
                  "woodmodesty_slant",
                  "woodmodesty_sleek",
                  "woodmodesty_sleek_livo",
                  "chamfer_edge",
                  "postform_edge",
                  "grommet",
                  "legs_30*60_strip",
                  "legs_40*40_loop",
                  "legs_40*40_slanted",
                  "legs_curvivo",
                  "legs_deskpro_loop",
                  "legs_deskpro_slant",
                  "legs_deskpro_straight",
                  "legs_Livo",
                  "legs_livo_slanted",
                  "legs_proceed",
                  "legs_sleek",
                  "legs_triangular_loop",
                  "legs_triangular_slant",
                  "legs_triangular_straight",
                  "alumfullfabric_1",
                  "alumfullfabricwithborder_1",
                  "alumfullfabricwithborder_1_nobrackets",
                  "fullfabricrounded_1",
                  "fullfabricrounded_1_nobrackets",
                  "fullpanels_1",
                  "livoscreen_1",
                  "roundedglass_1",
                  "chamfered_dcut",
                  "postform_dcut",
                  "straightedge_dcut",
                  "chamferedge_flipup_RHS",
                  "flipup_RHS",
                  "postformedge_flipup_RHS",
                  "chamferedge_flipup_LHS",
                  "flipup_LHS",
                  "postformedge_flipup_LHS",
                  "alumfullfabric_end",
                  "alumfullfabricwithborder__end",
                  "alumfullfabricwithborder_nobrackets_end",
                  "fullfabricrounded_end",
                  "fullfabricrounded_nobrackets_end",
                  "livoscreen_end",
                  "hanging_aluminium",
                  "hanging_framed",
                  "hanging_nylon",
                  "alumfullfabric_end_single",
                  "alumfullfabricwithborder__end_single",
                  "fullfabricrounded_end_single",
                  "alumfullfabricwithborder_nobrackets_end_single",
                  "roundedglass_end_single",
                  "Xbench_end_single",
                  "fullfabricrounded_nobrackets_end_single",
                  "Xbench_nobrackets_end_single",
                  "alumfullfabric_1_single",
                  "alumfullfabricwithborder_1_single",
                  "alumfullfabricwithborder_1_nobrackets_single",
                  "fullfabricrounded_1_nobrackets",
                  "fullfabricrounded_1_nobrackets_single",
                  "livoscreen_1_single",
                  "roundedglass_end",
                  "alumfullfabricwithborder__end",
                  "alumfullfabricwithborder_end",
                  "roundedglass_end",
                  "alumfullfabric_end_single",
                  "alumfullfabricwithborder__end_single",
                  "alumfullfabricwithborder_nobrackets_end_single",
                  "fullfabricrounded_end_single",
                  "fullfabricrounded_nobrackets_end_single",
                  "livoscreen_end_single",
                  "roundedglass_end_single",
                  "alumfullfabric_1_single",
                  "alumfullfabricwithborder_1_nobrackets_single",
                  "alumfullfabricwithborder_1_single",
                  "fullfabricrounded_1_nobrackets_single",
                  "fullfabricrounded_1_single",
                  "livoscreen_1_single",
                  "roundedglass_1_single",
                ],
              },
            ],
            disableFeatures: [
              "Handles",
              "Locks",
              "Facia Woods",
              "Fabrics",
              "LeGs",
              "Carcass Woods",
              "Facia Powder CoAt",
              "Carcass Powder CoAt",
              "Raiser Leg 1",
              "Raiser Leg 2",
              "Raiser Leg 3",
              "Raiser Leg 4",
              "Raiser Leg 5",
              "Raiser Leg 6",
              "Raiser Leg 7",
              "Raiser Leg 8",
              "Raiser Leg 9",
              "Raiser Leg 10",
              "Raiser Leg 11",
              "Raiser Leg 12",
              "Wire Manager for Standard Top",
              "Wire Manager for Chamfer Top",
              "Wire Manager for Postform Top",
              "Waterfall Raceway Options",
              "Livo Highlighter's Color",
              "Strip PowderCoat Color",
              "Acoustic fabric",
            ],
            disableOptions: [
              "HANGING METAL MARKER 450WX300H",
              "HANGING METAL MARKER",
              "PANEL MOUNTED NAME PLATE",
              "HANGING METAL PAPER TRAY 222L",
              "HANGING METAL PEN HOLDER 70L",
              "HANGING METAL BAG HOOK",
              "HANGING METAL MARKER 300WX300H",
              "METAL SINGLE PAPER HOLDER",
            ],

            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
          },
          {
            name: "7 SEATER",
            size: 7,
            thumbnail: "/assets/afc/thumnail/lks/nonsharing/7seater.png",
            productId: "665043d311f482737cccaf78", //612fd493daa6800c05b5ea77
            tableTop: ["table_top_nonshared"],
            enableFeatures: [
              "Frame Screen Return",
              "Sleek Screen Aluminum Beading Return",
              "Sleek Screen Nylon Beading Return",
              "Glass Return",
              "Full Panel Return",
              "Livo Panel Return",
              "Livo Highlighter's Color",
              "Acoustic fabric",
            ],
            leftLegs: ["left_leg"],
            rules: [
              {
                type: hide,
                node: [
                  "cabletray_flipup",
                  "cabletray_grommet",
                  "chamferedge_flipup",
                  "flipup",
                  "postformedge_flipup",
                  "alumfullfabric",
                  "alumfullfabricwithborder",
                  "alumfullfabricwithborder_nobrackets",
                  "alumhalfandhalf",
                  "fullfabricrounded",
                  "fullfabricrounded_nobrackets",
                  "fullpanels",
                  "halffabrichalfglass",
                  "livoscreen",
                  "panelwithplants",
                  "roundedglass",
                  "Xbench",
                  "Xbench_nobrackets",
                  "Xbenchrail",
                  "Xbenchrail_nobrackets",
                  "cpu_stand",
                  "hangingcpustand",
                  "hangingmetalbaghook",
                  "hangingmetalmarker_1",
                  "haningmetalpenholder",
                  "keyboardmousetray",
                  "keyboardtray",
                  "panelmountednameplate",
                  "metalmodesty",
                  "metalmodesty_proceed",
                  "metalmodesty_side",
                  "metalmodesty_slant",
                  "metalmodesty_sleek",
                  "metalmodesty_sleek_livo",
                  "woodmodesty",
                  "woodmodesty_proceed",
                  "woodmodesty_side",
                  "woodmodesty_slant",
                  "woodmodesty_sleek",
                  "woodmodesty_sleek_livo",
                  "chamfer_edge",
                  "postform_edge",
                  "grommet",
                  "legs_30*60_strip",
                  "legs_40*40_loop",
                  "legs_40*40_slanted",
                  "legs_curvivo",
                  "legs_deskpro_loop",
                  "legs_deskpro_slant",
                  "legs_deskpro_straight",
                  "legs_Livo",
                  "legs_livo_slanted",
                  "legs_proceed",
                  "legs_sleek",
                  "legs_triangular_loop",
                  "legs_triangular_slant",
                  "legs_triangular_straight",
                  "alumfullfabric_1",
                  "alumfullfabricwithborder_1",
                  "alumfullfabricwithborder_1_nobrackets",
                  "fullfabricrounded_1",
                  "fullfabricrounded_1_nobrackets",
                  "fullpanels_1",
                  "livoscreen_1",
                  "roundedglass_1",
                  "chamfered_dcut",
                  "postform_dcut",
                  "straightedge_dcut",
                  "chamferedge_flipup_RHS",
                  "flipup_RHS",
                  "postformedge_flipup_RHS",
                  "chamferedge_flipup_LHS",
                  "flipup_LHS",
                  "postformedge_flipup_LHS",
                  "alumfullfabric_end",
                  "alumfullfabricwithborder__end",
                  "alumfullfabricwithborder_nobrackets_end",
                  "fullfabricrounded_end",
                  "fullfabricrounded_nobrackets_end",
                  "livoscreen_end",
                  "hanging_aluminium",
                  "hanging_framed",
                  "hanging_nylon",
                  "alumfullfabric_end_single",
                  "alumfullfabricwithborder__end_single",
                  "fullfabricrounded_end_single",
                  "alumfullfabricwithborder_nobrackets_end_single",
                  "roundedglass_end_single",
                  "Xbench_end_single",
                  "fullfabricrounded_nobrackets_end_single",
                  "Xbench_nobrackets_end_single",
                  "alumfullfabric_1_single",
                  "alumfullfabricwithborder_1_single",
                  "alumfullfabricwithborder_1_nobrackets_single",
                  "fullfabricrounded_1_nobrackets",
                  "fullfabricrounded_1_nobrackets_single",
                  "livoscreen_1_single",
                  "roundedglass_end",
                  "alumfullfabricwithborder__end",
                  "alumfullfabricwithborder_end",
                  "roundedglass_end",
                  "alumfullfabric_end_single",
                  "alumfullfabricwithborder__end_single",
                  "alumfullfabricwithborder_nobrackets_end_single",
                  "fullfabricrounded_end_single",
                  "fullfabricrounded_nobrackets_end_single",
                  "livoscreen_end_single",
                  "roundedglass_end_single",
                  "alumfullfabric_1_single",
                  "alumfullfabricwithborder_1_nobrackets_single",
                  "alumfullfabricwithborder_1_single",
                  "fullfabricrounded_1_nobrackets_single",
                  "fullfabricrounded_1_single",
                  "livoscreen_1_single",
                  "roundedglass_1_single",
                ],
              },
            ],
            disableFeatures: [
              "Handles",
              "Locks",
              "Facia Woods",
              "Fabrics",
              "LeGs",
              "Carcass Woods",
            ],
            disableOptions: [
              "HANGING METAL MARKER 450WX300H",
              "HANGING METAL MARKER",
              "PANEL MOUNTED NAME PLATE",
              "HANGING METAL PAPER TRAY 222L",
              "HANGING METAL PEN HOLDER 70L",
              "HANGING METAL BAG HOOK",
              "HANGING METAL MARKER 300WX300H",
              "METAL SINGLE PAPER HOLDER",
            ],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
          },
          {
            name: "8 SEATER",
            size: 8,
            thumbnail: "/assets/afc/thumnail/lks/nonsharing/8seater.png",
            productId: "66503c8e70d7be8785578a42", //612fd493daa6800c05b5ea77
            tableTop: ["table_top_nonshared"],
            enableFeatures: [
              "Frame Screen Return",
              "Sleek Screen Aluminum Beading Return",
              "Sleek Screen Nylon Beading Return",
              "Glass Return",
              "Full Panel Return",
              "Livo Panel Return",
              "Livo Highlighter's Color",
              "Acoustic fabric",
            ],
            leftLegs: ["left_leg"],
            rules: [
              {
                type: hide,
                node: [
                  "cabletray_flipup",
                  "cabletray_grommet",
                  "chamferedge_flipup",
                  "flipup",
                  "postformedge_flipup",
                  "alumfullfabric",
                  "alumfullfabricwithborder",
                  "alumfullfabricwithborder_nobrackets",
                  "alumhalfandhalf",
                  "fullfabricrounded",
                  "fullfabricrounded_nobrackets",
                  "fullpanels",
                  "halffabrichalfglass",
                  "livoscreen",
                  "panelwithplants",
                  "roundedglass",
                  "Xbench",
                  "Xbench_nobrackets",
                  "Xbenchrail",
                  "Xbenchrail_nobrackets",
                  "cpu_stand",
                  "hangingcpustand",
                  "hangingmetalbaghook",
                  "hangingmetalmarker_1",
                  "haningmetalpenholder",
                  "keyboardmousetray",
                  "keyboardtray",
                  "panelmountednameplate",
                  "metalmodesty",
                  "metalmodesty_proceed",
                  "metalmodesty_side",
                  "metalmodesty_slant",
                  "metalmodesty_sleek",
                  "metalmodesty_sleek_livo",
                  "woodmodesty",
                  "woodmodesty_proceed",
                  "woodmodesty_side",
                  "woodmodesty_slant",
                  "woodmodesty_sleek",
                  "woodmodesty_sleek_livo",
                  "chamfer_edge",
                  "postform_edge",
                  "grommet",
                  "legs_30*60_strip",
                  "legs_40*40_loop",
                  "legs_40*40_slanted",
                  "legs_curvivo",
                  "legs_deskpro_loop",
                  "legs_deskpro_slant",
                  "legs_deskpro_straight",
                  "legs_Livo",
                  "legs_livo_slanted",
                  "legs_proceed",
                  "legs_sleek",
                  "legs_triangular_loop",
                  "legs_triangular_slant",
                  "legs_triangular_straight",
                  "alumfullfabric_1",
                  "alumfullfabricwithborder_1",
                  "alumfullfabricwithborder_1_nobrackets",
                  "fullfabricrounded_1",
                  "fullfabricrounded_1_nobrackets",
                  "fullpanels_1",
                  "livoscreen_1",
                  "roundedglass_1",
                  "chamfered_dcut",
                  "postform_dcut",
                  "straightedge_dcut",
                  "chamferedge_flipup_RHS",
                  "flipup_RHS",
                  "postformedge_flipup_RHS",
                  "chamferedge_flipup_LHS",
                  "flipup_LHS",
                  "postformedge_flipup_LHS",
                  "alumfullfabric_end",
                  "alumfullfabricwithborder__end",
                  "alumfullfabricwithborder_nobrackets_end",
                  "fullfabricrounded_end",
                  "fullfabricrounded_nobrackets_end",
                  "livoscreen_end",
                  "hanging_aluminium",
                  "hanging_framed",
                  "hanging_nylon",
                  "alumfullfabric_end_single",
                  "alumfullfabricwithborder__end_single",
                  "fullfabricrounded_end_single",
                  "alumfullfabricwithborder_nobrackets_end_single",
                  "roundedglass_end_single",
                  "Xbench_end_single",
                  "fullfabricrounded_nobrackets_end_single",
                  "Xbench_nobrackets_end_single",
                  "alumfullfabric_1_single",
                  "alumfullfabricwithborder_1_single",
                  "alumfullfabricwithborder_1_nobrackets_single",
                  "fullfabricrounded_1_nobrackets",
                  "fullfabricrounded_1_nobrackets_single",
                  "livoscreen_1_single",
                  "roundedglass_end",
                  "alumfullfabricwithborder__end",
                  "alumfullfabricwithborder_end",
                  "roundedglass_end",
                  "alumfullfabric_end_single",
                  "alumfullfabricwithborder__end_single",
                  "alumfullfabricwithborder_nobrackets_end_single",
                  "fullfabricrounded_end_single",
                  "fullfabricrounded_nobrackets_end_single",
                  "livoscreen_end_single",
                  "roundedglass_end_single",
                  "alumfullfabric_1_single",
                  "alumfullfabricwithborder_1_nobrackets_single",
                  "alumfullfabricwithborder_1_single",
                  "fullfabricrounded_1_nobrackets_single",
                  "fullfabricrounded_1_single",
                  "livoscreen_1_single",
                  "roundedglass_1_single",
                ],
              },
            ],
            disableFeatures: [
              "Handles",
              "Locks",
              "Facia Woods",
              "Fabrics",
              "LeGs",
              "Carcass Woods",
            ],
            disableOptions: [
              "HANGING METAL MARKER 450WX300H",
              "HANGING METAL MARKER",
              "PANEL MOUNTED NAME PLATE",
              "HANGING METAL PAPER TRAY 222L",
              "HANGING METAL PEN HOLDER 70L",
              "HANGING METAL BAG HOOK",
              "HANGING METAL MARKER 300WX300H",
              "METAL SINGLE PAPER HOLDER",
            ],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Dimensions",
    multiSelect: true,
    options: [
      {
        name: "SHARING",
        multiselect: false,
        options: [
          {
            name: "Width",
            required: false,
            dropDown: true,
            options: [
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
              {
                name: "1650 mm",
                value: 1650,
              },
              {
                name: "1800 mm",
                value: 1800,
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "675 mm",
                value: 675,
              },
              {
                name: "750 mm",
                value: 750,
              },
              {
                name: "900 mm",
                value: 900,
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        multiselect: false,
        options: [
          {
            name: "Width",
            required: false,
            dropDown: true,
            options: [
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
              {
                name: "1650 mm",
                value: 1650,
              },
              {
                name: "1800 mm",
                value: 1800,
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "675 mm",
                value: 675,
              },
              {
                name: "750 mm",
                value: 750,
              },
              {
                name: "900 mm",
                value: 900,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Table Top",
    required: false,
    multiSelect: true,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Table Top",
            required: false,
            options: [
              {
                name: "25 MM PLPB STANDARD TOP",
                enableFeatures: ["Wire Manager for Standard Top"],
                disableFeatures: [
                  "Wire Manager for Chamfer Top",
                  "Wire Manager for Postform Top",
                  "Waterfall Raceway Options",
                ],
                thumbnail: "/assets/afc/se.png",

                // rules: [
                //   {
                //     type: hide,
                //     node: [
                //       "flipup",
                //       "Raceway",
                //       "gorment2",
                //       "grommet",
                //       "gorment2@work",
                //       "gorment@work",
                //       "FlipUp@work",
                //       "wipro_grommet",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "cabletray_grommet",
                //       "cabletray_flipup",
                //       "reverse_waterfall",
                //       "reversewaterfall_flipup",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //       "flipup_RHS",
                //       "chamferedge_flipup_RHS",
                //       "postformedge_flipup_RHS",
                //       "reversewaterfall_flipup_RHS",
                //       "flipup_LHS",
                //       "chamferedge_flipup_LHS",
                //       "postformedge_flipup_LHS",
                //       "reversewaterfall_flipup_LHS",
                //       "flipup",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "reversewaterfall_flipup",
                //       "chamfer_edge",
                //       "postform_edge",
                //     ],
                //   },
                //   {
                //     type: "hide",
                //     node: [
                //       "chamfer_edge",
                //       "straight_edge",
                //       "prelammid@work",
                //       "prelamleft",
                //       "prelamright",
                //       "metalmodestyleft",
                //       "metalmodestyright",
                //       "postform_edge",
                //       "reverse_waterfall",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //     ],
                //   },
                //   {
                //     type: "show",
                //     node: ["straight_edge"],
                //   },
                // ],
              },
              {
                name: "25 MM PLPB CHAMFER EDGE WTOP",
                thumbnail: "/assets/afc/ce.png",
                enableFeatures: ["Wire Manager for Chamfer Top"],
                disableFeatures: [
                  "Wire Manager for Standard Top",
                  "Wire Manager for Postform Top",
                  "Waterfall Raceway Options",
                ],

                // rules: [
                //   {
                //     type: hide,
                //     node: [
                //       "flipup",
                //       "Raceway",
                //       "gorment2",
                //       "grommet",
                //       "gorment2@work",
                //       "gorment@work",
                //       "FlipUp@work",
                //       "wipro_grommet",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "cabletray_grommet",
                //       "cabletray_flipup",
                //       "reverse_waterfall",
                //       "reversewaterfall_flipup",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //       "flipup_RHS",
                //       "chamferedge_flipup_RHS",
                //       "postformedge_flipup_RHS",
                //       "reversewaterfall_flipup_RHS",
                //       "flipup_LHS",
                //       "chamferedge_flipup_LHS",
                //       "postformedge_flipup_LHS",
                //       "reversewaterfall_flipup_LHS",
                //       "flipup",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "reversewaterfall_flipup",
                //       "chamfer_edge",
                //       "postform_edge",
                //     ],
                //   },
                //   {
                //     type: "hide",
                //     node: [
                //       "chamfer_edge",
                //       "straight_edge",
                //       "prelammid@work",
                //       "prelamleft",
                //       "prelamright",
                //       "metalmodestyleft",
                //       "metalmodestyright",
                //       "postform_edge",
                //       "reverse_waterfall",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //     ],
                //   },
                //   {
                //     type: "show",
                //     node: ["chamfer_edge"],
                //   },
                // ],
              },
              {
                name: "25 MM PLPB POSTFORM TOP",
                enableFeatures: ["Wire Manager for Postform Top"],
                disableFeatures: [
                  "Wire Manager for Standard Top",
                  "Wire Manager for Chamfer Top",

                  "Waterfall Raceway Options",
                ],
                thumbnail: "/assets/afc/pf.png",

                // rules: [
                //   {
                //     type: hide,
                //     node: [
                //       "flipup",
                //       "Raceway",
                //       "gorment2",
                //       "grommet",
                //       "gorment2@work",
                //       "gorment@work",
                //       "FlipUp@work",
                //       "wipro_grommet",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "cabletray_grommet",
                //       "cabletray_flipup",
                //       "reverse_waterfall",
                //       "reversewaterfall_flipup",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //       "flipup_RHS",
                //       "chamferedge_flipup_RHS",
                //       "postformedge_flipup_RHS",
                //       "reversewaterfall_flipup_RHS",
                //       "flipup_LHS",
                //       "chamferedge_flipup_LHS",
                //       "postformedge_flipup_LHS",
                //       "reversewaterfall_flipup_LHS",
                //       "flipup",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "reversewaterfall_flipup",
                //       "chamfer_edge",
                //       "postform_edge",
                //     ],
                //   },
                //   {
                //     type: "hide",
                //     node: [
                //       "chamfer_edge",
                //       "straight_edge",
                //       "prelammid@work",
                //       "prelamleft",
                //       "prelamright",
                //       "metalmodestyleft",
                //       "metalmodestyright",
                //       "postform_edge",
                //       "reverse_waterfall",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //     ],
                //   },
                //   {
                //     type: "show",
                //     node: "postform_edge",
                //   },
                // ],
              },

              // {
              //   name: "25 MM PRELAMINATED MDF BOARD WTOP",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b57ab7c7402d4fb5251f1",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b5783c90a726a227b7c03",
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "connecter",
              //         "prelammid",
              //         "metalmodestymid",
              //         "prelamleft",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //         "prelamright",
              //       ],
              //     },
              //     {
              //       type: "show",
              //       node: ["workconnect"],
              //     },
              //   ],
              // },
              // {
              //   name: "25 MM MDF POSTFORMED BAORD WTOP",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "workconnect",
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //       ],
              //     },
              //   ],
              // },
              // {
              //   name: "25 MM PLY BOARD WTOP",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "workconnect",
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //       ],
              //     },
              //   ],
              // },
              // {
              //   name: "25 MM PLY POST FORMED BOARD WTOP",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "workconnect",
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //       ],
              //     },
              //   ],
              // },
              // {
              //   name: "25 MM WHITE MARKER LAMINATE IN PARTICLE",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "workconnect",
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //       ],
              //     },
              //   ],
              // },
              // {
              //   name: "25 MM MARINE PLY",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "workconnect",
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //       ],
              //     },
              //   ],
              // },
              // {
              //   name: "25 MM BWP PLY",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "workconnect",
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //       ],
              //     },
              //   ],
              // },
              // {
              //   name: "25 MM PRELAMINATED E1 GRADE BOARD WTOP",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "workconnect",
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //       ],
              //     },
              //   ],
              // },
              // {
              //   name: "25 THK COMM. PLY WITH 4 MM THK VENEER FINISH TOP",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "workconnect",
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //       ],
              //     },
              //  ],
              //   },
            ],
          },
          {
            name: "Wire Manager for Standard Top",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_grommet",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["straight_edge"],
                  },
                ],
              },
              {
                name: "D Cut",
                thumbnail: "/assets/afc/d-cut.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "flipup",
                      "cabletray_flipup",
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",

                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "chamfer_edge",
                      "straight_edge",
                      "postform_edge",
                      "reverse_waterfall",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["straightedge_dcut", "cabletray_grommet"],
                  },
                ],
              },

              {
                name: "Flip Up - Mid",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Left",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup_LHS", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Right",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup_RHS", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "straight_edge", "cabletray_grommet"],
                  },
                ],
              },
            ],
          },
          {
            name: "Wire Manager for Chamfer Top",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_grommet",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "straight_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamfer_edge"],
                  },
                ],
              },
              {
                name: "D Cut",
                thumbnail: "/assets/afc/d-cut.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "flipup",
                      "cabletray_flipup",
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",

                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "chamfer_edge",
                      "straight_edge",
                      "postform_edge",
                      "reverse_waterfall",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamfered_dcut", "cabletray_grommet"],
                  },
                ],
              },

              {
                name: "Flip Up - Mid",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Left",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup_LHS", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Right",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup_RHS", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "chamfer_edge", "cabletray_grommet"],
                  },
                ],
              },
            ],
          },
          {
            name: "Wire Manager for Postform Top",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_grommet",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "straight_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["postform_edge"],
                  },
                ],
              },
              {
                name: "D Cut",
                thumbnail: "/assets/afc/d-cut.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "flipup",
                      "cabletray_flipup",
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",

                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "chamfer_edge",
                      "straight_edge",
                      "postform_edge",
                      "reverse_waterfall",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["postform_dcut", "cabletray_grommet"],
                  },
                ],
              },

              {
                name: "Flip Up - Mid",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["postformedge_flipup", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Left",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["postformedge_flipup_LHS", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Right",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["postformedge_flipup_RHS", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "postform_edge", "cabletray_grommet"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Table Top",
            required: false,
            options: [
              {
                name: "25 MM PLPB STANDARD TOP",
                enableFeatures: ["Wire Manager for Standard Top"],
                disableFeatures: [
                  "Wire Manager for Chamfer Top",
                  "Wire Manager for Postform Top",
                  "Waterfall Raceway Options",
                ],
                thumbnail: "/assets/afc/se.png",

                // rules: [
                //   {
                //     type: hide,
                //     node: [
                //       "flipup",
                //       "Raceway",
                //       "gorment2",
                //       "grommet",
                //       "gorment2@work",
                //       "gorment@work",
                //       "FlipUp@work",
                //       "wipro_grommet",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "cabletray_grommet",
                //       "cabletray_flipup",
                //       "reverse_waterfall",
                //       "reversewaterfall_flipup",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //       "flipup_RHS",
                //       "chamferedge_flipup_RHS",
                //       "postformedge_flipup_RHS",
                //       "reversewaterfall_flipup_RHS",
                //       "flipup_LHS",
                //       "chamferedge_flipup_LHS",
                //       "postformedge_flipup_LHS",
                //       "reversewaterfall_flipup_LHS",
                //       "flipup",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "reversewaterfall_flipup",
                //       "chamfer_edge",
                //       "postform_edge",
                //     ],
                //   },
                //   {
                //     type: "hide",
                //     node: [
                //       "chamfer_edge",
                //       "straight_edge",
                //       "prelammid@work",
                //       "prelamleft",
                //       "prelamright",
                //       "metalmodestyleft",
                //       "metalmodestyright",
                //       "postform_edge",
                //       "reverse_waterfall",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //     ],
                //   },
                //   {
                //     type: "show",
                //     node: ["straight_edge"],
                //   },
                // ],
              },
              {
                name: "25 MM PLPB CHAMFER EDGE WTOP",
                thumbnail: "/assets/afc/ce.png",
                enableFeatures: ["Wire Manager for Chamfer Top"],
                disableFeatures: [
                  "Wire Manager for Standard Top",

                  "Wire Manager for Postform Top",
                  "Waterfall Raceway Options",
                ],

                // rules: [
                //   {
                //     type: hide,
                //     node: [
                //       "flipup",
                //       "Raceway",
                //       "gorment2",
                //       "grommet",
                //       "gorment2@work",
                //       "gorment@work",
                //       "FlipUp@work",
                //       "wipro_grommet",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "cabletray_grommet",
                //       "cabletray_flipup",
                //       "reverse_waterfall",
                //       "reversewaterfall_flipup",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //       "flipup_RHS",
                //       "chamferedge_flipup_RHS",
                //       "postformedge_flipup_RHS",
                //       "reversewaterfall_flipup_RHS",
                //       "flipup_LHS",
                //       "chamferedge_flipup_LHS",
                //       "postformedge_flipup_LHS",
                //       "reversewaterfall_flipup_LHS",
                //       "flipup",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "reversewaterfall_flipup",
                //       "chamfer_edge",
                //       "postform_edge",
                //     ],
                //   },
                //   {
                //     type: "hide",
                //     node: [
                //       "chamfer_edge",
                //       "straight_edge",
                //       "prelammid@work",
                //       "prelamleft",
                //       "prelamright",
                //       "metalmodestyleft",
                //       "metalmodestyright",
                //       "postform_edge",
                //       "reverse_waterfall",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //     ],
                //   },
                //   {
                //     type: "show",
                //     node: ["chamfer_edge"],
                //   },
                // ],
              },
              {
                name: "25 MM PLPB POSTFORM TOP",
                enableFeatures: ["Wire Manager for Postform Top"],
                disableFeatures: [
                  "Wire Manager for Standard Top",
                  "Wire Manager for Chamfer Top",

                  "Waterfall Raceway Options",
                ],
                thumbnail: "/assets/afc/pf.png",

                // rules: [
                //   {
                //     type: hide,
                //     node: [
                //       "flipup",
                //       "Raceway",
                //       "gorment2",
                //       "grommet",
                //       "gorment2@work",
                //       "gorment@work",
                //       "FlipUp@work",
                //       "wipro_grommet",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "cabletray_grommet",
                //       "cabletray_flipup",
                //       "reverse_waterfall",
                //       "reversewaterfall_flipup",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //       "flipup_RHS",
                //       "chamferedge_flipup_RHS",
                //       "postformedge_flipup_RHS",
                //       "reversewaterfall_flipup_RHS",
                //       "flipup_LHS",
                //       "chamferedge_flipup_LHS",
                //       "postformedge_flipup_LHS",
                //       "reversewaterfall_flipup_LHS",
                //       "flipup",
                //       "chamferedge_flipup",
                //       "postformedge_flipup",
                //       "reversewaterfall_flipup",
                //       "chamfer_edge",
                //       "postform_edge",
                //     ],
                //   },
                //   {
                //     type: "hide",
                //     node: [
                //       "chamfer_edge",
                //       "straight_edge",
                //       "prelammid@work",
                //       "prelamleft",
                //       "prelamright",
                //       "metalmodestyleft",
                //       "metalmodestyright",
                //       "postform_edge",
                //       "reverse_waterfall",
                //       "chamfered_dcut",
                //       "postform_dcut",
                //       "reversewaterfall_dcut",
                //       "straightedge_dcut",
                //     ],
                //   },
                //   {
                //     type: "show",
                //     node: "postform_edge",
                //   },
                // ],
              },

              // {
              //   name: "25 MM PRELAMINATED MDF BOARD WTOP",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b57ab7c7402d4fb5251f1",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b5783c90a726a227b7c03",
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "connecter",
              //         "prelammid",
              //         "metalmodestymid",
              //         "prelamleft",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //         "prelamright",
              //       ],
              //     },
              //     {
              //       type: "show",
              //       node: ["workconnect"],
              //     },
              //   ],
              // },
              // {
              //   name: "25 MM MDF POSTFORMED BAORD WTOP",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "workconnect",
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //       ],
              //     },
              //   ],
              // },
              // {
              //   name: "25 MM PLY BOARD WTOP",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "workconnect",
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //       ],
              //     },
              //   ],
              // },
              // {
              //   name: "25 MM PLY POST FORMED BOARD WTOP",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "workconnect",
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //       ],
              //     },
              //   ],
              // },
              // {
              //   name: "25 MM WHITE MARKER LAMINATE IN PARTICLE",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "workconnect",
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //       ],
              //     },
              //   ],
              // },
              // {
              //   name: "25 MM MARINE PLY",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "workconnect",
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //       ],
              //     },
              //   ],
              // },
              // {
              //   name: "25 MM BWP PLY",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "workconnect",
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //       ],
              //     },
              //   ],
              // },
              // {
              //   name: "25 MM PRELAMINATED E1 GRADE BOARD WTOP",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "workconnect",
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //       ],
              //     },
              //   ],
              // },
              // {
              //   name: "25 THK COMM. PLY WITH 4 MM THK VENEER FINISH TOP",
              //   thumbnail: "/assets/components/ColorsSharing.png",
              //   // enableFeatures: ["Color Options"],
              //   rules: [
              //     {
              //       type: "replacePart",
              //       node: "left_leg",
              //       pid: "663b585c7fc0a1b8456448c6",
              //     },
              //     {
              //       type: "replacePart",
              //       node: "right_leg",
              //       pid: "663b57db7c7402d4fb5251f9",
              //     },
              //     {
              //       type: "show",
              //       node: ["connecter"],
              //     },
              //     {
              //       type: "hide",
              //       node: [
              //         "workconnect",
              //         "metalmodestymid@work",
              //         "prelammid@work",
              //         "prelamleft",
              //         "prelamright",
              //         "metalmodestyleft",
              //         "metalmodestyright",
              //       ],
              //     },
              //  ],
              //   },
            ],
          },
          {
            name: "Wire Manager for Standard Top",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_grommet",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["straight_edge"],
                  },
                ],
              },
              {
                name: "D Cut",
                thumbnail: "/assets/afc/d-cut.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "flipup",
                      "cabletray_flipup",
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",

                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "chamfer_edge",
                      "straight_edge",
                      "postform_edge",
                      "reverse_waterfall",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["straightedge_dcut", "cabletray_grommet"],
                  },
                ],
              },

              {
                name: "Flip Up - Mid",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Left",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup_LHS", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Right",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["flipup_RHS", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "straight_edge", "cabletray_grommet"],
                  },
                ],
              },
            ],
          },
          {
            name: "Wire Manager for Chamfer Top",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_grommet",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "straight_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamfer_edge"],
                  },
                ],
              },
              {
                name: "D Cut",
                thumbnail: "/assets/afc/d-cut.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "flipup",
                      "cabletray_flipup",
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",

                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "chamfer_edge",
                      "straight_edge",
                      "postform_edge",
                      "reverse_waterfall",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamfered_dcut", "cabletray_grommet"],
                  },
                ],
              },

              {
                name: "Flip Up - Mid",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Left",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup_LHS", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Right",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["chamferedge_flipup_RHS", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "chamfer_edge", "cabletray_grommet"],
                  },
                ],
              },
            ],
          },
          {
            name: "Wire Manager for Postform Top",
            required: false,
            multiSelect: false,
            thumbnail: "/assets/components/ColorsSharing.png",

            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "flipup",
                      "Raceway",
                      "gorment2",
                      "grommet",
                      "gorment2@work",
                      "gorment@work",
                      "FlipUp@work",
                      "wipro_grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_grommet",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                      "straight_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["postform_edge"],
                  },
                ],
              },
              {
                name: "D Cut",
                thumbnail: "/assets/afc/d-cut.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "flipup",
                      "cabletray_flipup",
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",

                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "chamfer_edge",
                      "straight_edge",
                      "postform_edge",
                      "reverse_waterfall",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["postform_dcut", "cabletray_grommet"],
                  },
                ],
              },

              {
                name: "Flip Up - Mid",
                thumbnail: "/assets/afc/flipup.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["postformedge_flipup", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Left",
                thumbnail: "/assets/afc/fl.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["postformedge_flipup_LHS", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Flip Up - Right",
                thumbnail: "/assets/afc/fr.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],

                rules: [
                  {
                    type: hide,

                    node: [
                      "wipro_grommet",
                      "grommet",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "straight_edge",
                      "cabletray_grommet",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["postformedge_flipup_RHS", "cabletray_flipup"],
                  },
                ],
              },
              {
                name: "Grommet",
                thumbnail: "/assets/afc/grommet.png",
                // options: [...boxraceway],
                // enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: hide,
                    node: [
                      "wipro_grommet",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "cabletray_flipup",
                      "reverse_waterfall",
                      "reversewaterfall_flipup",
                      "chamfered_dcut",
                      "postform_dcut",
                      "reversewaterfall_dcut",
                      "straightedge_dcut",
                      "flipup_RHS",
                      "chamferedge_flipup_RHS",
                      "postformedge_flipup_RHS",
                      "reversewaterfall_flipup_RHS",
                      "flipup_LHS",
                      "chamferedge_flipup_LHS",
                      "postformedge_flipup_LHS",
                      "reversewaterfall_flipup_LHS",
                      "flipup",
                      "chamferedge_flipup",
                      "postformedge_flipup",
                      "reversewaterfall_flipup",
                      "chamfer_edge",
                      "postform_edge",
                    ],
                  },
                  {
                    type: show,

                    node: ["grommet", "postform_edge", "cabletray_grommet"],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Legs",
    multiSelect: true,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            /////
            name: "Legs",
            required: false,
            options: [
              {
                name: "40*40 Loop Leg",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_40*40_loop"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",

                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_curvivo",
                    ],
                  },
                ],
              },
              {
                name: "40*40 Slanted Leg",
                thumbnail: "/assets/afc/3slantleg.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_40*40_slanted"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",

                      "legs_40*40_straight",
                      "legs_curvivo",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Strip Leg",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: ["Livo Highlighter's Color"],
                enableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_strip"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_loop",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_curvivo",
                    ],
                  },
                ],
              },
              {
                name: "X-BENCH",
                thumbnail: "/assets/afc/x-bench.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_xbench"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_livo_slanted",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_proceed",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },
              {
                name: "PROOCED",
                thumbnail: "/assets/afc/proceed.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_proceed"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO STRAIGHT",
                thumbnail: "/assets/afc/deskpro-straight.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_straight"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO SLANT",
                thumbnail: "/assets/afc/deskpro-slant.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_slant"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO (LOOP)",
                thumbnail: "/assets/afc/deskpro-loop.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_loop"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },

              {
                name: "SLEEK LEG",
                thumbnail: "/assets/afc/sleek-legs.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_sleek"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },

              {
                name: "LIVO",
                thumbnail: "/assets/afc/livo.png",
                enableFeatures: ["Livo Highlighter's Color"],
                disableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: "show",
                    node: ["legs_Livo"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_curvivo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },
              {
                name: "CURVIVO",
                thumbnail: "/assets/afc/curvivo.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_curvivo"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },

              {
                name: "TRIANGULAR LEG (STRAIGHT)",
                thumbnail: "/assets/afc/triangular-leg-straight.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_triangular_straight"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },
              {
                name: "TRIANGULAR LEG (SLANT)",
                thumbnail: "/assets/afc/triangular-leg-slant.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_triangular_slant"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },
              {
                name: "TRIANGULAR LEG (LOOP)",
                thumbnail: "/assets/afc/triangular-leg-loop.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_triangular_loop"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "Livo Highlighter's Color",
            options: [
              {
                name: "Blue",
                thumbnail: "/assets/textures/Highlighter/Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/Blue.jpg",
                  },
                ],
              },
              {
                name: "Green",
                thumbnail: "/assets/textures/Highlighter/green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/green.jpg",
                  },
                ],
              },
              {
                name: "Orange",
                thumbnail: "/assets/textures/Highlighter/Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/Orange.jpg",
                  },
                ],
              },
              {
                name: "Yellow",
                thumbnail: "/assets/textures/Highlighter/yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/yellow.jpg",
                  },
                ],
              },
            ],
          },

          ///////
          {
            name: "Strip PowderCoat Color",
            options: [
              {
                name: "AFC Black",
                thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl: "/assets/textures/Metal/Black.jpg",
                  },
                ],
              },
              {
                name: "AFC White",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl:
                      "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
                  },
                ],
              },
              {
                name: "AFC Ivory",
                thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl: "/assets/textures/Metal/Ivory.jpg",
                  },
                ],
              },
              {
                name: "AFC Metallic Dark Grey",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
                  },
                ],
              },
              {
                name: "AFC Moonlight Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
                  },
                ],
              },
              {
                name: "AFC Silver Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            /////
            name: "Legs",
            required: false,
            options: [
              {
                name: "40*40 Loop Leg",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_40*40_loop"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",

                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_curvivo",
                    ],
                  },
                ],
              },
              {
                name: "40*40 Slanted Leg",
                thumbnail: "/assets/afc/3slantleg.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_40*40_slanted"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",

                      "legs_40*40_straight",
                      "legs_curvivo",
                    ],
                  },
                ],
              },
              {
                name: "30*60 Strip Leg",
                thumbnail: "/assets/afc/3loopleg.png",
                disableFeatures: ["Livo Highlighter's Color"],
                enableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: "show",
                    node: ["legs_30*60_strip"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_loop",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                      "legs_curvivo",
                    ],
                  },
                ],
              },
              {
                name: "X-BENCH",
                thumbnail: "/assets/afc/x-bench.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_xbench"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_livo_slanted",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_proceed",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },
              {
                name: "PROOCED",
                thumbnail: "/assets/afc/proceed.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_proceed"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO STRAIGHT",
                thumbnail: "/assets/afc/deskpro-straight.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_straight"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO SLANT",
                thumbnail: "/assets/afc/deskpro-slant.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_slant"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },
              {
                name: "DESKPRO (LOOP)",
                thumbnail: "/assets/afc/deskpro-loop.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_deskpro_loop"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },

              {
                name: "SLEEK LEG",
                thumbnail: "/assets/afc/sleek-legs.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_sleek"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },

              {
                name: "LIVO",
                thumbnail: "/assets/afc/livo.png",
                enableFeatures: ["Livo Highlighter's Color"],
                disableFeatures: ["Strip PowderCoat Color"],
                rules: [
                  {
                    type: "show",
                    node: ["legs_Livo"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_curvivo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },
              {
                name: "CURVIVO",
                thumbnail: "/assets/afc/curvivo.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_curvivo"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_sleek",
                      "legs_Livo",
                      "legs_livo_slanted",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },

              {
                name: "TRIANGULAR LEG (STRAIGHT)",
                thumbnail: "/assets/afc/triangular-leg-straight.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_triangular_straight"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_loop",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },
              {
                name: "TRIANGULAR LEG (SLANT)",
                thumbnail: "/assets/afc/triangular-leg-slant.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_triangular_slant"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_loop",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },
              {
                name: "TRIANGULAR LEG (LOOP)",
                thumbnail: "/assets/afc/triangular-leg-loop.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Strip PowderCoat Color",
                ],
                rules: [
                  {
                    type: "show",
                    node: ["legs_triangular_loop"],
                  },
                  {
                    type: "hide",
                    node: [
                      "legs_sleek",
                      "legs_deskpro_loop",
                      "legs_deskpro_straight",
                      "legs_deskpro_slant",
                      "legs_proceed",
                      "legs_xbench",
                      "legs_triangular_straight",
                      "legs_triangular_slant",
                      "legs_livo_slanted",
                      "legs_curvivo",
                      "legs_Livo",
                      "legs_30*60_loop",
                      "legs_30*60_straight",
                      "legs_30*60_slanted",
                      "legs_30*60_strip",
                      "legs_40*40_loop",
                      "legs_40*40_slanted",
                      "legs_40*40_straight",
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "Livo Highlighter's Color",
            options: [
              {
                name: "Blue",
                thumbnail: "/assets/textures/Highlighter/Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/Blue.jpg",
                  },
                ],
              },
              {
                name: "Green",
                thumbnail: "/assets/textures/Highlighter/green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/green.jpg",
                  },
                ],
              },
              {
                name: "Orange",
                thumbnail: "/assets/textures/Highlighter/Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/Orange.jpg",
                  },
                ],
              },
              {
                name: "Yellow",
                thumbnail: "/assets/textures/Highlighter/yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/yellow.jpg",
                  },
                ],
              },
            ],
          },

          ///////
          {
            name: "Strip PowderCoat Color",
            options: [
              {
                name: "AFC Black",
                thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl: "/assets/textures/Metal/Black.jpg",
                  },
                ],
              },
              {
                name: "AFC White",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl:
                      "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
                  },
                ],
              },
              {
                name: "AFC Ivory",
                thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl: "/assets/textures/Metal/Ivory.jpg",
                  },
                ],
              },
              {
                name: "AFC Metallic Dark Grey",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
                  },
                ],
              },
              {
                name: "AFC Moonlight Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
                  },
                ],
              },
              {
                name: "AFC Silver Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "stripcolor",
                    textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Laminate Options",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            // name: "Options",
            // required: false,
            // options: [
            //   {
            name: "Frosty White",
            thumbnail: "/assets/textures/Wood/thumbnail/white.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["wood"],
                textureUrl: "/assets/textures/Wood/2k/white.jpg",
              },
            ],
          },
          {
            name: "Slate Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/slategrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/slategrey.jpg",
              },
            ],
          },
          {
            name: "Gothic Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/gothicgrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/gothicgrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/silvergrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/silvergrey.jpg",
              },
            ],
          },
          {
            name: "Highland Pine",
            thumbnail: "/assets/textures/Wood/thumbnail/highland.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/highland.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia Light",
            thumbnail: "/assets/textures/Wood/thumbnail/acacialight.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/acacialight.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia Dark",
            thumbnail: "/assets/textures/Wood/thumbnail/acaciadark.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/acaciadark.jpg",
              },
            ],
          },
          {
            name: "Thansau Maple",
            thumbnail: "/assets/textures/Wood/thumbnail/thansau.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/thansau.jpg",
              },
            ],
          },
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood/thumbnail/mangfall.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/mangfall.jpg",
              },
            ],
          },
          {
            name: "Flowery Wenge",
            thumbnail: "/assets/textures/Wood/thumbnail/flowerywenge.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/flowerywenge.jpg",
              },
            ],
          },
          {
            name: "Persian Walnut",
            thumbnail: "/assets/textures/Wood/thumbnail/persian.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/persian.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood/thumbnail/urban.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/urban.jpg",
              },
            ],
          },
          // ],
          // },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            // name: "Options",
            // required: false,
            // options: [
            //   {
            name: "Frosty White",
            thumbnail: "/assets/textures/Wood/thumbnail/white.jpg",
            rules: [
              {
                type: applyMaterial,
                node: ["wood"],
                textureUrl: "/assets/textures/Wood/2k/white.jpg",
              },
            ],
          },
          {
            name: "Slate Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/slategrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/slategrey.jpg",
              },
            ],
          },
          {
            name: "Gothic Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/gothicgrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/gothicgrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/silvergrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/silvergrey.jpg",
              },
            ],
          },
          {
            name: "Highland Pine",
            thumbnail: "/assets/textures/Wood/thumbnail/highland.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/highland.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia Light",
            thumbnail: "/assets/textures/Wood/thumbnail/acacialight.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/acacialight.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia Dark",
            thumbnail: "/assets/textures/Wood/thumbnail/acaciadark.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/acaciadark.jpg",
              },
            ],
          },
          {
            name: "Thansau Maple",
            thumbnail: "/assets/textures/Wood/thumbnail/thansau.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/thansau.jpg",
              },
            ],
          },
          {
            name: "Mangfall Beech",
            thumbnail: "/assets/textures/Wood/thumbnail/mangfall.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/mangfall.jpg",
              },
            ],
          },
          {
            name: "Flowery Wenge",
            thumbnail: "/assets/textures/Wood/thumbnail/flowerywenge.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/flowerywenge.jpg",
              },
            ],
          },
          {
            name: "Persian Walnut",
            thumbnail: "/assets/textures/Wood/thumbnail/persian.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/persian.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood/thumbnail/urban.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "wood",
                textureUrl: "/assets/textures/Wood/2k/urban.jpg",
              },
            ],
          },
          // ],
          // },
        ],
      },
    ],
  },
  {
    name: "Powder Coat Options",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "AFC Black",
            thumbnail: "/assets/textures/Metal/thumbnail/black.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/black.jpeg",
              },
            ],
          },
          {
            name: "AFC White",
            thumbnail: "/assets/textures/Metal/thumbnail/white.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/white.jpeg",
              },
            ],
          },
          {
            name: "AFC Munshell Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/munshellgrey.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/munshellgrey.jpeg",
              },
            ],
          },
          {
            name: "AFC Graphite Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/graphitegrey.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/graphitegrey.jpeg",
              },
            ],
          },
          {
            name: "AFC Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/silvergrey.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/silvergrey.jpeg",
              },
            ],
          },
          {
            name: "AFC DA Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/dagrey.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/dagrey.jpeg",
              },
            ],
          },
          {
            name: "AFC Sky Blue",
            thumbnail: "/assets/textures/Metal/thumbnail/blue.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/blue.jpeg",
              },
            ],
          },
          {
            name: "AFC Sea Green",
            thumbnail: "/assets/textures/Metal/thumbnail/green.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/green.jpeg",
              },
            ],
          },
          {
            name: "AFC Light Orange",
            thumbnail: "/assets/textures/Metal/thumbnail/orange.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/orange.jpeg",
              },
            ],
          },
          {
            name: "AFC Misty Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/mistygrey.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/mistygrey.jpeg",
              },
            ],
          },
          {
            name: "AFC Nut Brown",
            thumbnail: "/assets/textures/Metal/thumbnail/nutbrown.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/nutbrown.jpeg",
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "AFC Black",
            thumbnail: "/assets/textures/Metal/thumbnail/black.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/black.jpeg",
              },
            ],
          },
          {
            name: "AFC White",
            thumbnail: "/assets/textures/Metal/thumbnail/white.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/white.jpeg",
              },
            ],
          },
          {
            name: "AFC Munshell Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/munshellgrey.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/munshellgrey.jpeg",
              },
            ],
          },
          {
            name: "AFC Graphite Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/graphitegrey.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/graphitegrey.jpeg",
              },
            ],
          },
          {
            name: "AFC Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/silvergrey.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/silvergrey.jpeg",
              },
            ],
          },
          {
            name: "AFC DA Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/dagrey.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/dagrey.jpeg",
              },
            ],
          },
          {
            name: "AFC Sky Blue",
            thumbnail: "/assets/textures/Metal/thumbnail/blue.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/blue.jpeg",
              },
            ],
          },
          {
            name: "AFC Sea Green",
            thumbnail: "/assets/textures/Metal/thumbnail/green.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/green.jpeg",
              },
            ],
          },
          {
            name: "AFC Light Orange",
            thumbnail: "/assets/textures/Metal/thumbnail/orange.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/orange.jpeg",
              },
            ],
          },
          {
            name: "AFC Misty Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/mistygrey.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/mistygrey.jpeg",
              },
            ],
          },
          {
            name: "AFC Nut Brown",
            thumbnail: "/assets/textures/Metal/thumbnail/nutbrown.jpeg",
            rules: [
              {
                type: applyMaterial,
                node: "leg",
                textureUrl: "/assets/textures/Metal/nutbrown.jpeg",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Main Privacy Screen",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "SHARING",
        required: false,

        options: [
          {
            name: "30mm Frame Screen",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Acoustic fabric",
                ],
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                ],
              },
              {
                name: "Pin-up Fabric",
                thumbnail: "/assets/afc/aff.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Acoustic fabric",
                ],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabric"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric",
              //   thumbnail: "/assets/afc/aff.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: [
              //     "Livo Highlighter's Color",
              //     "Acoustic fabric",
              //   ],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabric",
              //         "alumhalfandhalf",
              //         "halffabrichalfglass",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabric"],
              //     },
              //   ],
              // },
              {
                name: "Half Pin-up Fabric & White Marker",
                thumbnail: "/assets/afc/thumnail/half & half.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Acoustic fabric",
                ],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumhalfandhalf"],
                  },
                ],
              },
              // {
              //   name: "Half Magnetic Fabric & White Marker",
              //   thumbnail: "/assets/afc/thumnail/half & half.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: [
              //     "Livo Highlighter's Color",
              //     "Acoustic fabric",
              //   ],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabric",
              //         "alumhalfandhalf",
              //         "halffabrichalfglass",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumhalfandhalf"],
              //     },
              //   ],
              // },
              {
                name: "Pin-up Fabric + Glass Marker",
                thumbnail: "/assets/afc/aff.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Acoustic fabric",
                ],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                  {
                    type: show,
                    node: ["halffabrichalfglass"],
                  },
                ],
              },
              // {
              //   name: "Magnetic +Glass Marker",
              //   thumbnail: "/assets/afc/aff.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: [
              //     "Livo Highlighter's Color",
              //     "Acoustic fabric",
              //   ],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabric",
              //         "alumhalfandhalf",
              //         "halffabrichalfglass",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["halffabrichalfglass"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Sleek Screen Aluminum Beading Main",
            nodeName: ["aluminium_1", "alumfabric_1"],
            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Acoustic fabric",
                ],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],

                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder",
                      "alumfullfabricwithborder_nobrackets",
                    ],
                  },
                ],
              },
              {
                name: "Pin-up Fabric with brackets",
                thumbnail: "/assets/afc/thumnail/sleek screen Alumpinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Acoustic fabric",
                ],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder",
                      "alumfullfabricwithborder_nobrackets",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabricwithborder"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric without brackets",
                thumbnail:
                  "/assets/afc/thumnail/sleek screen Alumpinup without brackets.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Acoustic fabric",
                ],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder",
                      "alumfullfabricwithborder_nobrackets",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabricwithborder_nobrackets"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric with brackets",
              //   thumbnail: "/assets/afc/thumnail/sleek screen Alumpinup.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: [
              //     "Livo Highlighter's Color",
              //     "Acoustic fabric",
              //   ],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabricwithborder",
              //         "alumfullfabricwithborder_nobrackets",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabricwithborder"],
              //     },
              //   ],
              // },
              // {
              //   name: "Magnetic Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Alumpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: [
              //     "Livo Highlighter's Color",
              //     "Acoustic fabric",
              //   ],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabricwithborder",
              //         "alumfullfabricwithborder_nobrackets",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabricwithborder_nobrackets"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Screen with Nylon Beading",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Acoustic fabric",
                ],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["fullfabricrounded", "fullfabricrounded_nobrackets"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric with brackets",
                thumbnail: "/assets/afc/thumnail/sleek screen nylon pinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Acoustic fabric",
                ],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["fullfabricrounded", "fullfabricrounded_nobrackets"],
                  },
                  {
                    type: show,
                    node: ["fullfabricrounded"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric without brackets",
                thumbnail:
                  "/assets/afc/thumnail/sleek screen Nylonpinup without brackets.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Acoustic fabric",
                ],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["fullfabricrounded", "fullfabricrounded_nobrackets"],
                  },
                  {
                    type: show,
                    node: ["fullfabricrounded_nobrackets"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric with brackets",
              //   thumbnail: "/assets/afc/thumnail/sleek screen nylon pinup.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: [
              //     "Livo Highlighter's Color",
              //     "Acoustic fabric",
              //   ],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: ["fullfabricrounded", "fullfabricrounded_nobrackets"],
              //     },
              //     {
              //       type: show,
              //       node: ["fullfabricrounded"],
              //     },
              //   ],
              // },
              // {
              //   name: "Magnetic Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Nylonpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: [
              //     "Livo Highlighter's Color",
              //     "Acoustic fabric",
              //   ],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: ["fullfabricrounded", "fullfabricrounded_nobrackets"],
              //     },
              //     {
              //       type: show,
              //       node: ["fullfabricrounded_nobrackets"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Glass Main",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Acoustic fabric",
                ],
                // disableFeatures: ["Color Options Privacy"],
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["roundedglass"],
                  },
                ],
              },
              {
                name: "Sandwich glass",
                thumbnail: "/assets/afc/thumnail/Glass panel.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Acoustic fabric",
                ],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["roundedglass"],
                  },
                ],
              },
              // {
              //   name: "Sandwich magnetic glass",
              //   thumbnail: "/assets/afc/thumnail/Glass panel.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: [
              //     "Livo Highlighter's Color",
              //     "Acoustic fabric",
              //   ],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "show",
              //       node: ["roundedglass"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Xbench Main",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Acoustic fabric",
                ],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["Xbench", "Xbench_nobrackets"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric with brackets",
                thumbnail: "/assets/afc/thumnail/xbench brackets.png",
                disableFeatures: ["Livo Highlighter's Color"],
                enableFeatures: ["Acoustic fabric"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                //pid: "6122f35afe408b1279847325",

                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["Xbench", "Xbench_nobrackets"],
                  },
                  {
                    type: "show",
                    node: ["Xbench"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric without brackets",
                thumbnail: "/assets/afc/thumnail/xbench without brackets.png",
                enableFeatures: ["Acoustic fabric"],
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["Xbench", "Xbench_nobrackets"],
                  },
                  {
                    type: "show",
                    node: ["Xbench_nobrackets"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric with brackets",
              //   thumbnail: "/assets/afc/thumnail/xbench brackets.png",
              //   enableFeatures: ["Acoustic fabric"],
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: ["Xbench", "Xbench_nobrackets"],
              //     },
              //     {
              //       type: "show",
              //       node: ["Xbench"],
              //     },
              //   ],
              // },
              // {
              //   name: "Magnetic Fabric without brackets",
              //   thumbnail: "/assets/afc/thumnail/xbench without brackets.png",
              //   enableFeatures: ["Acoustic fabric"],
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   //pid: "6122f35afe408b1279847325",
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: ["Xbench", "Xbench_nobrackets"],
              //     },
              //     {
              //       type: "show",
              //       node: ["Xbench_nobrackets"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Xbench with rail Main",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Acoustic fabric",
                ],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["Xbenchrail", "Xbenchrail_nobrackets"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric with brackets",
                thumbnail: "/assets/afc/thumnail/xbenchrail brackets.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Acoustic fabric",
                ],
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["Xbenchrail", "Xbenchrail_nobrackets"],
                  },
                  {
                    type: "show",
                    node: ["Xbenchrail"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric without brackets",
                thumbnail:
                  "/assets/afc/thumnail/xbenchrail without brackets.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Acoustic fabric",
                ],
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["Xbenchrail", "Xbenchrail_nobrackets"],
                  },
                  {
                    type: "show",
                    node: ["Xbenchrail_nobrackets"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric with brackets",
              //   thumbnail: "/assets/afc/thumnail/xbenchrail brackets.png",
              //   disableFeatures: [
              //     "Livo Highlighter's Color",
              //     "Acoustic fabric",
              //   ],
              //   //pid: "6122f35afe408b1279847325",
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: ["Xbenchrail", "Xbenchrail_nobrackets"],
              //     },
              //     {
              //       type: "show",
              //       node: ["Xbenchrail"],
              //     },
              //   ],
              // },
              // {
              //   name: "Magnetic Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/xbenchrail without brackets.png",
              //   disableFeatures: [
              //     "Livo Highlighter's Color",
              //     "Acoustic fabric",
              //   ],
              //   //pid: "6122f35afe408b1279847325",
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: ["Xbenchrail", "Xbenchrail_nobrackets"],
              //     },
              //     {
              //       type: "show",
              //       node: ["Xbenchrail_nobrackets"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Full Panel Main",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Acoustic fabric",
                  // "30mm Frame Screen End Single"
                ],
                // enableFeatures: [
                //   "Livo Highlighter's Color",
                //   "Acoustic fabric",
                //   "30mm Frame Screen End"
                // ],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                //disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["fullpanels"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric",
                thumbnail: "/assets/afc/thumnail/fullpanel.png",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Acoustic fabric",

                  "30mm Return Frame Screen Single",
                  "Sleek Screen Aluminum Beading Return Single",
                  "Sleek Screen Nylon Beading Return Single",
                  "Glass Return Single",
                  "Livo Panel Return Single",
                  "30mm Frame Screen End Single",
                  "Sleek Screen Aluminum Beading End Single",
                  "Sleek Screen Nylon Beading End Single",
                  "Glass End Single",
                  "Livo Panel End Single",
                ],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["fullpanels"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric",
              //   thumbnail: "/assets/afc/thumnail/fullpanel.png",
              //   disableFeatures: [
              //     "Livo Highlighter's Color",
              //     "Acoustic fabric",
              //   ],
              //   //pid: "6122f35afe408b1279847325",
              //   disableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "show",
              //       node: ["fullpanels"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Curvivo Panel Main",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Acoustic fabric",
                ],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["panelwithplants"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric",
                thumbnail: "/assets/afc/pwp.png",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                enableFeatures: ["Acoustic fabric"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["panelwithplants"],
                  },
                ],
              },
            ],
          },
          {
            name: "Livo Panel Main",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: [
                  "Livo Highlighter's Color",
                  "Acoustic fabric",
                ],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                  "Livo Highlighter's Color",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["livoscreen"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric",
                thumbnail: "/assets/afc/thumnail/Livo screen.png",
                disableFeatures: ["Acoustic fabric"],
                enableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["livoscreen"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric",
              //   thumbnail: "/assets/afc/thumnail/Livo screen.png",
              //   disableFeatures: ["Acoustic fabric"],
              //   enableFeatures: ["Livo Highlighter's Color"],
              //   //pid: "6122f35afe408b1279847325",
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "show",
              //       node: ["livoscreen"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Livo Highlighter's Color",
            options: [
              {
                name: "Blue",
                thumbnail: "/assets/textures/Highlighter/Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/Blue.jpg",
                  },
                ],
              },
              {
                name: "Green",
                thumbnail: "/assets/textures/Highlighter/green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/green.jpg",
                  },
                ],
              },
              {
                name: "Orange",
                thumbnail: "/assets/textures/Highlighter/Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/Orange.jpg",
                  },
                ],
              },
              {
                name: "Yellow",
                thumbnail: "/assets/textures/Highlighter/yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/yellow.jpg",
                  },
                ],
              },
            ],
          },
          {
            name: "Acoustic fabric",
            options: [
              {
                name: "Caramel",
                thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Caramel.jpg",
                  },
                ],
              },
              {
                name: "Clouds",
                thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Clouds.jpg",
                  },
                ],
              },
              {
                name: "Dago Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
                  },
                ],
              },
              {
                name: "Dago Orange",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
                  },
                ],
              },
              {
                name: "Flame",
                thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Flame.jpg",
                  },
                ],
              },
              {
                name: "Granada",
                thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Granada.jpg",
                  },
                ],
              },
              {
                name: "Greysto",
                thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Greysto.jpg",
                  },
                ],
              },
              {
                name: "Lemon",
                thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Lemon.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
                  },
                ],
              },
              {
                name: "Mink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Mink.jpg",
                  },
                ],
              },
              {
                name: "Olive",
                thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Olive.jpg",
                  },
                ],
              },
              {
                name: "P Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/P_Green.jpg",
                  },
                ],
              },
              {
                name: "Peppermint",
                thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
                  },
                ],
              },
              {
                name: "Pink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Pink.jpg",
                  },
                ],
              },
              {
                name: "Planet",
                thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Planet.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Sangria.jpg",
                  },
                ],
              },
              {
                name: "Stone",
                thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Stone.jpg",
                  },
                ],
              },
              {
                name: "Tangerine",
                thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
                  },
                ],
              },
              {
                name: "Waterfall",
                thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,

        options: [
          {
            name: "30mm Frame Screen",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",

                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                ],
              },
              {
                name: "Pin-up Fabric",
                thumbnail: "/assets/afc/aff.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabric"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric",
              //   thumbnail: "/assets/afc/aff.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabric",
              //         "alumhalfandhalf",
              //         "halffabrichalfglass",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabric"],
              //     },
              //   ],
              // },
              {
                name: "Half Pin-up Fabric & White Marker",
                thumbnail: "/assets/afc/thumnail/half & half.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumhalfandhalf"],
                  },
                ],
              },
              // {
              //   name: "Half Magnetic Fabric & White Marker",
              //   thumbnail: "/assets/afc/thumnail/half & half.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabric",
              //         "alumhalfandhalf",
              //         "halffabrichalfglass",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumhalfandhalf"],
              //     },
              //   ],
              // },
              {
                name: "Pin-up Fabric + Glass Marker",
                thumbnail: "/assets/afc/aff.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                  {
                    type: show,
                    node: ["halffabrichalfglass"],
                  },
                ],
              },
              // {
              //   name: "Magnetic +Glass Marker",
              //   thumbnail: "/assets/afc/aff.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabric",
              //         "alumhalfandhalf",
              //         "halffabrichalfglass",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["halffabrichalfglass"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Sleek Screen Aluminum Beading Main",
            nodeName: ["aluminium_1", "alumfabric_1"],
            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],

                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder",
                      "alumfullfabricwithborder_nobrackets",
                    ],
                  },
                ],
              },
              {
                name: "Pin-up Fabric with brackets",
                thumbnail: "/assets/afc/thumnail/sleek screen Alumpinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder",
                      "alumfullfabricwithborder_nobrackets",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabricwithborder"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric without brackets",
                thumbnail:
                  "/assets/afc/thumnail/sleek screen Alumpinup without brackets.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder",
                      "alumfullfabricwithborder_nobrackets",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabricwithborder_nobrackets"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric with brackets",
              //   thumbnail: "/assets/afc/thumnail/sleek screen Alumpinup.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabricwithborder",
              //         "alumfullfabricwithborder_nobrackets",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabricwithborder"],
              //     },
              //   ],
              // },
              // {
              //   name: "Magnetic Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Alumpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabricwithborder",
              //         "alumfullfabricwithborder_nobrackets",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabricwithborder_nobrackets"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Screen with Nylon Beading",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["fullfabricrounded", "fullfabricrounded_nobrackets"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric with brackets",
                thumbnail: "/assets/afc/thumnail/sleek screen nylon pinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["fullfabricrounded", "fullfabricrounded_nobrackets"],
                  },
                  {
                    type: show,
                    node: ["fullfabricrounded"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric without brackets",
                thumbnail:
                  "/assets/afc/thumnail/sleek screen Nylonpinup without brackets.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["fullfabricrounded", "fullfabricrounded_nobrackets"],
                  },
                  {
                    type: show,
                    node: ["fullfabricrounded_nobrackets"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric with brackets",
              //   thumbnail: "/assets/afc/thumnail/sleek screen nylon pinup.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: ["fullfabricrounded", "fullfabricrounded_nobrackets"],
              //     },
              //     {
              //       type: show,
              //       node: ["fullfabricrounded"],
              //     },
              //   ],
              // },
              // {
              //   name: "Magnetic Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Nylonpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: ["fullfabricrounded", "fullfabricrounded_nobrackets"],
              //     },
              //     {
              //       type: show,
              //       node: ["fullfabricrounded_nobrackets"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Glass Main",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",

                // disableFeatures: ["Color Options Privacy"],
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["roundedglass"],
                  },
                ],
              },
              {
                name: "Sandwich glass",
                thumbnail: "/assets/afc/thumnail/Glass panel.png",
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["roundedglass"],
                  },
                ],
              },
              // {
              //   name: "Sandwich magnetic glass",
              //   thumbnail: "/assets/afc/thumnail/Glass panel.png",
              //   //pid: "6122f35afe408b1279847325",
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "show",
              //       node: ["roundedglass"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Xbench Main",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["Xbench", "Xbench_nobrackets"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric with brackets",
                thumbnail: "/assets/afc/thumnail/xbench brackets.png",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                //pid: "6122f35afe408b1279847325",

                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["Xbench", "Xbench_nobrackets"],
                  },
                  {
                    type: "show",
                    node: ["Xbench"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric without brackets",
                thumbnail: "/assets/afc/thumnail/xbench without brackets.png",
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["Xbench", "Xbench_nobrackets"],
                  },
                  {
                    type: "show",
                    node: ["Xbench_nobrackets"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric with brackets",
              //   thumbnail: "/assets/afc/thumnail/xbench brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: ["Xbench", "Xbench_nobrackets"],
              //     },
              //     {
              //       type: "show",
              //       node: ["Xbench"],
              //     },
              //   ],
              // },
              // {
              //   name: "Magnetic Fabric without brackets",
              //   thumbnail: "/assets/afc/thumnail/xbench without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: ["Xbench", "Xbench_nobrackets"],
              //     },
              //     {
              //       type: "show",
              //       node: ["Xbench_nobrackets"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Xbench with rail Main",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["Xbenchrail", "Xbenchrail_nobrackets"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric with brackets",
                thumbnail: "/assets/afc/thumnail/xbenchrail brackets.png",
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["Xbenchrail", "Xbenchrail_nobrackets"],
                  },
                  {
                    type: "show",
                    node: ["Xbenchrail"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric without brackets",
                thumbnail:
                  "/assets/afc/thumnail/xbenchrail without brackets.png",
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["Xbenchrail", "Xbenchrail_nobrackets"],
                  },
                  {
                    type: "show",
                    node: ["Xbenchrail_nobrackets"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric with brackets",
              //   thumbnail: "/assets/afc/thumnail/xbenchrail brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: ["Xbenchrail", "Xbenchrail_nobrackets"],
              //     },
              //     {
              //       type: "show",
              //       node: ["Xbenchrail"],
              //     },
              //   ],
              // },
              // {
              //   name: "Magnetic Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/xbenchrail without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: ["Xbenchrail", "Xbenchrail_nobrackets"],
              //     },
              //     {
              //       type: "show",
              //       node: ["Xbenchrail_nobrackets"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Full Panel Main",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["fullpanels"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric",
                thumbnail: "/assets/afc/thumnail/fullpanel.png",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["fullpanels"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric",
              //   thumbnail: "/assets/afc/thumnail/fullpanel.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "show",
              //       node: ["fullpanels"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Curvivo Panel Main",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["panelwithplants"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric",
                thumbnail: "/assets/afc/pwp.png",
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["panelwithplants"],
                  },
                ],
              },
            ],
          },
          {
            name: "Livo Panel Main",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                  "Livo Highlighter's Color",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["livoscreen"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric",
                thumbnail: "/assets/afc/thumnail/Livo screen.png",
                enableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["livoscreen"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric",
              //   thumbnail: "/assets/afc/thumnail/Livo screen.png",
              //   enableFeatures: ["Livo Highlighter's Color"],
              //   //pid: "6122f35afe408b1279847325",
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "show",
              //       node: ["livoscreen"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Livo Highlighter's Color",
            options: [
              {
                name: "Blue",
                thumbnail: "/assets/textures/Highlighter/Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/Blue.jpg",
                  },
                ],
              },
              {
                name: "Green",
                thumbnail: "/assets/textures/Highlighter/green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/green.jpg",
                  },
                ],
              },
              {
                name: "Orange",
                thumbnail: "/assets/textures/Highlighter/Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/Orange.jpg",
                  },
                ],
              },
              {
                name: "Yellow",
                thumbnail: "/assets/textures/Highlighter/yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/yellow.jpg",
                  },
                ],
              },
            ],
          },
          {
            name: "Acoustic fabric",
            options: [
              {
                name: "Caramel",
                thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Caramel.jpg",
                  },
                ],
              },
              {
                name: "Clouds",
                thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Clouds.jpg",
                  },
                ],
              },
              {
                name: "Dago Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
                  },
                ],
              },
              {
                name: "Dago Orange",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
                  },
                ],
              },
              {
                name: "Flame",
                thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Flame.jpg",
                  },
                ],
              },
              {
                name: "Granada",
                thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Granada.jpg",
                  },
                ],
              },
              {
                name: "Greysto",
                thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Greysto.jpg",
                  },
                ],
              },
              {
                name: "Lemon",
                thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Lemon.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
                  },
                ],
              },
              {
                name: "Mink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Mink.jpg",
                  },
                ],
              },
              {
                name: "Olive",
                thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Olive.jpg",
                  },
                ],
              },
              {
                name: "P Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/P_Green.jpg",
                  },
                ],
              },
              {
                name: "Peppermint",
                thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
                  },
                ],
              },
              {
                name: "Pink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Pink.jpg",
                  },
                ],
              },
              {
                name: "Planet",
                thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Planet.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Sangria.jpg",
                  },
                ],
              },
              {
                name: "Stone",
                thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Stone.jpg",
                  },
                ],
              },
              {
                name: "Tangerine",
                thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
                  },
                ],
              },
              {
                name: "Waterfall",
                thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Return Privacy Screen",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "SHARING",
        required: false,

        options: [
          {
            name: "30mm Return Frame Screen",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric_1",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                ],
              },
              {
                name: "Pin-up Fabric",
                thumbnail: "/assets/afc/aff.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["alumhalfandhalf", "halffabrichalfglass"],
                  },
                  {
                    type: show,
                    node: ["alumfullfabric_1"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric",
              //   thumbnail: "/assets/afc/aff.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabric_1",
              //         "alumhalfandhalf",
              //         "halffabrichalfglass",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabric_1"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "30mm Return Frame Screen Single",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["alumhalfandhalf", "alumfullfabric_1_single"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric",
                thumbnail: "/assets/afc/aff.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric_1",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabric_1_single"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric",
              //   thumbnail: "/assets/afc/aff.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabric_1",
              //         "alumhalfandhalf",
              //         "halffabrichalfglass",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabric_1"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Sleek Screen Aluminum Beading Return",
            nodeName: ["aluminium_1", "alumfabric_1"],
            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],

                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                    ],
                  },
                ],
              },
              {
                name: "Pin-Up Fabric with brackets",
                thumbnail: "/assets/afc/thumnail/sleek screen Alumpinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabricwithborder_1"],
                  },
                ],
              },
              {
                name: "Pin-Up Fabric without brackets",
                thumbnail:
                  "/assets/afc/thumnail/sleek screen Alumpinup without brackets.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabricwithborder_1_nobrackets"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric with brackets",
              //   thumbnail: "/assets/afc/thumnail/sleek screen Alumpinup.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabricwithborder_1",
              //         "alumfullfabricwithborder_1_nobrackets",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabricwithborder_1"],
              //     },
              //   ],
              // },
              // {
              //   name: "Magnetic Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Alumpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabricwithborder_1",
              //         "alumfullfabricwithborder_1_nobrackets",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabricwithborder_1_nobrackets"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Sleek Screen Aluminum Beading Return Single",
            nodeName: ["aluminium_1", "alumfabric_1"],
            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],

                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder_1_single",
                      "alumfullfabricwithborder_1_nobrackets_single",
                    ],
                  },
                ],
              },
              {
                name: "Pin-Up Fabric with brackets",
                thumbnail: "/assets/afc/thumnail/sleek screen Alumpinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabricwithborder_1_single"],
                  },
                ],
              },
              {
                name: "Pin-Up Fabric without brackets",
                thumbnail:
                  "/assets/afc/thumnail/sleek screen Alumpinup without brackets.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabricwithborder_1_nobrackets_single"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric with brackets",
              //   thumbnail: "/assets/afc/thumnail/sleek screen Alumpinup.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabricwithborder_1",
              //         "alumfullfabricwithborder_1_nobrackets",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabricwithborder_1"],
              //     },
              //   ],
              // },
              // {
              //   name: "Magnetic Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Alumpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabricwithborder_1",
              //         "alumfullfabricwithborder_1_nobrackets",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabricwithborder_1_nobrackets"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Sleek Screen Nylon Beading Return",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                disableFeatures: ["Livo Highlighter's Color"],
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                    ],
                  },
                ],
              },
              {
                name: "Pin-up Fabric with brackets",
                thumbnail: "/assets/afc/thumnail/sleek screen nylon pinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                    ],
                  },
                  {
                    type: show,
                    node: ["fullfabricrounded_1"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric without brackets",
                thumbnail:
                  "/assets/afc/thumnail/sleek screen Nylonpinup without brackets.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                    ],
                  },
                  {
                    type: show,
                    node: ["fullfabricrounded_1_nobrackets"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric with brackets",
              //   thumbnail: "/assets/afc/thumnail/sleek screen nylon pinup.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "fullfabricrounded_1",
              //         "fullfabricrounded_1_nobrackets",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["fullfabricrounded_1"],
              //     },
              //   ],
              // },
              // {
              //   name: "Magnetic Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Nylonpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "fullfabricrounded_1",
              //         "fullfabricrounded_1_nobrackets",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["fullfabricrounded_1_nobrackets"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Sleek Screen Nylon Beading Return Single",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                disableFeatures: ["Livo Highlighter's Color"],
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [" ", "fullfabricrounded_1_nobrackets"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric with brackets",
                thumbnail: "/assets/afc/thumnail/sleek screen nylon pinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                    ],
                  },
                  {
                    type: show,
                    node: ["fullfabricrounded_1_nobrackets"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric without brackets",
                thumbnail:
                  "/assets/afc/thumnail/sleek screen Nylonpinup without brackets.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                    ],
                  },
                  {
                    type: show,
                    node: ["fullfabricrounded_1_nobrackets_single"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric with brackets",
              //   thumbnail: "/assets/afc/thumnail/sleek screen nylon pinup.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "fullfabricrounded_1",
              //         "fullfabricrounded_1_nobrackets",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["fullfabricrounded_1"],
              //     },
              //   ],
              // },
              // {
              //   name: "Magnetic Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Nylonpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "fullfabricrounded_1",
              //         "fullfabricrounded_1_nobrackets",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["fullfabricrounded_1_nobrackets"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Glass Return",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                // disableFeatures: ["Color Options Privacy"],
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["roundedglass_1"],
                  },
                ],
              },
              {
                name: "Sandwich glass",
                thumbnail: "/assets/afc/thumnail/Glass panel.png",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["roundedglass_1"],
                  },
                ],
              },
              // {
              //   name: "Sandwich magnetic glass",
              //   thumbnail: "/assets/afc/thumnail/Glass panel.png",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   //pid: "6122f35afe408b1279847325",
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "show",
              //       node: ["roundedglass_1"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Glass Return Single",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                // disableFeatures: ["Color Options Privacy"],
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["roundedglass_1_single"],
                  },
                ],
              },
              {
                name: "Sandwich glass",
                thumbnail: "/assets/afc/thumnail/Glass panel.png",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["roundedglass_1_single"],
                  },
                ],
              },
              // {
              //   name: "Sandwich magnetic glass",
              //   thumbnail: "/assets/afc/thumnail/Glass panel.png",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   //pid: "6122f35afe408b1279847325",
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "show",
              //       node: ["roundedglass_1"],
              //     },
              //   ],
              // },
            ],
          },

          {
            name: "Full Panel Return",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["fullpanels_1"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric",
                thumbnail: "/assets/afc/thumnail/fullpanel.png",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["fullpanels_1"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric",
              //   thumbnail: "/assets/afc/thumnail/fullpanel.png",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   //pid: "6122f35afe408b1279847325",
              //   disableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "show",
              //       node: ["fullpanels_1"],
              //     },
              //   ],
              // },
            ],
          },

          {
            name: "Livo Panel Return",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                  "Livo Highlighter's Color",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["livoscreen_1"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric",
                thumbnail: "/assets/afc/thumnail/Livo screen.png",
                enableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["livoscreen_1"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric",
              //   thumbnail: "/assets/afc/thumnail/Livo screen.png",
              //   enableFeatures: ["Livo Highlighter's Color"],
              //   //pid: "6122f35afe408b1279847325",
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "show",
              //       node: ["livoscreen_1"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Livo Panel Return Single",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                  "Livo Highlighter's Color",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["livoscreen_1_single"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric",
                thumbnail: "/assets/afc/thumnail/Livo screen.png",
                enableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["livoscreen_1_single"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric",
              //   thumbnail: "/assets/afc/thumnail/Livo screen.png",
              //   enableFeatures: ["Livo Highlighter's Color"],
              //   //pid: "6122f35afe408b1279847325",
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "show",
              //       node: ["livoscreen_1"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Livo Highlighter's Color",
            options: [
              {
                name: "Blue",
                thumbnail: "/assets/textures/Highlighter/Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/Blue.jpg",
                  },
                ],
              },
              {
                name: "Green",
                thumbnail: "/assets/textures/Highlighter/green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/green.jpg",
                  },
                ],
              },
              {
                name: "Orange",
                thumbnail: "/assets/textures/Highlighter/Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/Orange.jpg",
                  },
                ],
              },
              {
                name: "Yellow",
                thumbnail: "/assets/textures/Highlighter/yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/yellow.jpg",
                  },
                ],
              },
            ],
          },
          {
            name: "Acoustic fabric",
            options: [
              {
                name: "Caramel",
                thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Caramel.jpg",
                  },
                ],
              },
              {
                name: "Clouds",
                thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Clouds.jpg",
                  },
                ],
              },
              {
                name: "Dago Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
                  },
                ],
              },
              {
                name: "Dago Orange",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
                  },
                ],
              },
              {
                name: "Flame",
                thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Flame.jpg",
                  },
                ],
              },
              {
                name: "Granada",
                thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Granada.jpg",
                  },
                ],
              },
              {
                name: "Greysto",
                thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Greysto.jpg",
                  },
                ],
              },
              {
                name: "Lemon",
                thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Lemon.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
                  },
                ],
              },
              {
                name: "Mink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Mink.jpg",
                  },
                ],
              },
              {
                name: "Olive",
                thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Olive.jpg",
                  },
                ],
              },
              {
                name: "P Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/P_Green.jpg",
                  },
                ],
              },
              {
                name: "Peppermint",
                thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
                  },
                ],
              },
              {
                name: "Pink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Pink.jpg",
                  },
                ],
              },
              {
                name: "Planet",
                thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Planet.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Sangria.jpg",
                  },
                ],
              },
              {
                name: "Stone",
                thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Stone.jpg",
                  },
                ],
              },
              {
                name: "Tangerine",
                thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
                  },
                ],
              },
              {
                name: "Waterfall",
                thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,

        options: [
          {
            name: "30mm Return Frame Screen",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric_1",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                ],
              },
              {
                name: "Pin-Up Fabric",
                thumbnail: "/assets/afc/aff.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric_1",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabric_1"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric",
              //   thumbnail: "/assets/afc/aff.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabric_1",
              //         "alumhalfandhalf",
              //         "halffabrichalfglass",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabric_1"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Sleek Screen Aluminum Beading Return",
            nodeName: ["aluminium_1", "alumfabric_1"],
            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],

                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                    ],
                  },
                ],
              },
              {
                name: "Fabric with brackets",
                thumbnail: "/assets/afc/thumnail/sleek screen Alumpinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabricwithborder_1"],
                  },
                ],
              },
              {
                name: "Fabric without brackets",
                thumbnail:
                  "/assets/afc/thumnail/sleek screen Alumpinup without brackets.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder_1",
                      "alumfullfabricwithborder_1_nobrackets",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabricwithborder_1_nobrackets"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric with brackets",
              //   thumbnail: "/assets/afc/thumnail/sleek screen Alumpinup.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabricwithborder_1",
              //         "alumfullfabricwithborder_1_nobrackets",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabricwithborder_1"],
              //     },
              //   ],
              // },
              // {
              //   name: "Magnetic Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Alumpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabricwithborder_1",
              //         "alumfullfabricwithborder_1_nobrackets",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabricwithborder_1_nobrackets"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Sleek Screen Nylon Beading Return",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                disableFeatures: ["Livo Highlighter's Color"],
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                    ],
                  },
                ],
              },
              {
                name: "Fabric with brackets",
                thumbnail: "/assets/afc/thumnail/sleek screen nylon pinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                    ],
                  },
                  {
                    type: show,
                    node: ["fullfabricrounded_1"],
                  },
                ],
              },
              {
                name: "Fabric without brackets",
                thumbnail:
                  "/assets/afc/thumnail/sleek screen Nylonpinup without brackets.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_1",
                      "fullfabricrounded_1_nobrackets",
                    ],
                  },
                  {
                    type: show,
                    node: ["fullfabricrounded_1_nobrackets"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric with brackets",
              //   thumbnail: "/assets/afc/thumnail/sleek screen nylon pinup.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "fullfabricrounded_1",
              //         "fullfabricrounded_1_nobrackets",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["fullfabricrounded_1"],
              //     },
              //   ],
              // },
              // {
              //   name: "Magnetic Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Nylonpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "fullfabricrounded_1",
              //         "fullfabricrounded_1_nobrackets",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["fullfabricrounded_1_nobrackets"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Glass Return",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                // disableFeatures: ["Color Options Privacy"],
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["roundedglass_1"],
                  },
                ],
              },
              {
                name: "Sandwich glass",
                thumbnail: "/assets/afc/thumnail/Glass panel.png",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["roundedglass_1"],
                  },
                ],
              },
              // {
              //   name: "Sandwich magnetic glass",
              //   thumbnail: "/assets/afc/thumnail/Glass panel.png",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   //pid: "6122f35afe408b1279847325",
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "show",
              //       node: ["roundedglass_1"],
              //     },
              //   ],
              // },
            ],
          },

          {
            name: "Full Panel Return",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["fullpanels_1"],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "/assets/afc/thumnail/fullpanel.png",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["fullpanels_1"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric",
              //   thumbnail: "/assets/afc/thumnail/fullpanel.png",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   //pid: "6122f35afe408b1279847325",
              //   disableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "show",
              //       node: ["fullpanels_1"],
              //     },
              //   ],
              // },
            ],
          },

          {
            name: "Livo Panel Return",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                  "Livo Highlighter's Color",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["livoscreen_1"],
                  },
                ],
              },
              {
                name: "Fabric",
                thumbnail: "/assets/afc/thumnail/Livo screen.png",
                enableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["livoscreen_1"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric",
              //   thumbnail: "/assets/afc/thumnail/Livo screen.png",
              //   enableFeatures: ["Livo Highlighter's Color"],
              //   //pid: "6122f35afe408b1279847325",
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "show",
              //       node: ["livoscreen_1"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Livo Highlighter's Color",
            options: [
              {
                name: "Blue",
                thumbnail: "/assets/textures/Highlighter/Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/Blue.jpg",
                  },
                ],
              },
              {
                name: "Green",
                thumbnail: "/assets/textures/Highlighter/green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/green.jpg",
                  },
                ],
              },
              {
                name: "Orange",
                thumbnail: "/assets/textures/Highlighter/Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/Orange.jpg",
                  },
                ],
              },
              {
                name: "Yellow",
                thumbnail: "/assets/textures/Highlighter/yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/yellow.jpg",
                  },
                ],
              },
            ],
          },
          {
            name: "Acoustic fabric",
            options: [
              {
                name: "Caramel",
                thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Caramel.jpg",
                  },
                ],
              },
              {
                name: "Clouds",
                thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Clouds.jpg",
                  },
                ],
              },
              {
                name: "Dago Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
                  },
                ],
              },
              {
                name: "Dago Orange",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
                  },
                ],
              },
              {
                name: "Flame",
                thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Flame.jpg",
                  },
                ],
              },
              {
                name: "Granada",
                thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Granada.jpg",
                  },
                ],
              },
              {
                name: "Greysto",
                thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Greysto.jpg",
                  },
                ],
              },
              {
                name: "Lemon",
                thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Lemon.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
                  },
                ],
              },
              {
                name: "Mink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Mink.jpg",
                  },
                ],
              },
              {
                name: "Olive",
                thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Olive.jpg",
                  },
                ],
              },
              {
                name: "P Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/P_Green.jpg",
                  },
                ],
              },
              {
                name: "Peppermint",
                thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
                  },
                ],
              },
              {
                name: "Pink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Pink.jpg",
                  },
                ],
              },
              {
                name: "Planet",
                thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Planet.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Sangria.jpg",
                  },
                ],
              },
              {
                name: "Stone",
                thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Stone.jpg",
                  },
                ],
              },
              {
                name: "Tangerine",
                thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
                  },
                ],
              },
              {
                name: "Waterfall",
                thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "End Privacy Screen",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "SHARING",
        required: false,

        options: [
          {
            name: "30mm Frame Screen End",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric_end",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                ],
              },
              {
                name: "Pin-up Fabric",
                thumbnail: "/assets/afc/aff.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric_end",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabric_end"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric",
              //   thumbnail: "/assets/afc/aff.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabric_end",
              //         "alumhalfandhalf",
              //         "halffabrichalfglass",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabric_end"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "30mm Frame Screen End Single",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                      "alumfullfabric_end_single",
                    ],
                  },
                ],
              },
              {
                name: "Pin-up Fabric",
                thumbnail: "/assets/afc/aff.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric_end",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabric_end_single"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric",
              //   thumbnail: "/assets/afc/aff.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabric_end",
              //         "alumhalfandhalf",
              //         "halffabrichalfglass",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabric_end"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Sleek Screen Aluminum Beading End",
            nodeName: ["aluminium_1", "alumfabric_1"],
            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],

                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder__end",
                      "alumfullfabricwithborder_nobrackets_end",
                    ],
                  },
                ],
              },
              {
                name: "Pin-up Fabric with brackets",
                thumbnail: "/assets/afc/thumnail/sleek screen Alumpinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder__end",
                      "alumfullfabricwithborder_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabricwithborder__end"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric without brackets",
                thumbnail:
                  "/assets/afc/thumnail/sleek screen Alumpinup without brackets.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder__end",
                      "alumfullfabricwithborder_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabricwithborder_nobrackets_end"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric with brackets",
              //   thumbnail: "/assets/afc/thumnail/sleek screen Alumpinup.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabricwithborder__end",
              //         "alumfullfabricwithborder_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabricwithborder__end"],
              //     },
              //   ],
              // },
              // {
              //   name: "Magnetic Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Alumpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabricwithborder__end",
              //         "alumfullfabricwithborder_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabricwithborder_nobrackets_end"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Sleek Screen Aluminum Beading End Single",
            nodeName: ["aluminium_1", "alumfabric_1"],
            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],

                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder_end",
                      "alumfullfabricwithborder_nobrackets_end",
                      "alumfullfabricwithborder_end_single",
                      "alumfullfabricwithborder_nobrackets_end_single",
                    ],
                  },
                ],
              },
              {
                name: "Pin-up Fabric with brackets",
                thumbnail: "/assets/afc/thumnail/sleek screen Alumpinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder__end",
                      "alumfullfabricwithborder_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabricwithborder_end_single"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric without brackets",
                thumbnail:
                  "/assets/afc/thumnail/sleek screen Alumpinup without brackets.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder__end",
                      "alumfullfabricwithborder_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabricwithborder_nobrackets_end_single"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric with brackets",
              //   thumbnail: "/assets/afc/thumnail/sleek screen Alumpinup.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabricwithborder__end",
              //         "alumfullfabricwithborder_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabricwithborder__end"],
              //     },
              //   ],
              // },
              // {
              //   name: "Magnetic Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Alumpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabricwithborder__end",
              //         "alumfullfabricwithborder_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabricwithborder_nobrackets_end"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Sleek Screen Nylon Beading End",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_end",
                      "fullfabricrounded_nobrackets_end",
                    ],
                  },
                ],
              },
              {
                name: "Pin-up Fabric with brackets",
                thumbnail: "/assets/afc/thumnail/sleek screen nylon pinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_end",
                      "fullfabricrounded_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["fullfabricrounded_end"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric without brackets",
                thumbnail:
                  "/assets/afc/thumnail/sleek screen Nylonpinup without brackets.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_end",
                      "fullfabricrounded_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["fullfabricrounded_nobrackets_end"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric with brackets",
              //   thumbnail: "/assets/afc/thumnail/sleek screen nylon pinup.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "fullfabricrounded_end",
              //         "fullfabricrounded_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["fullfabricrounded_end"],
              //     },
              //   ],
              // },
              // {
              //   name: "Magnetic Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Nylonpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "fullfabricrounded_end",
              //         "fullfabricrounded_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["fullfabricrounded_nobrackets_end"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Sleek Screen Nylon Beading End Single",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_end",
                      "fullfabricrounded_nobrackets_end",
                      "fullfabricrounded_end_single",
                      "fullfabricrounded_nobrackets_end_single",
                    ],
                  },
                ],
              },
              {
                name: "Pin-up Fabric with brackets",
                thumbnail: "/assets/afc/thumnail/sleek screen nylon pinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_end",
                      "fullfabricrounded_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["fullfabricrounded_nobrackets_end"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric without brackets",
                thumbnail:
                  "/assets/afc/thumnail/sleek screen Nylonpinup without brackets.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_end",
                      "fullfabricrounded_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["fullfabricrounded_nobrackets_end_single"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric with brackets",
              //   thumbnail: "/assets/afc/thumnail/sleek screen nylon pinup.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "fullfabricrounded_end",
              //         "fullfabricrounded_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["fullfabricrounded_end"],
              //     },
              //   ],
              // },
              // {
              //   name: "Magnetic Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Nylonpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "fullfabricrounded_end",
              //         "fullfabricrounded_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["fullfabricrounded_nobrackets_end"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Glass End",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                // disableFeatures: ["Color Options Privacy"],
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["roundedglass_end"],
                  },
                ],
              },
              {
                name: "Sandwich glass",
                thumbnail: "/assets/afc/thumnail/Glass panel.png",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["roundedglass_end"],
                  },
                ],
              },
              // {
              //   name: "Sandwich magnetic glass",
              //   thumbnail: "/assets/afc/thumnail/Glass panel.png",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   //pid: "6122f35afe408b1279847325",
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "show",
              //       node: ["roundedglass_end"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Glass End Single",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                // disableFeatures: ["Color Options Privacy"],
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["roundedglass_end_single"],
                  },
                ],
              },
              {
                name: "Sandwich glass",
                thumbnail: "/assets/afc/thumnail/Glass panel.png",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["roundedglass_end_single"],
                  },
                ],
              },
              // {
              //   name: "Sandwich magnetic glass",
              //   thumbnail: "/assets/afc/thumnail/Glass panel.png",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   //pid: "6122f35afe408b1279847325",
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "show",
              //       node: ["roundedglass_end"],
              //     },
              //   ],
              // },
            ],
          },

          {
            name: "Hanging Panel End",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "hanging_framed",
                      "hanging_nylon",
                      "hanging_aluminium",
                    ],
                  },
                  {
                    type: show,
                    node: [""],
                  },
                ],
              },

              {
                name: "Framed screen",
                thumbnail: "/assets/afc/thumnail/hanging_framed.png",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "hanging_framed",
                      "hanging_nylon",
                      "hanging_aluminium",
                    ],
                  },
                  {
                    type: show,
                    node: ["hanging_framed"],
                  },
                ],
              },
              {
                name: "Nylon Beading",
                thumbnail: "/assets/afc/thumnail/nylon beading.png",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "hanging_framed",
                      "hanging_nylon",
                      "hanging_aluminium",
                    ],
                  },
                  {
                    type: show,
                    node: ["hanging_nylon"],
                  },
                ],
              },
              {
                name: "Aluminium Beading",
                thumbnail: "/assets/afc/thumnail/aluminium beading.png",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "hanging_framed",
                      "hanging_nylon",
                      "hanging_aluminium",
                    ],
                  },
                  {
                    type: show,
                    node: ["hanging_aluminium"],
                  },
                ],
              },
            ],
          },

          {
            name: "Livo Panel End",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                  "Livo Highlighter's Color",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["livoscreen_end"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric",
                thumbnail: "/assets/afc/thumnail/Livo screen.png",
                enableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["livoscreen_end"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric",
              //   thumbnail: "/assets/afc/thumnail/Livo screen.png",
              //   enableFeatures: ["Livo Highlighter's Color"],
              //   //pid: "6122f35afe408b1279847325",
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "show",
              //       node: ["livoscreen_end"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Livo Panel End Single",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                  "Livo Highlighter's Color",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["livoscreen_end_single"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric",
                thumbnail: "/assets/afc/thumnail/Livo screen.png",
                enableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["livoscreen_end_single"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric",
              //   thumbnail: "/assets/afc/thumnail/Livo screen.png",
              //   enableFeatures: ["Livo Highlighter's Color"],
              //   //pid: "6122f35afe408b1279847325",
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "show",
              //       node: ["livoscreen_end"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Livo Highlighter's Color",
            options: [
              {
                name: "Blue",
                thumbnail: "/assets/textures/Highlighter/Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/Blue.jpg",
                  },
                ],
              },
              {
                name: "Green",
                thumbnail: "/assets/textures/Highlighter/green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/green.jpg",
                  },
                ],
              },
              {
                name: "Orange",
                thumbnail: "/assets/textures/Highlighter/Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/Orange.jpg",
                  },
                ],
              },
              {
                name: "Yellow",
                thumbnail: "/assets/textures/Highlighter/yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/yellow.jpg",
                  },
                ],
              },
            ],
          },
          {
            name: "Acoustic fabric",
            options: [
              {
                name: "Caramel",
                thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Caramel.jpg",
                  },
                ],
              },
              {
                name: "Clouds",
                thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Clouds.jpg",
                  },
                ],
              },
              {
                name: "Dago Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
                  },
                ],
              },
              {
                name: "Dago Orange",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
                  },
                ],
              },
              {
                name: "Flame",
                thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Flame.jpg",
                  },
                ],
              },
              {
                name: "Granada",
                thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Granada.jpg",
                  },
                ],
              },
              {
                name: "Greysto",
                thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Greysto.jpg",
                  },
                ],
              },
              {
                name: "Lemon",
                thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Lemon.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
                  },
                ],
              },
              {
                name: "Mink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Mink.jpg",
                  },
                ],
              },
              {
                name: "Olive",
                thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Olive.jpg",
                  },
                ],
              },
              {
                name: "P Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/P_Green.jpg",
                  },
                ],
              },
              {
                name: "Peppermint",
                thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
                  },
                ],
              },
              {
                name: "Pink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Pink.jpg",
                  },
                ],
              },
              {
                name: "Planet",
                thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Planet.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Sangria.jpg",
                  },
                ],
              },
              {
                name: "Stone",
                thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Stone.jpg",
                  },
                ],
              },
              {
                name: "Tangerine",
                thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
                  },
                ],
              },
              {
                name: "Waterfall",
                thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,

        options: [
          {
            name: "30mm Frame Screen End",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric_end",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                ],
              },
              {
                name: "Pin-up Fabric",
                thumbnail: "/assets/afc/aff.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabric_end",
                      "alumhalfandhalf",
                      "halffabrichalfglass",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabric_end"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric",
              //   thumbnail: "/assets/afc/aff.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabric_end",
              //         "alumhalfandhalf",
              //         "halffabrichalfglass",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabric_end"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Sleek Screen Aluminum Beading End",
            nodeName: ["aluminium_1", "alumfabric_1"],
            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],

                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder__end",
                      "alumfullfabricwithborder_nobrackets_end",
                    ],
                  },
                ],
              },
              {
                name: "Pin-up Fabric with brackets",
                thumbnail: "/assets/afc/thumnail/sleek screen Alumpinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder__end",
                      "alumfullfabricwithborder_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabricwithborder__end"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric without brackets",
                thumbnail:
                  "/assets/afc/thumnail/sleek screen Alumpinup without brackets.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "alumfullfabricwithborder__end",
                      "alumfullfabricwithborder_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["alumfullfabricwithborder_nobrackets_end"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric with brackets",
              //   thumbnail: "/assets/afc/thumnail/sleek screen Alumpinup.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabricwithborder__end",
              //         "alumfullfabricwithborder_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabricwithborder__end"],
              //     },
              //   ],
              // },
              // {
              //   name: "Magnetic Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Alumpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "alumfullfabricwithborder__end",
              //         "alumfullfabricwithborder_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["alumfullfabricwithborder_nobrackets_end"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Sleek Screen Nylon Beading End",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_end",
                      "fullfabricrounded_nobrackets_end",
                    ],
                  },
                ],
              },
              {
                name: "Pin-up Fabric with brackets",
                thumbnail: "/assets/afc/thumnail/sleek screen nylon pinup.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_end",
                      "fullfabricrounded_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["fullfabricrounded_end"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric without brackets",
                thumbnail:
                  "/assets/afc/thumnail/sleek screen Nylonpinup without brackets.png",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "fullfabricrounded_end",
                      "fullfabricrounded_nobrackets_end",
                    ],
                  },
                  {
                    type: show,
                    node: ["fullfabricrounded_nobrackets_end"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric with brackets",
              //   thumbnail: "/assets/afc/thumnail/sleek screen nylon pinup.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "fullfabricrounded_end",
              //         "fullfabricrounded_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["fullfabricrounded_end"],
              //     },
              //   ],
              // },
              // {
              //   name: "Magnetic Fabric without brackets",
              //   thumbnail:
              //     "/assets/afc/thumnail/sleek screen Nylonpinup without brackets.png",
              //   //pid: "6122f35afe408b1279847325",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "hide",
              //       node: [
              //         "fullfabricrounded_end",
              //         "fullfabricrounded_nobrackets_end",
              //       ],
              //     },
              //     {
              //       type: show,
              //       node: ["fullfabricrounded_nobrackets_end"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Glass End",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                //pid: "6122f35afe408b1279847325",
                disableFeatures: ["Livo Highlighter's Color"],
                // disableFeatures: ["Color Options Privacy"],
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["roundedglass_end"],
                  },
                ],
              },
              {
                name: "Sandwich glass",
                thumbnail: "/assets/afc/thumnail/Glass panel.png",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["roundedglass_end"],
                  },
                ],
              },
              // {
              //   name: "Sandwich magnetic glass",
              //   thumbnail: "/assets/afc/thumnail/Glass panel.png",
              //   disableFeatures: ["Livo Highlighter's Color"],
              //   //pid: "6122f35afe408b1279847325",
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "show",
              //       node: ["roundedglass_end"],
              //     },
              //   ],
              // },
            ],
          },

          {
            name: "Hanging Panel End",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "hanging_framed",
                      "hanging_nylon",
                      "hanging_aluminium",
                    ],
                  },
                  {
                    type: show,
                    node: [""],
                  },
                ],
              },

              {
                name: "Framed screen",
                thumbnail: "/assets/afc/thumnail/hanging_framed.png",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "hanging_framed",
                      "hanging_nylon",
                      "hanging_aluminium",
                    ],
                  },
                  {
                    type: show,
                    node: ["hanging_framed"],
                  },
                ],
              },
              {
                name: "Nylon Beading",
                thumbnail: "/assets/afc/thumnail/nylon beading.png",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "hanging_framed",
                      "hanging_nylon",
                      "hanging_aluminium",
                    ],
                  },
                  {
                    type: show,
                    node: ["hanging_nylon"],
                  },
                ],
              },
              {
                name: "Aluminium Beading",
                thumbnail: "/assets/afc/thumnail/aluminium beading.png",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: [
                      "hanging_framed",
                      "hanging_nylon",
                      "hanging_aluminium",
                    ],
                  },
                  {
                    type: show,
                    node: ["hanging_aluminium"],
                  },
                ],
              },
            ],
          },

          {
            name: "Livo Panel End",
            nodeName: ["aluminium", "alumfabric"],

            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                disableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                disableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                  "Livo Highlighter's Color",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "hide",
                    node: ["livoscreen_end"],
                  },
                ],
              },
              {
                name: "Pin-up Fabric",
                thumbnail: "/assets/afc/thumnail/Livo screen.png",
                enableFeatures: ["Livo Highlighter's Color"],
                //pid: "6122f35afe408b1279847325",
                enableOptions: [
                  "HANGING METAL MARKER 450WX300H",
                  "HANGING METAL MARKER",
                  "PANEL MOUNTED NAME PLATE",
                  "HANGING METAL PAPER TRAY 222L",
                  "HANGING METAL PEN HOLDER 70L",
                  "HANGING METAL BAG HOOK",
                  "HANGING METAL MARKER 300WX300H",
                  "METAL SINGLE PAPER HOLDER",
                ],
                // disableFeatures: ["Color Options Privacy"],
                // enableOptions: ['ColorOptionsPrivacy'],
                rules: [
                  {
                    type: "show",
                    node: ["livoscreen_end"],
                  },
                ],
              },
              // {
              //   name: "Magnetic Fabric",
              //   thumbnail: "/assets/afc/thumnail/Livo screen.png",
              //   enableFeatures: ["Livo Highlighter's Color"],
              //   //pid: "6122f35afe408b1279847325",
              //   enableOptions: [
              //     "HANGING METAL MARKER 450WX300H",
              //     "HANGING METAL MARKER",
              //     "PANEL MOUNTED NAME PLATE",
              //     "HANGING METAL PAPER TRAY 222L",
              //     "HANGING METAL PEN HOLDER 70L",
              //     "HANGING METAL BAG HOOK",
              //     "HANGING METAL MARKER 300WX300H",
              //     "METAL SINGLE PAPER HOLDER",
              //   ],
              //   // disableFeatures: ["Color Options Privacy"],
              //   // enableOptions: ['ColorOptionsPrivacy'],
              //   rules: [
              //     {
              //       type: "show",
              //       node: ["livoscreen_end"],
              //     },
              //   ],
              // },
            ],
          },
          {
            name: "Livo Highlighter's Color",
            options: [
              {
                name: "Blue",
                thumbnail: "/assets/textures/Highlighter/Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/Blue.jpg",
                  },
                ],
              },
              {
                name: "Green",
                thumbnail: "/assets/textures/Highlighter/green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/green.jpg",
                  },
                ],
              },
              {
                name: "Orange",
                thumbnail: "/assets/textures/Highlighter/Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/Orange.jpg",
                  },
                ],
              },
              {
                name: "Yellow",
                thumbnail: "/assets/textures/Highlighter/yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "highlighter",
                    textureUrl: "/assets/textures/Highlighter/yellow.jpg",
                  },
                ],
              },
            ],
          },
          {
            name: "Acoustic fabric",
            options: [
              {
                name: "Caramel",
                thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Caramel.jpg",
                  },
                ],
              },
              {
                name: "Clouds",
                thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Clouds.jpg",
                  },
                ],
              },
              {
                name: "Dago Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
                  },
                ],
              },
              {
                name: "Dago Orange",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
                  },
                ],
              },
              {
                name: "Flame",
                thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Flame.jpg",
                  },
                ],
              },
              {
                name: "Granada",
                thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Granada.jpg",
                  },
                ],
              },
              {
                name: "Greysto",
                thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Greysto.jpg",
                  },
                ],
              },
              {
                name: "Lemon",
                thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Lemon.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
                  },
                ],
              },
              {
                name: "Mink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Mink.jpg",
                  },
                ],
              },
              {
                name: "Olive",
                thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Olive.jpg",
                  },
                ],
              },
              {
                name: "P Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/P_Green.jpg",
                  },
                ],
              },
              {
                name: "Peppermint",
                thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
                  },
                ],
              },
              {
                name: "Pink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Pink.jpg",
                  },
                ],
              },
              {
                name: "Planet",
                thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Planet.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Sangria.jpg",
                  },
                ],
              },
              {
                name: "Stone",
                thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Stone.jpg",
                  },
                ],
              },
              {
                name: "Tangerine",
                thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
                  },
                ],
              },
              {
                name: "Waterfall",
                thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "acoustic",
                    textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Main Privacy Color Option",
    options: [
      {
        name: "SHARING",
        multiSelect: false,
        options: [
          {
            name: "Main Glass Color",
            multiSelect: false,
            options: [
              {
                name: "Apple Green",
                thumbnail: "/assets/textures/Glass/Apple Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Apple Green.jpg",
                  },
                ],
              },
              {
                name: "Aqua Maring",
                thumbnail: "/assets/textures/Glass/Aqua Marine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Aqua Marine.jpg",
                  },
                ],
              },
              {
                name: "Azure Blue",
                thumbnail: "/assets/textures/Glass/Azure Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Azure Blue.jpg",
                  },
                ],
              },
              {
                name: "Beige",
                thumbnail: "/assets/textures/Glass/Beige.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Beige.jpg",
                  },
                ],
              },
              {
                name: "Black",
                thumbnail: "/assets/textures/Glass/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Black.jpg",
                  },
                ],
              },
              {
                name: "Canary Yellow",
                thumbnail: "/assets/textures/Glass/Canary Yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Canary Yellow.jpg",
                  },
                ],
              },
              {
                name: "Casis Purple",
                thumbnail: "/assets/textures/Glass/Casis Purple.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Casis Purple.jpg",
                  },
                ],
              },
              {
                name: "Classic Ivory",
                thumbnail: "/assets/textures/Glass/ClassicIvory.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/ClassicIvory.jpg",
                  },
                ],
              },
              {
                name: "Crystal White",
                thumbnail: "/assets/textures/Glass/Crystal White.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Crystal White.jpg",
                  },
                ],
              },
              {
                name: "Dark Blue",
                thumbnail: "/assets/textures/Glass/Dark Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Dark Blue.jpg",
                  },
                ],
              },
              {
                name: "Dark Bronze",
                thumbnail: "/assets/textures/Glass/Dark Bronze.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Dark Bronze.jpg",
                  },
                ],
              },
              {
                name: "Dark Lavender",
                thumbnail: "/assets/textures/Glass/dark lavender.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/dark lavender.jpg",
                  },
                ],
              },
              {
                name: "Dark Red",
                thumbnail: "/assets/textures/Glass/Dark Red.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Dark Red.jpg",
                  },
                ],
              },
              {
                name: "Ergo Grey",
                thumbnail: "/assets/textures/Glass/Ergo Grey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Ergo Grey.jpg",
                  },
                ],
              },
              {
                name: "Euro Bronze Frosted",
                thumbnail: "/assets/textures/Glass/Euro Bronze Frosted.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl:
                      "/assets/textures/Glass/Euro Bronze Frosted.jpg",
                  },
                ],
              },
              {
                name: "Euro Bronze",
                thumbnail: "/assets/textures/Glass/Euro Bronze.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Euro Bronze.jpg",
                  },
                ],
              },
              {
                name: "Euro Grey",
                thumbnail: "/assets/textures/Glass/Euro Grey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Euro Grey.jpg",
                  },
                ],
              },
              {
                name: "Flame Red",
                thumbnail: "/assets/textures/Glass/Flame-red.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Flame-red.jpg",
                  },
                ],
              },
              {
                name: "Frosted White",
                thumbnail: "/assets/textures/Glass/FrostedWhite.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/FrostedWhite.jpg",
                  },
                ],
              },
              {
                name: "Green Lime",
                thumbnail: "/assets/textures/Glass/Green Lime.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Green Lime.jpg",
                  },
                ],
              },
              {
                name: "Mediterranean Blue",
                thumbnail: "/assets/textures/Glass/Mediterranean Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Mediterranean Blue.jpg",
                  },
                ],
              },
              {
                name: "Ocean Green",
                thumbnail: "/assets/textures/Glass/Ocean Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Ocean Green.jpg",
                  },
                ],
              },
              {
                name: "Olive Green",
                thumbnail: "/assets/textures/Glass/Olive Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Olive Green.jpg",
                  },
                ],
              },
              {
                name: "Orange Mist",
                thumbnail: "/assets/textures/Glass/orange mist.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/orange mist.jpg",
                  },
                ],
              },
              {
                name: "Sky Blue",
                thumbnail: "/assets/textures/Glass/Sky Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Sky Blue.jpg",
                  },
                ],
              },
              {
                name: "Solar Yellow",
                thumbnail: "/assets/textures/Glass/Solar Yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Solar Yellow.jpg",
                  },
                ],
              },
              {
                name: "Steel Grey",
                thumbnail: "/assets/textures/Glass/Steel Grey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Steel Grey.jpg",
                  },
                ],
              },
              {
                name: "Sunny Orange",
                thumbnail: "/assets/textures/Glass/Sunny Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Sunny Orange.jpg",
                  },
                ],
              },
              {
                name: "Super White",
                thumbnail: "/assets/textures/Glass/SuperWhite.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/SuperWhite.jpg",
                  },
                ],
              },
              {
                name: "Tangerine Orange",
                thumbnail: "/assets/textures/Glass/Tangerine Orange.JPG",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Tangerine Orange.JPG",
                  },
                ],
              },
            ],
          },
          {
            name: "Main Fabric Color",
            multiSelect: false,
            options: [
              {
                name: "Caramel",
                thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Caramel.jpg",
                  },
                ],
              },
              {
                name: "Clouds",
                thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Clouds.jpg",
                  },
                ],
              },
              {
                name: "Dago Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
                  },
                ],
              },
              {
                name: "Dago Orange",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
                  },
                ],
              },
              {
                name: "Flame",
                thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Flame.jpg",
                  },
                ],
              },
              {
                name: "Granada",
                thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Granada.jpg",
                  },
                ],
              },
              {
                name: "Greysto",
                thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Greysto.jpg",
                  },
                ],
              },
              {
                name: "Lemon",
                thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Lemon.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
                  },
                ],
              },
              {
                name: "Mink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Mink.jpg",
                  },
                ],
              },
              {
                name: "Olive",
                thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Olive.jpg",
                  },
                ],
              },
              {
                name: "P Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/P_Green.jpg",
                  },
                ],
              },
              {
                name: "Peppermint",
                thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
                  },
                ],
              },
              {
                name: "Pink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Pink.jpg",
                  },
                ],
              },
              {
                name: "Planet",
                thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Planet.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Sangria.jpg",
                  },
                ],
              },
              {
                name: "Stone",
                thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Stone.jpg",
                  },
                ],
              },
              {
                name: "Tangerine",
                thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
                  },
                ],
              },
              {
                name: "Waterfall",
                thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        multiSelect: false,
        options: [
          {
            name: "Main Glass Color",
            multiSelect: false,
            options: [
              {
                name: "Apple Green",
                thumbnail: "/assets/textures/Glass/Apple Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Apple Green.jpg",
                  },
                ],
              },
              {
                name: "Aqua Maring",
                thumbnail: "/assets/textures/Glass/Aqua Marine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Aqua Marine.jpg",
                  },
                ],
              },
              {
                name: "Azure Blue",
                thumbnail: "/assets/textures/Glass/Azure Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Azure Blue.jpg",
                  },
                ],
              },
              {
                name: "Beige",
                thumbnail: "/assets/textures/Glass/Beige.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Beige.jpg",
                  },
                ],
              },
              {
                name: "Black",
                thumbnail: "/assets/textures/Glass/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Black.jpg",
                  },
                ],
              },
              {
                name: "Canary Yellow",
                thumbnail: "/assets/textures/Glass/Canary Yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Canary Yellow.jpg",
                  },
                ],
              },
              {
                name: "Casis Purple",
                thumbnail: "/assets/textures/Glass/Casis Purple.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Casis Purple.jpg",
                  },
                ],
              },
              {
                name: "Classic Ivory",
                thumbnail: "/assets/textures/Glass/ClassicIvory.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/ClassicIvory.jpg",
                  },
                ],
              },
              {
                name: "Crystal White",
                thumbnail: "/assets/textures/Glass/Crystal White.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Crystal White.jpg",
                  },
                ],
              },
              {
                name: "Dark Blue",
                thumbnail: "/assets/textures/Glass/Dark Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Dark Blue.jpg",
                  },
                ],
              },
              {
                name: "Dark Bronze",
                thumbnail: "/assets/textures/Glass/Dark Bronze.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Dark Bronze.jpg",
                  },
                ],
              },
              {
                name: "Dark Lavender",
                thumbnail: "/assets/textures/Glass/dark lavender.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/dark lavender.jpg",
                  },
                ],
              },
              {
                name: "Dark Red",
                thumbnail: "/assets/textures/Glass/Dark Red.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Dark Red.jpg",
                  },
                ],
              },
              {
                name: "Ergo Grey",
                thumbnail: "/assets/textures/Glass/Ergo Grey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Ergo Grey.jpg",
                  },
                ],
              },
              {
                name: "Euro Bronze Frosted",
                thumbnail: "/assets/textures/Glass/Euro Bronze Frosted.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl:
                      "/assets/textures/Glass/Euro Bronze Frosted.jpg",
                  },
                ],
              },
              {
                name: "Euro Bronze",
                thumbnail: "/assets/textures/Glass/Euro Bronze.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Euro Bronze.jpg",
                  },
                ],
              },
              {
                name: "Euro Grey",
                thumbnail: "/assets/textures/Glass/Euro Grey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Euro Grey.jpg",
                  },
                ],
              },
              {
                name: "Flame Red",
                thumbnail: "/assets/textures/Glass/Flame-red.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Flame-red.jpg",
                  },
                ],
              },
              {
                name: "Frosted White",
                thumbnail: "/assets/textures/Glass/FrostedWhite.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/FrostedWhite.jpg",
                  },
                ],
              },
              {
                name: "Green Lime",
                thumbnail: "/assets/textures/Glass/Green Lime.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Green Lime.jpg",
                  },
                ],
              },
              {
                name: "Mediterranean Blue",
                thumbnail: "/assets/textures/Glass/Mediterranean Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Mediterranean Blue.jpg",
                  },
                ],
              },
              {
                name: "Ocean Green",
                thumbnail: "/assets/textures/Glass/Ocean Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Ocean Green.jpg",
                  },
                ],
              },
              {
                name: "Olive Green",
                thumbnail: "/assets/textures/Glass/Olive Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Olive Green.jpg",
                  },
                ],
              },
              {
                name: "Orange Mist",
                thumbnail: "/assets/textures/Glass/orange mist.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/orange mist.jpg",
                  },
                ],
              },
              {
                name: "Sky Blue",
                thumbnail: "/assets/textures/Glass/Sky Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Sky Blue.jpg",
                  },
                ],
              },
              {
                name: "Solar Yellow",
                thumbnail: "/assets/textures/Glass/Solar Yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Solar Yellow.jpg",
                  },
                ],
              },
              {
                name: "Steel Grey",
                thumbnail: "/assets/textures/Glass/Steel Grey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Steel Grey.jpg",
                  },
                ],
              },
              {
                name: "Sunny Orange",
                thumbnail: "/assets/textures/Glass/Sunny Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Sunny Orange.jpg",
                  },
                ],
              },
              {
                name: "Super White",
                thumbnail: "/assets/textures/Glass/SuperWhite.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/SuperWhite.jpg",
                  },
                ],
              },
              {
                name: "Tangerine Orange",
                thumbnail: "/assets/textures/Glass/Tangerine Orange.JPG",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass",
                    textureUrl: "/assets/textures/Glass/Tangerine Orange.JPG",
                  },
                ],
              },
            ],
          },
          {
            name: "Main Fabric Color",
            multiSelect: false,
            options: [
              {
                name: "Caramel",
                thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Caramel.jpg",
                  },
                ],
              },
              {
                name: "Clouds",
                thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Clouds.jpg",
                  },
                ],
              },
              {
                name: "Dago Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
                  },
                ],
              },
              {
                name: "Dago Orange",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
                  },
                ],
              },
              {
                name: "Flame",
                thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Flame.jpg",
                  },
                ],
              },
              {
                name: "Granada",
                thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Granada.jpg",
                  },
                ],
              },
              {
                name: "Greysto",
                thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Greysto.jpg",
                  },
                ],
              },
              {
                name: "Lemon",
                thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Lemon.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
                  },
                ],
              },
              {
                name: "Mink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Mink.jpg",
                  },
                ],
              },
              {
                name: "Olive",
                thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Olive.jpg",
                  },
                ],
              },
              {
                name: "P Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/P_Green.jpg",
                  },
                ],
              },
              {
                name: "Peppermint",
                thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
                  },
                ],
              },
              {
                name: "Pink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Pink.jpg",
                  },
                ],
              },
              {
                name: "Planet",
                thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Planet.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Sangria.jpg",
                  },
                ],
              },
              {
                name: "Stone",
                thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Stone.jpg",
                  },
                ],
              },
              {
                name: "Tangerine",
                thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
                  },
                ],
              },
              {
                name: "Waterfall",
                thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric",
                    textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Side Privacy Color Option",
    options: [
      {
        name: "SHARING",
        multiSelect: false,
        options: [
          {
            name: "Side Glass Color",
            multiSelect: false,
            options: [
              {
                name: "Apple Green",
                thumbnail: "/assets/textures/Glass/Apple Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Apple Green.jpg",
                  },
                ],
              },
              {
                name: "Aqua Maring",
                thumbnail: "/assets/textures/Glass/Aqua Marine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Aqua Marine.jpg",
                  },
                ],
              },
              {
                name: "Azure Blue",
                thumbnail: "/assets/textures/Glass/Azure Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Azure Blue.jpg",
                  },
                ],
              },
              {
                name: "Beige",
                thumbnail: "/assets/textures/Glass/Beige.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Beige.jpg",
                  },
                ],
              },
              {
                name: "Black",
                thumbnail: "/assets/textures/Glass/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Black.jpg",
                  },
                ],
              },
              {
                name: "Canary Yellow",
                thumbnail: "/assets/textures/Glass/Canary Yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Canary Yellow.jpg",
                  },
                ],
              },
              {
                name: "Casis Purple",
                thumbnail: "/assets/textures/Glass/Casis Purple.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Casis Purple.jpg",
                  },
                ],
              },
              {
                name: "Classic Ivory",
                thumbnail: "/assets/textures/Glass/ClassicIvory.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/ClassicIvory.jpg",
                  },
                ],
              },
              {
                name: "Crystal White",
                thumbnail: "/assets/textures/Glass/Crystal White.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Crystal White.jpg",
                  },
                ],
              },
              {
                name: "Dark Blue",
                thumbnail: "/assets/textures/Glass/Dark Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Dark Blue.jpg",
                  },
                ],
              },
              {
                name: "Dark Bronze",
                thumbnail: "/assets/textures/Glass/Dark Bronze.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Dark Bronze.jpg",
                  },
                ],
              },
              {
                name: "Dark Lavender",
                thumbnail: "/assets/textures/Glass/dark lavender.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/dark lavender.jpg",
                  },
                ],
              },
              {
                name: "Dark Red",
                thumbnail: "/assets/textures/Glass/Dark Red.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Dark Red.jpg",
                  },
                ],
              },
              {
                name: "Ergo Grey",
                thumbnail: "/assets/textures/Glass/Ergo Grey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Ergo Grey.jpg",
                  },
                ],
              },
              {
                name: "Euro Bronze Frosted",
                thumbnail: "/assets/textures/Glass/Euro Bronze Frosted.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl:
                      "/assets/textures/Glass/Euro Bronze Frosted.jpg",
                  },
                ],
              },
              {
                name: "Euro Bronze",
                thumbnail: "/assets/textures/Glass/Euro Bronze.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Euro Bronze.jpg",
                  },
                ],
              },
              {
                name: "Euro Grey",
                thumbnail: "/assets/textures/Glass/Euro Grey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Euro Grey.jpg",
                  },
                ],
              },
              {
                name: "Flame Red",
                thumbnail: "/assets/textures/Glass/Flame-red.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Flame-red.jpg",
                  },
                ],
              },
              {
                name: "Frosted White",
                thumbnail: "/assets/textures/Glass/FrostedWhite.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/FrostedWhite.jpg",
                  },
                ],
              },
              {
                name: "Green Lime",
                thumbnail: "/assets/textures/Glass/Green Lime.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Green Lime.jpg",
                  },
                ],
              },
              {
                name: "Mediterranean Blue",
                thumbnail: "/assets/textures/Glass/Mediterranean Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Mediterranean Blue.jpg",
                  },
                ],
              },
              {
                name: "Ocean Green",
                thumbnail: "/assets/textures/Glass/Ocean Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Ocean Green.jpg",
                  },
                ],
              },
              {
                name: "Olive Green",
                thumbnail: "/assets/textures/Glass/Olive Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Olive Green.jpg",
                  },
                ],
              },
              {
                name: "Orange Mist",
                thumbnail: "/assets/textures/Glass/orange mist.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/orange mist.jpg",
                  },
                ],
              },
              {
                name: "Sky Blue",
                thumbnail: "/assets/textures/Glass/Sky Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Sky Blue.jpg",
                  },
                ],
              },
              {
                name: "Solar Yellow",
                thumbnail: "/assets/textures/Glass/Solar Yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Solar Yellow.jpg",
                  },
                ],
              },
              {
                name: "Steel Grey",
                thumbnail: "/assets/textures/Glass/Steel Grey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Steel Grey.jpg",
                  },
                ],
              },
              {
                name: "Sunny Orange",
                thumbnail: "/assets/textures/Glass/Sunny Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Sunny Orange.jpg",
                  },
                ],
              },
              {
                name: "Super White",
                thumbnail: "/assets/textures/Glass/SuperWhite.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/SuperWhite.jpg",
                  },
                ],
              },
              {
                name: "Tangerine Orange",
                thumbnail: "/assets/textures/Glass/Tangerine Orange.JPG",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Tangerine Orange.JPG",
                  },
                ],
              },
            ],
          },
          {
            name: "Side Fabric Color",
            multiSelect: false,
            options: [
              {
                name: "Caramel",
                thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Caramel.jpg",
                  },
                ],
              },
              {
                name: "Clouds",
                thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Clouds.jpg",
                  },
                ],
              },
              {
                name: "Dago Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
                  },
                ],
              },
              {
                name: "Dago Orange",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
                  },
                ],
              },
              {
                name: "Flame",
                thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Flame.jpg",
                  },
                ],
              },
              {
                name: "Granada",
                thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Granada.jpg",
                  },
                ],
              },
              {
                name: "Greysto",
                thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Greysto.jpg",
                  },
                ],
              },
              {
                name: "Lemon",
                thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Lemon.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
                  },
                ],
              },
              {
                name: "Mink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Mink.jpg",
                  },
                ],
              },
              {
                name: "Olive",
                thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Olive.jpg",
                  },
                ],
              },
              {
                name: "P Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/P_Green.jpg",
                  },
                ],
              },
              {
                name: "Peppermint",
                thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
                  },
                ],
              },
              {
                name: "Pink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Pink.jpg",
                  },
                ],
              },
              {
                name: "Planet",
                thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Planet.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Sangria.jpg",
                  },
                ],
              },
              {
                name: "Stone",
                thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Stone.jpg",
                  },
                ],
              },
              {
                name: "Tangerine",
                thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
                  },
                ],
              },
              {
                name: "Waterfall",
                thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        multiSelect: false,
        options: [
          {
            name: "Side Glass Color",
            multiSelect: false,
            options: [
              {
                name: "Apple Green",
                thumbnail: "/assets/textures/Glass/Apple Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Apple Green.jpg",
                  },
                ],
              },
              {
                name: "Aqua Maring",
                thumbnail: "/assets/textures/Glass/Aqua Marine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Aqua Marine.jpg",
                  },
                ],
              },
              {
                name: "Azure Blue",
                thumbnail: "/assets/textures/Glass/Azure Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Azure Blue.jpg",
                  },
                ],
              },
              {
                name: "Beige",
                thumbnail: "/assets/textures/Glass/Beige.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Beige.jpg",
                  },
                ],
              },
              {
                name: "Black",
                thumbnail: "/assets/textures/Glass/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Black.jpg",
                  },
                ],
              },
              {
                name: "Canary Yellow",
                thumbnail: "/assets/textures/Glass/Canary Yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Canary Yellow.jpg",
                  },
                ],
              },
              {
                name: "Casis Purple",
                thumbnail: "/assets/textures/Glass/Casis Purple.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Casis Purple.jpg",
                  },
                ],
              },
              {
                name: "Classic Ivory",
                thumbnail: "/assets/textures/Glass/ClassicIvory.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/ClassicIvory.jpg",
                  },
                ],
              },
              {
                name: "Crystal White",
                thumbnail: "/assets/textures/Glass/Crystal White.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Crystal White.jpg",
                  },
                ],
              },
              {
                name: "Dark Blue",
                thumbnail: "/assets/textures/Glass/Dark Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Dark Blue.jpg",
                  },
                ],
              },
              {
                name: "Dark Bronze",
                thumbnail: "/assets/textures/Glass/Dark Bronze.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Dark Bronze.jpg",
                  },
                ],
              },
              {
                name: "Dark Lavender",
                thumbnail: "/assets/textures/Glass/dark lavender.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/dark lavender.jpg",
                  },
                ],
              },
              {
                name: "Dark Red",
                thumbnail: "/assets/textures/Glass/Dark Red.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Dark Red.jpg",
                  },
                ],
              },
              {
                name: "Ergo Grey",
                thumbnail: "/assets/textures/Glass/Ergo Grey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Ergo Grey.jpg",
                  },
                ],
              },
              {
                name: "Euro Bronze Frosted",
                thumbnail: "/assets/textures/Glass/Euro Bronze Frosted.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl:
                      "/assets/textures/Glass/Euro Bronze Frosted.jpg",
                  },
                ],
              },
              {
                name: "Euro Bronze",
                thumbnail: "/assets/textures/Glass/Euro Bronze.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Euro Bronze.jpg",
                  },
                ],
              },
              {
                name: "Euro Grey",
                thumbnail: "/assets/textures/Glass/Euro Grey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Euro Grey.jpg",
                  },
                ],
              },
              {
                name: "Flame Red",
                thumbnail: "/assets/textures/Glass/Flame-red.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Flame-red.jpg",
                  },
                ],
              },
              {
                name: "Frosted White",
                thumbnail: "/assets/textures/Glass/FrostedWhite.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/FrostedWhite.jpg",
                  },
                ],
              },
              {
                name: "Green Lime",
                thumbnail: "/assets/textures/Glass/Green Lime.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Green Lime.jpg",
                  },
                ],
              },
              {
                name: "Mediterranean Blue",
                thumbnail: "/assets/textures/Glass/Mediterranean Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Mediterranean Blue.jpg",
                  },
                ],
              },
              {
                name: "Ocean Green",
                thumbnail: "/assets/textures/Glass/Ocean Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Ocean Green.jpg",
                  },
                ],
              },
              {
                name: "Olive Green",
                thumbnail: "/assets/textures/Glass/Olive Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Olive Green.jpg",
                  },
                ],
              },
              {
                name: "Orange Mist",
                thumbnail: "/assets/textures/Glass/orange mist.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/orange mist.jpg",
                  },
                ],
              },
              {
                name: "Sky Blue",
                thumbnail: "/assets/textures/Glass/Sky Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Sky Blue.jpg",
                  },
                ],
              },
              {
                name: "Solar Yellow",
                thumbnail: "/assets/textures/Glass/Solar Yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Solar Yellow.jpg",
                  },
                ],
              },
              {
                name: "Steel Grey",
                thumbnail: "/assets/textures/Glass/Steel Grey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Steel Grey.jpg",
                  },
                ],
              },
              {
                name: "Sunny Orange",
                thumbnail: "/assets/textures/Glass/Sunny Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Sunny Orange.jpg",
                  },
                ],
              },
              {
                name: "Super White",
                thumbnail: "/assets/textures/Glass/SuperWhite.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/SuperWhite.jpg",
                  },
                ],
              },
              {
                name: "Tangerine Orange",
                thumbnail: "/assets/textures/Glass/Tangerine Orange.JPG",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_side",
                    textureUrl: "/assets/textures/Glass/Tangerine Orange.JPG",
                  },
                ],
              },
            ],
          },
          {
            name: "Side Fabric Color",
            multiSelect: false,
            options: [
              {
                name: "Caramel",
                thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Caramel.jpg",
                  },
                ],
              },
              {
                name: "Clouds",
                thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Clouds.jpg",
                  },
                ],
              },
              {
                name: "Dago Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
                  },
                ],
              },
              {
                name: "Dago Orange",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
                  },
                ],
              },
              {
                name: "Flame",
                thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Flame.jpg",
                  },
                ],
              },
              {
                name: "Granada",
                thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Granada.jpg",
                  },
                ],
              },
              {
                name: "Greysto",
                thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Greysto.jpg",
                  },
                ],
              },
              {
                name: "Lemon",
                thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Lemon.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
                  },
                ],
              },
              {
                name: "Mink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Mink.jpg",
                  },
                ],
              },
              {
                name: "Olive",
                thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Olive.jpg",
                  },
                ],
              },
              {
                name: "P Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/P_Green.jpg",
                  },
                ],
              },
              {
                name: "Peppermint",
                thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
                  },
                ],
              },
              {
                name: "Pink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Pink.jpg",
                  },
                ],
              },
              {
                name: "Planet",
                thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Planet.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Sangria.jpg",
                  },
                ],
              },
              {
                name: "Stone",
                thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Stone.jpg",
                  },
                ],
              },
              {
                name: "Tangerine",
                thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
                  },
                ],
              },
              {
                name: "Waterfall",
                thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric_side",
                    textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Modesty Options",

    options: [
      {
        name: "SHARING",
        required: false,

        options: [
          {
            name: "MID Modesty",
            nodeName: ["metalmodesty", "woodmodesty"],
            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: ["metalmodesty", "woodmodesty"],
                  },
                ],
              },
              {
                name: "Mid Metal",
                thumbnail: "/assets/afc/wmm.png",

                rules: [
                  {
                    type: "show",
                    node: ["metalmodesty"],
                  },
                  {
                    type: "hide",
                    node: ["woodmodesty"],
                  },
                ],
              },
              {
                name: "Mid Wood",
                thumbnail: "/assets/afc/wwm.png",

                rules: [
                  {
                    type: "show",
                    node: ["woodmodesty"],
                  },
                  {
                    type: "hide",
                    node: ["metalmodesty"],
                  },
                ],
              },
            ],
          },
          {
            ////
            name: "Side Modesty",
            nodeName: ["metalmodesty_side", "woodmodesty_side"],
            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "metalmodesty_side",
                      "woodmodesty_side",
                      "metalmodesty_sleek",
                      "woodmodesty_sleek",
                    ],
                  },
                ],
              },
              {
                name: "Side Metal",
                thumbnail: "/assets/afc/wsm.png",

                rules: [
                  {
                    type: "hide",

                    node: [
                      "woodmodesty_sleek",
                      "metalmodesty_sleek",
                      "metalmodesty_side",
                      "woodmodesty_side",
                      "metalmodesty_slant",
                      "woodmodesty_slant",
                      "metalmodesty_sleek_livo",
                      "woodmodesty_proceed",
                      "woodmodesty_sleek_livo",
                      "metalmodesty_proceed",
                    ],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Loop Leg",
                          "30*60 Straight Leg",

                          "40*40 Loop Leg",
                          "40*40 Straight Leg",
                          "X-BENCH",
                          "DESKPRO STRAIGHT",
                          "DESKPRO (LOOP)",
                          "TRIANGULAR LEG (STRAIGHT)",
                          "TRIANGULAR LEG (LOOP)",
                        ],
                      },
                    ],
                    node: ["metalmodesty_side"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["Livo Leg slanted", "SLEEK LEG", "CURVIVO"],
                      },
                    ],
                    node: ["metalmodesty_sleek"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Strip Leg",
                          "DESKPRO SLANT",
                          "TRIANGULAR LEG (SLANT)",
                          "30*60 Slant Leg",
                          "40*40 Slanted Leg",
                        ],
                      },
                    ],
                    node: ["metalmodesty_slant"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["PROOCED"],
                      },
                    ],
                    node: ["metalmodesty_proceed"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["LIVO"],
                      },
                    ],
                    node: ["metalmodesty_sleek_livo"],
                  },
                ],
              },
              {
                name: "Side Wood",
                thumbnail: "/assets/afc/wsw.png",

                rules: [
                  {
                    type: "hide",

                    node: [
                      "woodmodesty_sleek",
                      "metalmodesty_sleek",
                      "metalmodesty_side",
                      "woodmodesty_side",
                      "metalmodesty_slant",
                      "woodmodesty_slant",
                      "metalmodesty_sleek_livo",
                      "woodmodesty_proceed",
                      "woodmodesty_sleek_livo",
                      "metalmodesty_proceed",
                    ],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Loop Leg",
                          "30*60 Straight Leg",

                          "40*40 Loop Leg",
                          "40*40 Straight Leg",
                          "X-BENCH",
                          "DESKPRO STRAIGHT",
                          "DESKPRO (LOOP)",
                          "TRIANGULAR LEG (STRAIGHT)",
                          "TRIANGULAR LEG (LOOP)",
                        ],
                      },
                    ],
                    node: ["woodmodesty_side"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["Livo Leg slanted", "SLEEK LEG", "CURVIVO"],
                      },
                    ],
                    node: ["woodmodesty_sleek"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Strip Leg",
                          "DESKPRO SLANT",
                          "TRIANGULAR LEG (SLANT)",
                          "30*60 Slant Leg",
                          "40*40 Slanted Leg",
                        ],
                      },
                    ],
                    node: ["woodmodesty_slant"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["PROOCED"],
                      },
                    ],
                    node: ["woodmodesty_proceed"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["LIVO"],
                      },
                    ],
                    node: ["woodmodesty_sleek_livo"],
                  },
                ],
              },
            ],
          },
          {
            name: "PowderCoat  Options for Modesty",
            multiSelect: false,
            options: [
              {
                name: "AFC Black",
                thumbnail: "/assets/textures/Metal/thumbnail/black.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/black.jpeg",
                  },
                ],
              },
              {
                name: "AFC White",
                thumbnail: "/assets/textures/Metal/thumbnail/white.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/white.jpeg",
                  },
                ],
              },
              {
                name: "AFC Munshell Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/munshellgrey.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/munshellgrey.jpeg",
                  },
                ],
              },
              {
                name: "AFC Graphite Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/graphitegrey.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/graphitegrey.jpeg",
                  },
                ],
              },
              {
                name: "AFC Silver Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/silvergrey.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/silvergrey.jpeg",
                  },
                ],
              },
              {
                name: "AFC DA Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/dagrey.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/dagrey.jpeg",
                  },
                ],
              },
              {
                name: "AFC Sky Blue",
                thumbnail: "/assets/textures/Metal/thumbnail/blue.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/blue.jpeg",
                  },
                ],
              },
              {
                name: "AFC Sea Green",
                thumbnail: "/assets/textures/Metal/thumbnail/green.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/green.jpeg",
                  },
                ],
              },
              {
                name: "AFC Light Orange",
                thumbnail: "/assets/textures/Metal/thumbnail/orange.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/orange.jpeg",
                  },
                ],
              },
              {
                name: "AFC Misty Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/mistygrey.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/mistygrey.jpeg",
                  },
                ],
              },
              {
                name: "AFC Nut Brown",
                thumbnail: "/assets/textures/Metal/thumbnail/nutbrown.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/nutbrown.jpeg",
                  },
                ],
              },
            ],
          },
          {
            name: "Wood Color Options for Modesty",
            multiSelect: false,
            options: [
              {
                // name: "Options",
                // required: false,
                // options: [
                //   {
                name: "Frosty White",
                thumbnail: "/assets/textures/Wood/thumbnail/white.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/white.jpg",
                  },
                ],
              },
              {
                name: "Slate Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/slategrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/slategrey.jpg",
                  },
                ],
              },
              {
                name: "Gothic Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/gothicgrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/gothicgrey.jpg",
                  },
                ],
              },
              {
                name: "Silver Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/silvergrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/silvergrey.jpg",
                  },
                ],
              },
              {
                name: "Highland Pine",
                thumbnail: "/assets/textures/Wood/thumbnail/highland.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/highland.jpg",
                  },
                ],
              },
              {
                name: "Moldau Acacia Light",
                thumbnail: "/assets/textures/Wood/thumbnail/acacialight.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/acacialight.jpg",
                  },
                ],
              },
              {
                name: "Moldau Acacia Dark",
                thumbnail: "/assets/textures/Wood/thumbnail/acaciadark.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/acaciadark.jpg",
                  },
                ],
              },
              {
                name: "Thansau Maple",
                thumbnail: "/assets/textures/Wood/thumbnail/thansau.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/thansau.jpg",
                  },
                ],
              },
              {
                name: "Mangfall Beech",
                thumbnail: "/assets/textures/Wood/thumbnail/mangfall.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/mangfall.jpg",
                  },
                ],
              },
              {
                name: "Flowery Wenge",
                thumbnail: "/assets/textures/Wood/thumbnail/flowerywenge.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/flowerywenge.jpg",
                  },
                ],
              },
              {
                name: "Persian Walnut",
                thumbnail: "/assets/textures/Wood/thumbnail/persian.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/persian.jpg",
                  },
                ],
              },
              {
                name: "Urban Teak",
                thumbnail: "/assets/textures/Wood/thumbnail/urban.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/urban.jpg",
                  },
                ],
              },
              // ],
              // },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        multiSelect: false,
        options: [
          {
            name: "MID Modesty",
            nodeName: ["metalmodesty", "woodmodesty"],
            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: ["metalmodesty", "woodmodesty"],
                  },
                ],
              },
              {
                name: "Mid Metal",
                thumbnail: "/assets/afc/wmm.png",

                rules: [
                  {
                    type: "show",
                    node: ["metalmodesty"],
                  },
                  {
                    type: "hide",
                    node: ["woodmodesty"],
                  },
                ],
              },
              {
                name: "Mid Wood",
                thumbnail: "/assets/afc/wwm.png",

                rules: [
                  {
                    type: "show",
                    node: ["woodmodesty"],
                  },
                  {
                    type: "hide",
                    node: ["metalmodesty"],
                  },
                ],
              },
            ],
          },
          {
            ////
            name: "Side Modesty",
            nodeName: ["metalmodesty_side", "woodmodesty_side"],
            required: false,
            options: [
              {
                name: "none",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "metalmodesty_side",
                      "woodmodesty_side",
                      "metalmodesty_sleek",
                      "woodmodesty_sleek",
                    ],
                  },
                ],
              },
              {
                name: "Side Metal",
                thumbnail: "/assets/afc/wsm.png",

                rules: [
                  {
                    type: "hide",

                    node: [
                      "woodmodesty_sleek",
                      "metalmodesty_sleek",
                      "metalmodesty_side",
                      "woodmodesty_side",
                      "metalmodesty_slant",
                      "woodmodesty_slant",
                      "metalmodesty_sleek_livo",
                      "woodmodesty_proceed",
                      "woodmodesty_sleek_livo",
                      "metalmodesty_proceed",
                    ],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Loop Leg",
                          "30*60 Straight Leg",

                          "40*40 Loop Leg",
                          "40*40 Straight Leg",
                          "X-BENCH",
                          "DESKPRO STRAIGHT",
                          "DESKPRO (LOOP)",
                          "TRIANGULAR LEG (STRAIGHT)",
                          "TRIANGULAR LEG (LOOP)",
                        ],
                      },
                    ],
                    node: ["metalmodesty_side"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["Livo Leg slanted", "SLEEK LEG", "CURVIVO"],
                      },
                    ],
                    node: ["metalmodesty_sleek"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Strip Leg",
                          "DESKPRO SLANT",
                          "TRIANGULAR LEG (SLANT)",
                          "30*60 Slant Leg",
                          "40*40 Slanted Leg",
                        ],
                      },
                    ],
                    node: ["metalmodesty_slant"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["PROOCED"],
                      },
                    ],
                    node: ["metalmodesty_proceed"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["LIVO"],
                      },
                    ],
                    node: ["metalmodesty_sleek_livo"],
                  },
                ],
              },
              {
                name: "Side Wood",
                thumbnail: "/assets/afc/wsw.png",

                rules: [
                  {
                    type: "hide",

                    node: [
                      "woodmodesty_sleek",
                      "metalmodesty_sleek",
                      "metalmodesty_side",
                      "woodmodesty_side",
                      "metalmodesty_slant",
                      "woodmodesty_slant",
                      "metalmodesty_sleek_livo",
                      "woodmodesty_proceed",
                      "woodmodesty_sleek_livo",
                      "metalmodesty_proceed",
                    ],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Loop Leg",
                          "30*60 Straight Leg",

                          "40*40 Loop Leg",
                          "40*40 Straight Leg",
                          "X-BENCH",
                          "DESKPRO STRAIGHT",
                          "DESKPRO (LOOP)",
                          "TRIANGULAR LEG (STRAIGHT)",
                          "TRIANGULAR LEG (LOOP)",
                        ],
                      },
                    ],
                    node: ["woodmodesty_side"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["Livo Leg slanted", "SLEEK LEG", "CURVIVO"],
                      },
                    ],
                    node: ["woodmodesty_sleek"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: [
                          "30*60 Strip Leg",
                          "DESKPRO SLANT",
                          "TRIANGULAR LEG (SLANT)",
                          "30*60 Slant Leg",
                          "40*40 Slanted Leg",
                        ],
                      },
                    ],
                    node: ["woodmodesty_slant"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["PROOCED"],
                      },
                    ],
                    node: ["woodmodesty_proceed"],
                  },
                  {
                    type: "show",
                    condition: [
                      {
                        feature: "Legs",
                        values: ["LIVO"],
                      },
                    ],
                    node: ["woodmodesty_sleek_livo"],
                  },
                ],
              },
            ],
          },
          {
            name: "PowderCoat  Options for Modesty",
            multiSelect: false,
            options: [
              {
                name: "AFC Black",
                thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/Black.jpg",
                  },
                ],
              },
              {
                name: "AFC White",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl:
                      "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
                  },
                ],
              },
              {
                name: "AFC Ivory",
                thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/Ivory.jpg",
                  },
                ],
              },
              {
                name: "AFC Metallic Dark Grey",
                thumbnail:
                  "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
                  },
                ],
              },
              {
                name: "AFC Moonlight Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
                  },
                ],
              },
              {
                name: "AFC Silver Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "metalmodesty",
                    textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
                  },
                ],
              },
            ],
          },
          {
            name: "Wood Color Options for Modesty",
            multiSelect: false,
            options: [
              {
                // name: "Options",
                // required: false,
                // options: [
                //   {
                name: "Frosty White",
                thumbnail: "/assets/textures/Wood/thumbnail/white.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["woodmodesty"],
                    textureUrl: "/assets/textures/Wood/2k/white.jpg",
                  },
                ],
              },
              {
                name: "Slate Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/slategrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/slategrey.jpg",
                  },
                ],
              },
              {
                name: "Gothic Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/gothicgrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/gothicgrey.jpg",
                  },
                ],
              },
              {
                name: "Silver Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/silvergrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/silvergrey.jpg",
                  },
                ],
              },
              {
                name: "Highland Pine",
                thumbnail: "/assets/textures/Wood/thumbnail/highland.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/highland.jpg",
                  },
                ],
              },
              {
                name: "Moldau Acacia Light",
                thumbnail: "/assets/textures/Wood/thumbnail/acacialight.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/acacialight.jpg",
                  },
                ],
              },
              {
                name: "Moldau Acacia Dark",
                thumbnail: "/assets/textures/Wood/thumbnail/acaciadark.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/acaciadark.jpg",
                  },
                ],
              },
              {
                name: "Thansau Maple",
                thumbnail: "/assets/textures/Wood/thumbnail/thansau.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/thansau.jpg",
                  },
                ],
              },
              {
                name: "Mangfall Beech",
                thumbnail: "/assets/textures/Wood/thumbnail/mangfall.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/mangfall.jpg",
                  },
                ],
              },
              {
                name: "Flowery Wenge",
                thumbnail: "/assets/textures/Wood/thumbnail/flowerywenge.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/flowerywenge.jpg",
                  },
                ],
              },
              {
                name: "Persian Walnut",
                thumbnail: "/assets/textures/Wood/thumbnail/persian.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/persian.jpg",
                  },
                ],
              },
              {
                name: "Urban Teak",
                thumbnail: "/assets/textures/Wood/thumbnail/urban.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "woodmodesty",
                    textureUrl: "/assets/textures/Wood/2k/urban.jpg",
                  },
                ],
              },
              // ],
              // },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Accessories",
    multiSelect: false,
    options: [
      {
        name: "SHARING",
        required: false,
        multiSelect: false,
        options: [
          {
            name: "none",
            thumbnail: "/assets/components/none.jpg",
            rules: [
              {
                type: "hide",
                node: [
                  "hangingmetalbaghook",
                  "hangingmetalmarker_1",
                  "rail",
                  "cpu_stand",
                  "hangingcpustand",
                  "Key&mousetray",
                  "Key_board_tray",
                  "hangingmetalmarker_2",
                  "hangingmetalpapertray",
                  "haningmetalpenholder",
                  "metaldividerstand",
                  "metallaptopstand",
                  "metalmobilestand",
                  "metalsinglepaperholder",
                  "panelmountednameplate",
                  "keyboardmousetray",
                  "ROUND_POWER_DESKPORT_",
                  "POWER_DESKPORT_",
                  "sliderpowerdeskport",
                  "PVC_SNAKE_RISER",
                  "SINGLE_MONITOR_ARM_2",
                  "DUAL_MONITOR_ARM_1",
                  "DUAL_MONITOR_ARM_2",
                  "SINGLE_MONITOR_ARM_1",
                  "keyboardtray",
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                  "dualarm_1",
                  "dualarm_3",
                  "dualarm_5",
                  "dualarm_7",
                  "dualarm_9",
                  "dualarm_11",
                  "dualarm_13",
                  "dualarm_15",
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                  "singlearm_1",
                  "singlearm_3",
                  "singlearm_5",
                  "singlearm_7",
                  "singlearm_9",
                  "singlearm_11",
                  "singlearm_13",
                  "singlearm_15",
                  "singlearm_2",
                  "singlearm_4",
                  "singlearm_6",
                  "singlearm_8",
                  "singlearm_10",
                  "singlearm_12",
                  "singlearm_14",
                  "singlearm_16",
                ],
              },
            ],
          },

          {
            name: "CPU Stand",
            thumbnail: "/assets/afc/cpustand.png",
            rules: [
              {
                type: "hide",
                node: ["hangingcpustand"],
              },
              {
                type: "show",
                node: ["cpu_stand"],
              },
            ],
          },
          {
            name: "Hanging CPU Stand",
            thumbnail: "/assets/afc/hanging-cpu-stand.png",
            rules: [
              {
                type: "hide",
                node: ["cpu_stand"],
              },
              {
                type: "show",
                node: ["hangingcpustand"],
              },
            ],
          },
          {
            name: "Keyboard Tray",
            thumbnail: "/assets/afc/keyboard-tray.png",
            rules: [
              {
                type: "hide",
                node: ["keyboardmousetray"],
              },
              {
                type: "show",
                node: ["keyboardtray"],
              },
            ],
          },
          {
            name: "Keyboard and Mouse Tray",
            thumbnail: "/assets/afc/kandmtray.png",
            rules: [
              {
                type: "hide",
                node: ["keyboardtray"],
              },
              {
                type: "show",
                node: ["keyboardmousetray"],
              },
            ],
          },
          {
            name: "Hanging Metal Marker",
            thumbnail: "/assets/afc/hmm.png",
            rules: [
              {
                type: "hide",
                node: [
                  "hangingmetalmarker_2",
                  "hangingmetalpapertray",
                  "haningmetalpenholder",
                  "metaldividerstand",
                  "panelmountednameplate",
                  "metalsinglepaperholder",
                ],
              },
              {
                type: "show",
                node: ["hangingmetalmarker_1"],
              },
            ],
          },

          {
            name: "Panel Mounted Name Plate",
            thumbnail: "/assets/afc/pmnp.png",
            rules: [
              // {
              //   type: "hide",
              //   node: [
              //     "hangingmetalmarker_2",
              //     "hangingmetalpapertray",
              //     "haningmetalpenholder",
              //     "metaldividerstand",
              //     "hangingmetalpapertray",
              //     "metalsinglepaperholder",
              //   ],
              // },
              {
                type: "show",
                node: ["panelmountednameplate"],
              },
            ],
          },

          {
            name: "Hanging Metal Pen Holder",
            thumbnail: "/assets/afc/hmph.png",
            rules: [
              // {
              //   type: "hide",
              //   node: [
              //     "hangingmetalmarker_1",
              //     "hangingmetalpapertray",
              //     "metaldividerstand",
              //     "hangingmetalmarker_2",
              //     "panelmountednameplate",
              //     "metalsinglepaperholder",
              //   ],
              // },
              {
                type: "show",
                node: ["haningmetalpenholder"],
              },
            ],
          },
          {
            name: "Hanging Metal Bag Hook",
            thumbnail: "/assets/afc/hm.png",
            rules: [
              {
                type: "show",
                node: ["hangingmetalbaghook"],
              },
            ],
          },

          // {
          //   name: "PVC Black pencil tray for inside the pedestal drawer",
          //   thumbnail: "/assets/components/nameboard.png",
          //   rules: [
          //     {
          //       type: "hide",
          //       node: [""],
          //     },
          //     {
          //       type: "show",
          //       node: ["PVC_SNAKE_RISER"],
          //     },
          //   ],
          // },

          // {
          //   name: "SINGLE MONITOR ARM-1",
          //   thumbnail: "/assets/afc/sm1.png",

          //   rules: [
          //     {
          //       type: replacePart,
          //       node: [
          //         "singlearm_1",
          //         "singlearm_3",
          //         "singlearm_5",
          //         "singlearm_7",
          //         "singlearm_9",
          //         "singlearm_11",
          //         "singlearm_13",
          //         "singlearm_15",
          //       ],
          //       isScale: true,
          //       zoom: 0.3,
          //       pid: "https://content.helloviewer.io/uploads/fc5f044e-b7c4-4667-8ec6-e121602bf06e/21_5_2024/1718973989148/singlearm-1.glb",
          //     },
          //     {
          //       type: replacePart,
          //       node: [
          //         "singlearm_2",
          //         "singlearm_4",
          //         "singlearm_6",
          //         "singlearm_8",
          //         "singlearm_10",
          //         "singlearm_12",
          //         "singlearm_14",
          //         "singlearm_16",
          //       ],
          //       isScale: true,
          //       zoom: 0.3,
          //       pid: "https://content.helloviewer.io/uploads/2fba5329-a5fa-4878-9ddf-b874872d7e5d/21_5_2024/1718974046451/singlearm-1-180.glb",
          //     },
          //     {
          //       type: "show",
          //       node: [
          //         "singlearm_1",
          //         "singlearm_3",
          //         "singlearm_5",
          //         "singlearm_7",
          //         "singlearm_9",
          //         "singlearm_11",
          //         "singlearm_13",
          //         "singlearm_15",
          //         "singlearm_2",
          //         "singlearm_4",
          //         "singlearm_6",
          //         "singlearm_8",
          //         "singlearm_10",
          //         "singlearm_12",
          //         "singlearm_14",
          //         "singlearm_16",
          //       ],
          //     },
          //     {
          //       type: "hide",
          //       node: [
          //         "dualarm_2",
          //         "dualarm_4",
          //         "dualarm_6",
          //         "dualarm_8",
          //         "dualarm_10",
          //         "dualarm_12",
          //         "dualarm_14",
          //         "dualarm_16",
          //         "dualarm_1",
          //         "dualarm_3",
          //         "dualarm_5",
          //         "dualarm_7",
          //         "dualarm_9",
          //         "dualarm_11",
          //         "dualarm_13",
          //         "dualarm_15",
          //       ],
          //     },
          //   ],
          // },
          // {
          //   name: "DUAL MONITOR ARM-1",
          //   thumbnail: "/assets/afc/thumnail/dual arm.png",
          //   rules: [
          //     {
          //       type: replacePart,
          //       node: [
          //         "dualarm_1",
          //         "dualarm_3",
          //         "dualarm_5",
          //         "dualarm_7",
          //         "dualarm_9",
          //         "dualarm_11",
          //         "dualarm_13",
          //         "dualarm_15",
          //       ],
          //       isScale: true,
          //       zoom: 0.3,
          //       pid: "https://content.helloviewer.io/uploads/5ac98f00-80e1-4fdf-9445-26da36522405/21_5_2024/1718973630993/Dualarm-1.glb",
          //     },
          //     {
          //       type: replacePart,
          //       node: [
          //         "dualarm_2",
          //         "dualarm_4",
          //         "dualarm_6",
          //         "dualarm_8",
          //         "dualarm_10",
          //         "dualarm_12",
          //         "dualarm_14",
          //         "dualarm_16",
          //       ],
          //       isScale: true,
          //       zoom: 0.3,
          //       pid: "https://content.helloviewer.io/uploads/05d384d4-1332-40c9-8ef8-76945aabd2c2/21_5_2024/1718973689449/Dualarm-1-180.glb",
          //     },
          //     {
          //       type: "hide",
          //       node: [
          //         "singlearm_1",
          //         "singlearm_3",
          //         "singlearm_5",
          //         "singlearm_7",
          //         "singlearm_9",
          //         "singlearm_11",
          //         "singlearm_13",
          //         "singlearm_15",
          //         "singlearm_2",
          //         "singlearm_4",
          //         "singlearm_6",
          //         "singlearm_8",
          //         "singlearm_10",
          //         "singlearm_12",
          //         "singlearm_14",
          //         "singlearm_16",
          //       ],
          //     },
          //     {
          //       type: "show",
          //       node: [
          //         "dualarm_2",
          //         "dualarm_4",
          //         "dualarm_6",
          //         "dualarm_8",
          //         "dualarm_10",
          //         "dualarm_12",
          //         "dualarm_14",
          //         "dualarm_16",
          //         "dualarm_1",
          //         "dualarm_3",
          //         "dualarm_5",
          //         "dualarm_7",
          //         "dualarm_9",
          //         "dualarm_11",
          //         "dualarm_13",
          //         "dualarm_15",
          //       ],
          //     },
          //   ],
          // },

          // {
          //   name: "METAL DUAL PAPER HOLDER",
          //   thumbnail: "/assets/components/nameboard.png",
          //   rules: [
          //     {
          //       type: replacePart,
          //       node: [""],
          //     },

          //   ],
          // },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        multiSelect: false,
        options: [
          {
            name: "none",
            thumbnail: "/assets/components/none.jpg",
            rules: [
              {
                type: "hide",
                node: [
                  "hangingmetalbaghook",
                  "hangingmetalmarker_1",
                  "rail",
                  "cpu_stand",
                  "hangingcpustand",
                  "Key&mousetray",
                  "Key_board_tray",
                  "hangingmetalmarker_2",
                  "hangingmetalpapertray",
                  "haningmetalpenholder",
                  "metaldividerstand",
                  "metallaptopstand",
                  "metalmobilestand",
                  "metalsinglepaperholder",
                  "panelmountednameplate",
                  "keyboardmousetray",
                  "ROUND_POWER_DESKPORT_",
                  "POWER_DESKPORT_",
                  "sliderpowerdeskport",
                  "PVC_SNAKE_RISER",
                  "SINGLE_MONITOR_ARM_2",
                  "DUAL_MONITOR_ARM_1",
                  "DUAL_MONITOR_ARM_2",
                  "SINGLE_MONITOR_ARM_1",
                  "keyboardtray",
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                  "dualarm_1",
                  "dualarm_3",
                  "dualarm_5",
                  "dualarm_7",
                  "dualarm_9",
                  "dualarm_11",
                  "dualarm_13",
                  "dualarm_15",
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                  "singlearm_1",
                  "singlearm_3",
                  "singlearm_5",
                  "singlearm_7",
                  "singlearm_9",
                  "singlearm_11",
                  "singlearm_13",
                  "singlearm_15",
                  "singlearm_2",
                  "singlearm_4",
                  "singlearm_6",
                  "singlearm_8",
                  "singlearm_10",
                  "singlearm_12",
                  "singlearm_14",
                  "singlearm_16",
                ],
              },
            ],
          },

          {
            name: "CPU Stand",
            thumbnail: "/assets/afc/cpustand.png",
            rules: [
              {
                type: "hide",
                node: ["hangingcpustand"],
              },
              {
                type: "show",
                node: ["cpu_stand"],
              },
            ],
          },
          {
            name: "Hanging CPU Stand",
            thumbnail: "/assets/afc/hanging-cpu-stand.png",
            rules: [
              {
                type: "hide",
                node: ["cpu_stand"],
              },
              {
                type: "show",
                node: ["hangingcpustand"],
              },
            ],
          },
          {
            name: "Keyboard Tray",
            thumbnail: "/assets/afc/keyboard-tray.png",
            rules: [
              {
                type: "hide",
                node: ["keyboardmousetray"],
              },
              {
                type: "show",
                node: ["keyboardtray"],
              },
            ],
          },
          {
            name: "Keyboard and Mouse Tray",
            thumbnail: "/assets/afc/kandmtray.png",
            rules: [
              {
                type: "hide",
                node: ["keyboardtray"],
              },
              {
                type: "show",
                node: ["keyboardmousetray"],
              },
            ],
          },
          {
            name: "HANGING METAL MARKER",
            thumbnail: "/assets/afc/hmm.png",
            rules: [
              {
                type: "hide",
                node: [
                  "hangingmetalmarker_2",
                  "hangingmetalpapertray",
                  "haningmetalpenholder",
                  "metaldividerstand",
                  "panelmountednameplate",
                  "metalsinglepaperholder",
                ],
              },
              {
                type: "show",
                node: ["hangingmetalmarker_1"],
              },
            ],
          },

          {
            name: "PANEL MOUNTED NAME PLATE",
            thumbnail: "/assets/afc/pmnp.png",
            rules: [
              // {
              //   type: "hide",
              //   node: [
              //     "hangingmetalmarker_2",
              //     "hangingmetalpapertray",
              //     "haningmetalpenholder",
              //     "metaldividerstand",
              //     "hangingmetalpapertray",
              //     "metalsinglepaperholder",
              //   ],
              // },
              {
                type: "show",
                node: ["panelmountednameplate"],
              },
            ],
          },

          {
            name: "HANGING METAL PEN HOLDER 70L",
            thumbnail: "/assets/afc/hmph.png",
            rules: [
              // {
              //   type: "hide",
              //   node: [
              //     "hangingmetalmarker_1",
              //     "hangingmetalpapertray",
              //     "metaldividerstand",
              //     "hangingmetalmarker_2",
              //     "panelmountednameplate",
              //     "metalsinglepaperholder",
              //   ],
              // },
              {
                type: "show",
                node: ["haningmetalpenholder"],
              },
            ],
          },
          {
            name: "HANGING METAL BAG HOOK",
            thumbnail: "/assets/afc/hm.png",
            rules: [
              {
                type: "show",
                node: ["hangingmetalbaghook"],
              },
            ],
          },

          // {
          //   name: "PVC Black pencil tray for inside the pedestal drawer",
          //   thumbnail: "/assets/components/nameboard.png",
          //   rules: [
          //     {
          //       type: "hide",
          //       node: [""],
          //     },
          //     {
          //       type: "show",
          //       node: ["PVC_SNAKE_RISER"],
          //     },
          //   ],
          // },

          {
            name: "SINGLE MONITOR ARM-1",
            thumbnail: "/assets/afc/sm1.png",

            rules: [
              {
                type: replacePart,
                node: [
                  "singlearm_1",
                  "singlearm_3",
                  "singlearm_5",
                  "singlearm_7",
                  "singlearm_9",
                  "singlearm_11",
                  "singlearm_13",
                  "singlearm_15",
                ],
                isScale: true,
                zoom: 0.3,
                pid: "https://content.helloviewer.io/uploads/fc5f044e-b7c4-4667-8ec6-e121602bf06e/21_5_2024/1718973989148/singlearm-1.glb",
              },
              {
                type: replacePart,
                node: [
                  "singlearm_2",
                  "singlearm_4",
                  "singlearm_6",
                  "singlearm_8",
                  "singlearm_10",
                  "singlearm_12",
                  "singlearm_14",
                  "singlearm_16",
                ],
                isScale: true,
                zoom: 0.3,
                pid: "https://content.helloviewer.io/uploads/2fba5329-a5fa-4878-9ddf-b874872d7e5d/21_5_2024/1718974046451/singlearm-1-180.glb",
              },
              {
                type: "show",
                node: [
                  "singlearm_1",
                  "singlearm_3",
                  "singlearm_5",
                  "singlearm_7",
                  "singlearm_9",
                  "singlearm_11",
                  "singlearm_13",
                  "singlearm_15",
                  "singlearm_2",
                  "singlearm_4",
                  "singlearm_6",
                  "singlearm_8",
                  "singlearm_10",
                  "singlearm_12",
                  "singlearm_14",
                  "singlearm_16",
                ],
              },
              {
                type: "hide",
                node: [
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                  "dualarm_1",
                  "dualarm_3",
                  "dualarm_5",
                  "dualarm_7",
                  "dualarm_9",
                  "dualarm_11",
                  "dualarm_13",
                  "dualarm_15",
                ],
              },
            ],
          },
          {
            name: "DUAL MONITOR ARM-1",
            thumbnail: "/assets/afc/thumnail/dual arm.png",
            rules: [
              {
                type: replacePart,
                node: [
                  "dualarm_1",
                  "dualarm_3",
                  "dualarm_5",
                  "dualarm_7",
                  "dualarm_9",
                  "dualarm_11",
                  "dualarm_13",
                  "dualarm_15",
                ],
                isScale: true,
                zoom: 0.3,
                pid: "https://content.helloviewer.io/uploads/5ac98f00-80e1-4fdf-9445-26da36522405/21_5_2024/1718973630993/Dualarm-1.glb",
              },
              {
                type: replacePart,
                node: [
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                ],
                isScale: true,
                zoom: 0.3,
                pid: "https://content.helloviewer.io/uploads/05d384d4-1332-40c9-8ef8-76945aabd2c2/21_5_2024/1718973689449/Dualarm-1-180.glb",
              },
              {
                type: "hide",
                node: [
                  "singlearm_1",
                  "singlearm_3",
                  "singlearm_5",
                  "singlearm_7",
                  "singlearm_9",
                  "singlearm_11",
                  "singlearm_13",
                  "singlearm_15",
                  "singlearm_2",
                  "singlearm_4",
                  "singlearm_6",
                  "singlearm_8",
                  "singlearm_10",
                  "singlearm_12",
                  "singlearm_14",
                  "singlearm_16",
                ],
              },
              {
                type: "show",
                node: [
                  "dualarm_2",
                  "dualarm_4",
                  "dualarm_6",
                  "dualarm_8",
                  "dualarm_10",
                  "dualarm_12",
                  "dualarm_14",
                  "dualarm_16",
                  "dualarm_1",
                  "dualarm_3",
                  "dualarm_5",
                  "dualarm_7",
                  "dualarm_9",
                  "dualarm_11",
                  "dualarm_13",
                  "dualarm_15",
                ],
              },
            ],
          },

          // {
          //   name: "METAL DUAL PAPER HOLDER",
          //   thumbnail: "/assets/components/nameboard.png",
          //   rules: [
          //     {
          //       type: replacePart,
          //       node: [""],
          //     },

          //   ],
          // },
        ],
      },
    ],
  },
  {
    name: "Pedestal",
    rules: [
      {
        type: hide,
        node: ["LOCK", "NUMERIC_LOCK", "NUMERIC_LOCK2", "LOCK2"],
      },
    ],
    options: [
      {
        name: "SHARING",
        required: false,
        multiSelect: false,
        rules: [],
        options: [
          {
            name: "Pedestal",
            options: [
              {
                name: "none",
                thumbnail: "assets/system/placeholder.svg",
                pid: "",
                disableFeatures: [
                  "Handles",
                  "Locks",
                  "Facia Woods",
                  "Fabrics",
                  "LeGs",
                  "Carcass Woods",
                ],
                enableOptions: [
                  "I Handle",
                  "D Handle",
                  "2 Individual KeyLocks",
                  "2 Individual Numeric Locks",
                ],
                rules: [
                  {
                    type: hide,
                    node: [
                      "ihandle",
                      "front_1",
                      "groovehandle",
                      "flushhandle",
                      "dhandle",
                      "lock_1",
                      "lock_2",
                      "numberlock_1",
                      "numberlock_2",
                      "ped1",
                      "ped3",
                      "ped5",
                      "ped7",
                      "ped9",
                      "ped11",
                      "ped13",
                      "ped15",
                      "ped17",
                      "ped19",
                      "ped21",
                      "ped23",
                      "ped2",
                      "ped4",
                      "ped6",
                      "ped8",
                      "ped10",
                      "ped12",
                      "ped14",
                      "ped16",
                      "ped18",
                      "ped20",
                      "ped22",
                      "ped24",
                    ],
                  },

                  ...sideTableLegRules,
                ],
              },
              {
                name: "2 Equal drawer pedestal",
                thumbnail: "assets/system/placeholder.svg",
                enableFeatures: [
                  "Handles",
                  "Locks",
                  "Facia Woods",
                  "LeGs",
                  "Carcass Woods",
                ],
                disableFeatures: ["Facia Powder CoAt", "Carcass Powder CoAt"],
                enableOptions: [
                  "I Handle",
                  "D Handle",
                  "2 Individual KeyLocks",
                  "2 Individual Numeric Locks",
                ],
                rules: [
                  {
                    type: show,
                    node: ["ped1"],
                  },

                  {
                    type: replacePart,
                    node: [
                      "ped1",
                      "ped3",
                      "ped5",
                      "ped7",
                      "ped9",
                      "ped11",
                      "ped13",
                      "ped15",
                      "ped17",
                      "ped19",
                      "ped21",
                      "ped23",
                    ],
                    pid: "https://content.helloviewer.io/uploads/a29e5f8b-be1b-4a8b-bc86-d254e755f714/14_5_2024/1718343146488/prelam-1.glb",
                  },
                  {
                    type: replacePart,
                    node: [
                      "ped2",
                      "ped4",
                      "ped6",
                      "ped8",
                      "ped10",
                      "ped12",
                      "ped14",
                      "ped16",
                      "ped18",
                      "ped20",
                      "ped22",
                      "ped24",
                    ],
                    pid: "https://content.helloviewer.io/uploads/36b86cee-2d9c-4d6e-a5ab-747890c5d2c9/18_5_2024/1718718919702/prelam-1.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "ihandle",
                      "front_1",
                      "groovehandle",
                      "flushhandle",
                      "dhandle",
                      "lock_1",
                      "lock_2",
                      "numberlock_1",
                      "numberlock_2",

                      "sidewood_1",
                      "sidemetal_1",
                    ],
                  },

                  ...sideTableLegRules,
                ],
              },
              {
                name: "2 Non Equal drawer pedestal",
                thumbnail: "assets/system/placeholder.svg",
                enableFeatures: [
                  "Handles",
                  "Locks",
                  "Facia Woods",
                  "LeGs",
                  "Carcass Woods",
                ],

                disableFeatures: ["Facia Powder CoAt", "Carcass Powder CoAt"],
                enableOptions: [
                  "I Handle",
                  "D Handle",
                  "2 Individual KeyLocks",
                  "2 Individual Numeric Locks",
                ],
                rules: [
                  {
                    type: show,
                    node: ["ped"],
                  },

                  {
                    type: replacePart,
                    node: [
                      "ped1",
                      "ped3",
                      "ped5",
                      "ped7",
                      "ped9",
                      "ped11",
                      "ped13",
                      "ped15",
                      "ped17",
                      "ped19",
                      "ped21",
                      "ped23",
                    ],
                    pid: "https://content.helloviewer.io/uploads/6fd5454c-9207-4efd-baf2-dab3ca61021d/14_5_2024/1718346356122/Prelam-2-Non-equal-pedestals.glb",
                  },
                  {
                    type: replacePart,
                    node: [
                      "ped2",
                      "ped4",
                      "ped6",
                      "ped8",
                      "ped10",
                      "ped12",
                      "ped14",
                      "ped16",
                      "ped18",
                      "ped20",
                      "ped22",
                      "ped24",
                    ],
                    pid: "https://content.helloviewer.io/uploads/fa85cd5c-f9eb-4138-a8cf-2f88ae04258e/18_5_2024/1718719214237/prelam-2.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "ihandle",
                      "front_1",
                      "groovehandle",
                      "flushhandle",
                      "dhandle",
                      "lock_1",
                      "lock_2",
                      "numberlock_1",
                      "numberlock_2",

                      "sidewood_1",
                      "sidemetal_1",
                    ],
                  },

                  ...sideTableLegRules,
                ],
              },
              {
                name: "(1D+1F) DRAWER WITH CASTOR & CUSHION",
                thumbnail: "assets/system/placeholder.svg",
                enableFeatures: [
                  "Handles",
                  "Locks",
                  "Facia Woods",
                  "Fabrics",
                  "Carcass Woods",
                ],
                enableOptions: [
                  "I Handle",
                  "D Handle",
                  "2 Individual KeyLocks",
                  "2 Individual Numeric Locks",
                ],
                rules: [
                  {
                    type: show,
                    node: ["ped"],
                  },

                  {
                    type: replacePart,
                    node: [
                      "ped1",
                      "ped3",
                      "ped5",
                      "ped7",
                      "ped9",
                      "ped11",
                      "ped13",
                      "ped15",
                      "ped17",
                      "ped19",
                      "ped21",
                      "ped23",
                    ],
                    pid: "https://content.helloviewer.io/uploads/810e1f35-32a5-4981-be54-524578d78df0/14_5_2024/1718346501884/Prelam-3-Non-equal-pedestalsCastorcushion.glb",
                  },
                  {
                    type: replacePart,
                    node: [
                      "ped2",
                      "ped4",
                      "ped6",
                      "ped8",
                      "ped10",
                      "ped12",
                      "ped14",
                      "ped16",
                      "ped18",
                      "ped20",
                      "ped22",
                      "ped24",
                    ],
                    pid: "https://content.helloviewer.io/uploads/d7252c80-0d50-4070-bfe2-920009018467/18_5_2024/1718719456705/prelam-3.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "ihandle",
                      "front_1",
                      "groovehandle",
                      "flushhandle",
                      "dhandle",
                      "lock_1",
                      "lock_2",
                      "numberlock_1",
                      "numberlock_2",

                      "sidewood_1",
                      "sidemetal_1",
                    ],
                  },

                  ...sideTableLegRules,
                ],
              },
              {
                name: "(2D+1F) DRAWER WITH CASTOR/ LEVELLER",
                thumbnail: "assets/system/placeholder.svg",
                enableFeatures: [
                  "Handles",
                  "Locks",
                  "Facia Woods",
                  "LeGs",
                  "Carcass Woods",
                ],
                disableFeatures: ["Facia Powder CoAt", "Carcass Powder CoAt"],
                enableOptions: ["I Handle", "D Handle"],
                disableOptions: [
                  "2 Individual KeyLocks",
                  "2 Individual Numeric Locks",
                ],
                rules: [
                  {
                    type: show,
                    node: ["ped"],
                  },

                  {
                    type: replacePart,
                    node: [
                      "ped1",
                      "ped3",
                      "ped5",
                      "ped7",
                      "ped9",
                      "ped11",
                      "ped13",
                      "ped15",
                      "ped17",
                      "ped19",
                      "ped21",
                      "ped23",
                    ],
                    pid: "https://content.helloviewer.io/uploads/e6e2f7d6-7009-44f0-bfc6-c71726b0eb7b/14_5_2024/1718348075772/Prelam-4-2d-1f-.glb",
                  },
                  {
                    type: replacePart,
                    node: [
                      "ped2",
                      "ped4",
                      "ped6",
                      "ped8",
                      "ped10",
                      "ped12",
                      "ped14",
                      "ped16",
                      "ped18",
                      "ped20",
                      "ped22",
                      "ped24",
                    ],
                    pid: "https://content.helloviewer.io/uploads/355f33ac-d591-43b7-ade4-2948e2f9d61e/18_5_2024/1718719595747/prelam-4.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "ihandle",
                      "front_1",
                      "groovehandle",
                      "flushhandle",
                      "dhandle",
                      "lock_1",
                      "lock_2",
                      "numberlock_1",
                      "numberlock_2",

                      "sidewood_1",
                      "sidemetal_1",
                    ],
                  },

                  ...sideTableLegRules,
                ],
              },
              {
                name: "4L EQUAL DRAWER WITH LEVELLER",
                thumbnail: "assets/system/placeholder.svg",
                enableFeatures: [
                  "Handles",
                  "Locks",
                  "Facia Woods",
                  "LeGs",
                  "Carcass Woods",
                ],
                disableFeatures: ["Facia Powder CoAt", "Carcass Powder CoAt"],
                enableOptions: ["I Handle", "D Handle"],
                disableOptions: [
                  "2 Individual KeyLocks",
                  "2 Individual Numeric Locks",
                ],
                rules: [
                  {
                    type: show,
                    node: ["ped"],
                  },

                  {
                    type: replacePart,
                    node: [
                      "ped1",
                      "ped3",
                      "ped5",
                      "ped7",
                      "ped9",
                      "ped11",
                      "ped13",
                      "ped15",
                      "ped17",
                      "ped19",
                      "ped21",
                      "ped23",
                    ],
                    pid: "https://content.helloviewer.io/uploads/b6d2eb27-a6dd-4cc1-b231-ba373fed4c84/18_5_2024/1718694211367/Prelam-5-4d-.glb",
                  },
                  {
                    type: replacePart,
                    node: [
                      "ped2",
                      "ped4",
                      "ped6",
                      "ped8",
                      "ped10",
                      "ped12",
                      "ped14",
                      "ped16",
                      "ped18",
                      "ped20",
                      "ped22",
                      "ped24",
                    ],
                    pid: "https://content.helloviewer.io/uploads/da99344a-6602-409a-9156-55d8c34f9cab/18_5_2024/1718719806434/prelam-5.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "ihandle",
                      "front_1",
                      "groovehandle",
                      "flushhandle",
                      "dhandle",
                      "lock_1",
                      "lock_2",
                      "numberlock_1",
                      "numberlock_2",

                      "sidewood_1",
                      "sidemetal_1",
                    ],
                  },

                  ...sideTableLegRules,
                ],
              },

              {
                name: " 4 EQUAL DRAWER WITH LEVELLER (4 key options)",
                thumbnail: "assets/system/placeholder.svg",
                enableFeatures: [
                  "Handles",
                  "Locks",
                  "Facia Woods",
                  "LeGs",
                  "Carcass Woods",
                ],
                disableFeatures: ["Facia Powder CoAt", "Carcass Powder CoAt"],
                enableOptions: ["I Handle", "D Handle"],
                disableOptions: [
                  "2 Individual KeyLocks",
                  "2 Individual Numeric Locks",
                ],
                rules: [
                  {
                    type: show,
                    node: ["ped"],
                  },

                  {
                    type: replacePart,
                    node: [
                      "ped1",
                      "ped3",
                      "ped5",
                      "ped7",
                      "ped9",
                      "ped11",
                      "ped13",
                      "ped15",
                      "ped17",
                      "ped19",
                      "ped21",
                      "ped23",
                    ],
                    pid: "https://content.helloviewer.io/uploads/f082df19-84d6-401b-8447-458dda5278fb/18_5_2024/1718694272235/Prelam-5-4d-4keyoptions..glb",
                  },
                  {
                    type: replacePart,
                    node: [
                      "ped2",
                      "ped4",
                      "ped6",
                      "ped8",
                      "ped10",
                      "ped12",
                      "ped14",
                      "ped16",
                      "ped18",
                      "ped20",
                      "ped22",
                      "ped24",
                    ],
                    pid: "https://content.helloviewer.io/uploads/ab94aa05-58db-4531-a17e-baf749bffab3/18_5_2024/1718720035058/prelam-6.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "ihandle",
                      "front_1",
                      "groovehandle",
                      "flushhandle",
                      "dhandle",
                      "lock_1",
                      "lock_2",
                      "numberlock_1",
                      "numberlock_2",

                      "sidewood_1",
                      "sidemetal_1",
                    ],
                  },

                  ...sideTableLegRules,
                ],
              },
              {
                name: "Metal Pedestal 2 EQUAL DRAWER PEDESTAL",
                thumbnail: "assets/system/placeholder.svg",
                enableFeatures: [
                  "Handles",
                  "Locks",
                  "LeGs",
                  "Facia Powder CoAt",
                  "Carcass Powder CoAt",
                ],
                disableFeatures: ["Facia Woods", "Carcass Woods"],
                disableOptions: ["I Handle", "D Handle"],
                enableOptions: [
                  "2 Individual KeyLocks",
                  "2 Individual Numeric Locks",
                ],
                rules: [
                  {
                    type: show,
                    node: ["ped"],
                  },
                  {
                    type: replacePart,
                    node: [
                      "ped1",
                      "ped3",
                      "ped5",
                      "ped7",
                      "ped9",
                      "ped11",
                      "ped13",
                      "ped15",
                      "ped17",
                      "ped19",
                      "ped21",
                      "ped23",
                    ],
                    pid: "https://content.helloviewer.io/uploads/b206cc26-6278-4711-bdd1-9dc73dc1a7f9/14_5_2024/1718373683438/2-EQUAL-DRAWER.glb",
                  },
                  {
                    type: replacePart,
                    node: [
                      "ped2",
                      "ped4",
                      "ped6",
                      "ped8",
                      "ped10",
                      "ped12",
                      "ped14",
                      "ped16",
                      "ped18",
                      "ped20",
                      "ped22",
                      "ped24",
                    ],
                    pid: "https://content.helloviewer.io/uploads/6742516d-9fa4-445d-9066-7ce1bcea45cc/19_5_2024/1718769969876/Prelam-1.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "ihandle",
                      "front_1",
                      "groovehandle",
                      "flushhandle",
                      "dhandle",
                      "lock_1",
                      "lock_2",
                      "numberlock_1",
                      "numberlock_2",

                      "sidewood_1",
                      "sidemetal_1",
                    ],
                  },

                  ...sideTableLegRules,
                ],
              },
              {
                name: "Metal Pedestal 2 NON-EQUAL DRAWER",
                thumbnail: "assets/system/placeholder.svg",
                disableOptions: [
                  "I Handle",
                  "D Handle",
                  "2 Individual KeyLocks",
                  "2 Individual Numeric Locks",
                ],
                enableFeatures: [
                  "Handles",
                  "Locks",
                  "LeGs",
                  "Facia Powder CoAt",
                  "Carcass Powder CoAt",
                ],
                disableFeatures: ["Facia Woods", "Carcass Woods"],
                rules: [
                  {
                    type: show,
                    node: ["ped"],
                  },

                  {
                    type: replacePart,
                    node: [
                      "ped1",
                      "ped3",
                      "ped5",
                      "ped7",
                      "ped9",
                      "ped11",
                      "ped13",
                      "ped15",
                      "ped17",
                      "ped19",
                      "ped21",
                      "ped23",
                    ],
                    pid: "https://content.helloviewer.io/uploads/2e175d8b-7886-44d1-b708-e88f3995a18c/14_5_2024/1718373653348/1D-1F-.glb",
                  },
                  {
                    type: replacePart,
                    node: [
                      "ped2",
                      "ped4",
                      "ped6",
                      "ped8",
                      "ped10",
                      "ped12",
                      "ped14",
                      "ped16",
                      "ped18",
                      "ped20",
                      "ped22",
                      "ped24",
                    ],
                    pid: "https://content.helloviewer.io/uploads/c95971fd-c612-4239-aa22-0b81ba69144a/19_5_2024/1718770077386/Prelam-2.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "ihandle",
                      "front_1",
                      "groovehandle",
                      "flushhandle",
                      "dhandle",
                      "lock_1",
                      "lock_2",
                      "numberlock_1",
                      "numberlock_2",

                      "sidewood_1",
                      "sidemetal_1",
                    ],
                  },

                  ...sideTableLegRules,
                ],
              },
              {
                name: "METAL PEDESTAL (1D+1F) DRAWER WITH cushion",
                thumbnail: "assets/system/placeholder.svg",
                disableOptions: ["I Handle", "D Handle"],
                enableFeatures: [
                  "Handles",
                  "Locks",
                  "Facia Woods",
                  "Fabrics",
                  "Carcass Woods",
                ],
                enableOptions: [
                  "2 Individual KeyLocks",
                  "2 Individual Numeric Locks",
                ],
                rules: [
                  {
                    type: show,
                    node: ["ped"],
                  },

                  {
                    type: replacePart,
                    node: [
                      "ped1",
                      "ped3",
                      "ped5",
                      "ped7",
                      "ped9",
                      "ped11",
                      "ped13",
                      "ped15",
                      "ped17",
                      "ped19",
                      "ped21",
                      "ped23",
                    ],
                    pid: "https://content.helloviewer.io/uploads/d96d5d30-6304-4c63-9713-848248538b41/14_5_2024/1718373595217/1D-1F-with-fabric.glb",
                  },
                  {
                    type: replacePart,
                    node: [
                      "ped2",
                      "ped4",
                      "ped6",
                      "ped8",
                      "ped10",
                      "ped12",
                      "ped14",
                      "ped16",
                      "ped18",
                      "ped20",
                      "ped22",
                      "ped24",
                    ],
                    pid: "https://content.helloviewer.io/uploads/68605489-47eb-433f-8657-6eb2b3d3ec04/19_5_2024/1718770126399/Prelam-3.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "ihandle",
                      "front_1",
                      "groovehandle",
                      "flushhandle",
                      "dhandle",
                      "lock_1",
                      "lock_2",
                      "numberlock_1",
                      "numberlock_2",

                      "sidewood_1",
                      "sidemetal_1",
                    ],
                  },

                  ...sideTableLegRules,
                ],
              },
              {
                name: "METAL PEDESTAL (2D+1F) DRAWER",
                thumbnail: "assets/system/placeholder.svg",
                disableOptions: [
                  "I Handle",
                  "D Handle",
                  "2 Individual KeyLocks",
                  "2 Individual Numeric Locks",
                ],
                enableFeatures: [
                  "Handles",
                  "Locks",
                  "LeGs",
                  "Facia Powder CoAt",
                  "Carcass Powder CoAt",
                ],
                disableFeatures: ["Facia Woods", "Carcass Woods"],
                rules: [
                  {
                    type: show,
                    node: ["ped"],
                  },

                  {
                    type: replacePart,
                    node: [
                      "ped1",
                      "ped3",
                      "ped5",
                      "ped7",
                      "ped9",
                      "ped11",
                      "ped13",
                      "ped15",
                      "ped17",
                      "ped19",
                      "ped21",
                      "ped23",
                    ],
                    pid: "https://content.helloviewer.io/uploads/796d8ddd-bb66-444c-9cf1-b9bee62bbb3d/18_5_2024/1718693946834/2D-1F-.glb",
                  },
                  {
                    type: replacePart,
                    node: [
                      "ped2",
                      "ped4",
                      "ped6",
                      "ped8",
                      "ped10",
                      "ped12",
                      "ped14",
                      "ped16",
                      "ped18",
                      "ped20",
                      "ped22",
                      "ped24",
                    ],
                    pid: "https://content.helloviewer.io/uploads/938fabd1-f05a-4913-805a-ec4d958de834/19_5_2024/1718770172204/Prelam-4.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "ihandle",
                      "front_1",
                      "groovehandle",
                      "flushhandle",
                      "dhandle",
                      "lock_1",
                      "lock_2",
                      "numberlock_1",
                      "numberlock_2",

                      "sidewood_1",
                      "sidemetal_1",
                    ],
                  },

                  ...sideTableLegRules,
                ],
              },
              {
                name: "Metal Pedestal 4 EQUAL DRAWER WITH LEVELLER",
                thumbnail: "assets/system/placeholder.svg",
                disableOptions: [
                  "I Handle",
                  "D Handle",
                  "2 Individual KeyLocks",
                  "2 Individual Numeric Locks",
                ],
                enableFeatures: [
                  "Handles",
                  "Locks",
                  "LeGs",
                  "Facia Powder CoAt",
                  "Carcass Powder CoAt",
                ],
                disableFeatures: ["Facia Woods", "Carcass Woods"],
                rules: [
                  {
                    type: show,
                    node: ["ped"],
                  },

                  {
                    type: replacePart,
                    node: [
                      "ped1",
                      "ped3",
                      "ped5",
                      "ped7",
                      "ped9",
                      "ped11",
                      "ped13",
                      "ped15",
                      "ped17",
                      "ped19",
                      "ped21",
                      "ped23",
                    ],
                    pid: "https://content.helloviewer.io/uploads/eb60a3be-1e72-4398-882b-9aac17a377ea/14_5_2024/1718373489628/4D-2Locks.glb",
                  },
                  {
                    type: replacePart,
                    node: [
                      "ped2",
                      "ped4",
                      "ped6",
                      "ped8",
                      "ped10",
                      "ped12",
                      "ped14",
                      "ped16",
                      "ped18",
                      "ped20",
                      "ped22",
                      "ped24",
                    ],
                    pid: "https://content.helloviewer.io/uploads/82b3c53e-8ab3-45a5-935b-23c25832e988/19_5_2024/1718770218230/Prelam-5.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "ihandle",
                      "front_1",
                      "groovehandle",
                      "flushhandle",
                      "dhandle",
                      "lock_1",
                      "lock_2",
                      "numberlock_1",
                      "numberlock_2",

                      "sidewood_1",
                      "sidemetal_1",
                    ],
                  },

                  ...sideTableLegRules,
                ],
              },

              {
                name: "Metal Pedestal 4 EQUAL DRAWER WITH LEVELLER 4 Locks",
                thumbnail: "assets/system/placeholder.svg",
                disableOptions: [
                  "I Handle",
                  "D Handle",
                  "2 Individual KeyLocks",
                  "2 Individual Numeric Locks",
                ],
                enableFeatures: [
                  "Handles",
                  "Locks",
                  "LeGs",
                  "Facia Powder CoAt",
                  "Carcass Powder CoAt",
                ],
                disableFeatures: ["Facia Woods", "Carcass Woods"],
                rules: [
                  {
                    type: show,
                    node: ["ped"],
                  },

                  {
                    type: replacePart,
                    node: [
                      "ped1",
                      "ped3",
                      "ped5",
                      "ped7",
                      "ped9",
                      "ped11",
                      "ped13",
                      "ped15",
                      "ped17",
                      "ped19",
                      "ped21",
                      "ped23",
                    ],
                    pid: "https://content.helloviewer.io/uploads/99eb6779-652f-4676-a993-5c5336a4e6de/14_5_2024/1718373432536/4D-4Locks.glb",
                  },
                  {
                    type: replacePart,
                    node: [
                      "ped2",
                      "ped4",
                      "ped6",
                      "ped8",
                      "ped10",
                      "ped12",
                      "ped14",
                      "ped16",
                      "ped18",
                      "ped20",
                      "ped22",
                      "ped24",
                    ],
                    pid: "https://content.helloviewer.io/uploads/665eda56-ec68-414b-8e65-0ef1d9c59ef7/19_5_2024/1718770250301/Prelam-6.glb",
                  },
                  {
                    type: hide,
                    node: [
                      "ihandle",
                      "front_1",
                      "groovehandle",
                      "flushhandle",
                      "dhandle",
                      "lock_1",
                      "lock_2",
                      "numberlock_1",
                      "numberlock_2",

                      "sidewood_1",
                      "sidemetal_1",
                    ],
                  },

                  ...sideTableLegRules,
                ],
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        multiSelect: false,
        rules: [],
        options: [
          {
            name: "Pedestal",
            options: [
              {
                name: "none",
                thumbnail: "assets/system/placeholder.svg",
                pid: "",
                disableFeatures: [
                  "Handles",
                  "Locks",
                  "Facia Woods",
                  "Fabrics",
                  "LeGs",
                  "Carcass Woods",
                ],
                enableOptions: [
                  "I Handle",
                  "D Handle",
                  "2 Individual KeyLocks",
                  "2 Individual Numeric Locks",
                ],
                rules: [
                  {
                    type: hide,
                    node: [
                      "ihandle",
                      "front_1",
                      "groovehandle",
                      "flushhandle",
                      "dhandle",
                      "lock_1",
                      "lock_2",
                      "numberlock_1",
                      "numberlock_2",
                      "ped1",
                      "ped3",
                      "ped5",
                      "ped7",
                      "ped9",
                      "ped11",
                      "ped13",
                      "ped15",
                      "ped17",
                      "ped19",
                      "ped21",
                      "ped23",
                      "ped2",
                      "ped4",
                      "ped6",
                      "ped8",
                      "ped10",
                      "ped12",
                      "ped14",
                      "ped16",
                      "ped18",
                      "ped20",
                      "ped22",
                      "ped24",
                    ],
                  },

                  ...sideTableLegRules,
                ],
              },
              {
                name: "2 Equal drawer pedestal",
                thumbnail: "assets/system/placeholder.svg",
                enableFeatures: [
                  "Handles",
                  "Locks",
                  "Facia Woods",
                  "LeGs",
                  "Carcass Woods",
                ],
                disableFeatures: ["Facia Powder CoAt", "Carcass Powder CoAt"],
                enableOptions: [
                  "I Handle",
                  "D Handle",
                  "2 Individual KeyLocks",
                  "2 Individual Numeric Locks",
                ],
                rules: [
                  {
                    type: show,
                    node: ["ped1"],
                  },

                  {
                    type: replacePart,
                    node: [
                      "ped1",
                      "ped3",
                      "ped5",
                      "ped7",
                      "ped9",
                      "ped11",
                      "ped13",
                      "ped15",
                      "ped17",
                      "ped19",
                      "ped21",
                      "ped23",
                      "ped2",
                      "ped4",
                      "ped6",
                      "ped8",
                      "ped10",
                      "ped12",
                      "ped14",
                      "ped16",
                      "ped18",
                      "ped20",
                      "ped22",
                      "ped24",
                    ],
                    pid: "https://content.helloviewer.io/uploads/a29e5f8b-be1b-4a8b-bc86-d254e755f714/14_5_2024/1718343146488/prelam-1.glb",
                  },

                  {
                    type: hide,
                    node: [
                      "ihandle",
                      "front_1",
                      "groovehandle",
                      "flushhandle",
                      "dhandle",
                      "lock_1",
                      "lock_2",
                      "numberlock_1",
                      "numberlock_2",

                      "sidewood_1",
                      "sidemetal_1",
                    ],
                  },

                  ...sideTableLegRules,
                ],
              },
              {
                name: "2 Non Equal drawer pedestal",
                thumbnail: "assets/system/placeholder.svg",
                enableFeatures: [
                  "Handles",
                  "Locks",
                  "Facia Woods",
                  "LeGs",
                  "Carcass Woods",
                ],
                disableFeatures: ["Facia Powder CoAt", "Carcass Powder CoAt"],
                enableOptions: [
                  "I Handle",
                  "D Handle",
                  "2 Individual KeyLocks",
                  "2 Individual Numeric Locks",
                ],
                rules: [
                  {
                    type: show,
                    node: ["ped"],
                  },

                  {
                    type: replacePart,
                    node: [
                      "ped1",
                      "ped3",
                      "ped5",
                      "ped7",
                      "ped9",
                      "ped11",
                      "ped13",
                      "ped15",
                      "ped17",
                      "ped19",
                      "ped21",
                      "ped23",
                      "ped2",
                      "ped4",
                      "ped6",
                      "ped8",
                      "ped10",
                      "ped12",
                      "ped14",
                      "ped16",
                      "ped18",
                      "ped20",
                      "ped22",
                      "ped24",
                    ],
                    pid: "https://content.helloviewer.io/uploads/6fd5454c-9207-4efd-baf2-dab3ca61021d/14_5_2024/1718346356122/Prelam-2-Non-equal-pedestals.glb",
                  },

                  {
                    type: hide,
                    node: [
                      "ihandle",
                      "front_1",
                      "groovehandle",
                      "flushhandle",
                      "dhandle",
                      "lock_1",
                      "lock_2",
                      "numberlock_1",
                      "numberlock_2",

                      "sidewood_1",
                      "sidemetal_1",
                    ],
                  },

                  ...sideTableLegRules,
                ],
              },
              {
                name: "(1D+1F) DRAWER WITH CASTOR & CUSHION",
                thumbnail: "assets/system/placeholder.svg",
                enableFeatures: [
                  "Handles",
                  "Locks",
                  "Facia Woods",
                  "Fabrics",
                  "Carcass Woods",
                ],
                enableOptions: [
                  "I Handle",
                  "D Handle",
                  "2 Individual KeyLocks",
                  "2 Individual Numeric Locks",
                ],
                rules: [
                  {
                    type: show,
                    node: ["ped"],
                  },

                  {
                    type: replacePart,
                    node: [
                      "ped1",
                      "ped3",
                      "ped5",
                      "ped7",
                      "ped9",
                      "ped11",
                      "ped13",
                      "ped15",
                      "ped17",
                      "ped19",
                      "ped21",
                      "ped23",
                      "ped2",
                      "ped4",
                      "ped6",
                      "ped8",
                      "ped10",
                      "ped12",
                      "ped14",
                      "ped16",
                      "ped18",
                      "ped20",
                      "ped22",
                      "ped24",
                    ],
                    pid: "https://content.helloviewer.io/uploads/810e1f35-32a5-4981-be54-524578d78df0/14_5_2024/1718346501884/Prelam-3-Non-equal-pedestalsCastorcushion.glb",
                  },

                  {
                    type: hide,
                    node: [
                      "ihandle",
                      "front_1",
                      "groovehandle",
                      "flushhandle",
                      "dhandle",
                      "lock_1",
                      "lock_2",
                      "numberlock_1",
                      "numberlock_2",

                      "sidewood_1",
                      "sidemetal_1",
                    ],
                  },

                  ...sideTableLegRules,
                ],
              },
              {
                name: "(2D+1F) DRAWER WITH CASTOR/ LEVELLER",
                thumbnail: "assets/system/placeholder.svg",
                enableFeatures: [
                  "Handles",
                  "Locks",
                  "Facia Woods",
                  "LeGs",
                  "Carcass Woods",
                ],
                disableFeatures: ["Facia Powder CoAt", "Carcass Powder CoAt"],
                enableOptions: ["I Handle", "D Handle"],
                disableOptions: [
                  "2 Individual KeyLocks",
                  "2 Individual Numeric Locks",
                ],
                rules: [
                  {
                    type: show,
                    node: ["ped"],
                  },

                  {
                    type: replacePart,
                    node: [
                      "ped1",
                      "ped3",
                      "ped5",
                      "ped7",
                      "ped9",
                      "ped11",
                      "ped13",
                      "ped15",
                      "ped17",
                      "ped19",
                      "ped21",
                      "ped23",
                      "ped2",
                      "ped4",
                      "ped6",
                      "ped8",
                      "ped10",
                      "ped12",
                      "ped14",
                      "ped16",
                      "ped18",
                      "ped20",
                      "ped22",
                      "ped24",
                    ],
                    pid: "https://content.helloviewer.io/uploads/e6e2f7d6-7009-44f0-bfc6-c71726b0eb7b/14_5_2024/1718348075772/Prelam-4-2d-1f-.glb",
                  },

                  {
                    type: hide,
                    node: [
                      "ihandle",
                      "front_1",
                      "groovehandle",
                      "flushhandle",
                      "dhandle",
                      "lock_1",
                      "lock_2",
                      "numberlock_1",
                      "numberlock_2",

                      "sidewood_1",
                      "sidemetal_1",
                    ],
                  },

                  ...sideTableLegRules,
                ],
              },
              {
                name: "4L EQUAL DRAWER WITH LEVELLER",
                thumbnail: "assets/system/placeholder.svg",
                enableFeatures: [
                  "Handles",
                  "Locks",
                  "Facia Woods",
                  "LeGs",
                  "Carcass Woods",
                ],
                disableFeatures: ["Facia Powder CoAt", "Carcass Powder CoAt"],
                enableOptions: ["I Handle", "D Handle"],
                disableOptions: [
                  "2 Individual KeyLocks",
                  "2 Individual Numeric Locks",
                ],
                rules: [
                  {
                    type: show,
                    node: ["ped"],
                  },

                  {
                    type: replacePart,
                    node: [
                      "ped1",
                      "ped3",
                      "ped5",
                      "ped7",
                      "ped9",
                      "ped11",
                      "ped13",
                      "ped15",
                      "ped17",
                      "ped19",
                      "ped21",
                      "ped23",
                      "ped2",
                      "ped4",
                      "ped6",
                      "ped8",
                      "ped10",
                      "ped12",
                      "ped14",
                      "ped16",
                      "ped18",
                      "ped20",
                      "ped22",
                      "ped24",
                    ],
                    pid: "https://content.helloviewer.io/uploads/b6d2eb27-a6dd-4cc1-b231-ba373fed4c84/18_5_2024/1718694211367/Prelam-5-4d-.glb",
                  },

                  {
                    type: hide,
                    node: [
                      "ihandle",
                      "front_1",
                      "groovehandle",
                      "flushhandle",
                      "dhandle",
                      "lock_1",
                      "lock_2",
                      "numberlock_1",
                      "numberlock_2",

                      "sidewood_1",
                      "sidemetal_1",
                    ],
                  },

                  ...sideTableLegRules,
                ],
              },

              {
                name: " 4 EQUAL DRAWER WITH LEVELLER (4 key options)",
                thumbnail: "assets/system/placeholder.svg",
                enableFeatures: [
                  "Handles",
                  "Locks",
                  "Facia Woods",
                  "LeGs",
                  "Carcass Woods",
                ],
                disableFeatures: ["Facia Powder CoAt", "Carcass Powder CoAt"],
                enableOptions: ["I Handle", "D Handle"],
                disableOptions: [
                  "2 Individual KeyLocks",
                  "2 Individual Numeric Locks",
                ],
                rules: [
                  {
                    type: show,
                    node: ["ped"],
                  },

                  {
                    type: replacePart,
                    node: [
                      "ped1",
                      "ped3",
                      "ped5",
                      "ped7",
                      "ped9",
                      "ped11",
                      "ped13",
                      "ped15",
                      "ped17",
                      "ped19",
                      "ped21",
                      "ped23",
                      "ped2",
                      "ped4",
                      "ped6",
                      "ped8",
                      "ped10",
                      "ped12",
                      "ped14",
                      "ped16",
                      "ped18",
                      "ped20",
                      "ped22",
                      "ped24",
                    ],
                    pid: "https://content.helloviewer.io/uploads/f082df19-84d6-401b-8447-458dda5278fb/18_5_2024/1718694272235/Prelam-5-4d-4keyoptions..glb",
                  },

                  {
                    type: hide,
                    node: [
                      "ihandle",
                      "front_1",
                      "groovehandle",
                      "flushhandle",
                      "dhandle",
                      "lock_1",
                      "lock_2",
                      "numberlock_1",
                      "numberlock_2",

                      "sidewood_1",
                      "sidemetal_1",
                    ],
                  },

                  ...sideTableLegRules,
                ],
              },
              {
                name: "Metal Pedestal 2 EQUAL DRAWER PEDESTAL",
                thumbnail: "assets/system/placeholder.svg",
                enableFeatures: [
                  "Handles",
                  "Locks",
                  "LeGs",
                  "Facia Powder CoAt",
                  "Carcass Powder CoAt",
                ],
                disableFeatures: ["Facia Woods", "Carcass Woods"],
                disableOptions: ["I Handle", "D Handle"],
                enableOptions: [
                  "2 Individual KeyLocks",
                  "2 Individual Numeric Locks",
                ],
                rules: [
                  {
                    type: show,
                    node: ["ped"],
                  },
                  {
                    type: replacePart,
                    node: [
                      "ped1",
                      "ped3",
                      "ped5",
                      "ped7",
                      "ped9",
                      "ped11",
                      "ped13",
                      "ped15",
                      "ped17",
                      "ped19",
                      "ped21",
                      "ped23",
                      "ped2",
                      "ped4",
                      "ped6",
                      "ped8",
                      "ped10",
                      "ped12",
                      "ped14",
                      "ped16",
                      "ped18",
                      "ped20",
                      "ped22",
                      "ped24",
                    ],
                    pid: "https://content.helloviewer.io/uploads/b206cc26-6278-4711-bdd1-9dc73dc1a7f9/14_5_2024/1718373683438/2-EQUAL-DRAWER.glb",
                  },

                  {
                    type: hide,
                    node: [
                      "ihandle",
                      "front_1",
                      "groovehandle",
                      "flushhandle",
                      "dhandle",
                      "lock_1",
                      "lock_2",
                      "numberlock_1",
                      "numberlock_2",

                      "sidewood_1",
                      "sidemetal_1",
                    ],
                  },

                  ...sideTableLegRules,
                ],
              },
              {
                name: "Metal Pedestal 2 NON-EQUAL DRAWER",
                thumbnail: "assets/system/placeholder.svg",
                disableOptions: [
                  "I Handle",
                  "D Handle",
                  "2 Individual KeyLocks",
                  "2 Individual Numeric Locks",
                ],
                enableFeatures: [
                  "Handles",
                  "Locks",
                  "LeGs",
                  "Facia Powder CoAt",
                  "Carcass Powder CoAt",
                ],
                disableFeatures: ["Facia Woods", "Carcass Woods"],
                rules: [
                  {
                    type: show,
                    node: ["ped"],
                  },

                  {
                    type: replacePart,
                    node: [
                      "ped1",
                      "ped3",
                      "ped5",
                      "ped7",
                      "ped9",
                      "ped11",
                      "ped13",
                      "ped15",
                      "ped17",
                      "ped19",
                      "ped21",
                      "ped23",
                      "ped2",
                      "ped4",
                      "ped6",
                      "ped8",
                      "ped10",
                      "ped12",
                      "ped14",
                      "ped16",
                      "ped18",
                      "ped20",
                      "ped22",
                      "ped24",
                    ],
                    pid: "https://content.helloviewer.io/uploads/2e175d8b-7886-44d1-b708-e88f3995a18c/14_5_2024/1718373653348/1D-1F-.glb",
                  },

                  {
                    type: hide,
                    node: [
                      "ihandle",
                      "front_1",
                      "groovehandle",
                      "flushhandle",
                      "dhandle",
                      "lock_1",
                      "lock_2",
                      "numberlock_1",
                      "numberlock_2",

                      "sidewood_1",
                      "sidemetal_1",
                    ],
                  },

                  ...sideTableLegRules,
                ],
              },
              {
                name: "METAL PEDESTAL (1D+1F) DRAWER WITH cushion",
                thumbnail: "assets/system/placeholder.svg",
                disableOptions: ["I Handle", "D Handle"],
                enableFeatures: [
                  "Handles",
                  "Locks",
                  "Facia Woods",
                  "Fabrics",
                  "Carcass Woods",
                ],
                enableOptions: [
                  "2 Individual KeyLocks",
                  "2 Individual Numeric Locks",
                ],
                rules: [
                  {
                    type: show,
                    node: ["ped"],
                  },

                  {
                    type: replacePart,
                    node: [
                      "ped1",
                      "ped3",
                      "ped5",
                      "ped7",
                      "ped9",
                      "ped11",
                      "ped13",
                      "ped15",
                      "ped17",
                      "ped19",
                      "ped21",
                      "ped23",
                      "ped2",
                      "ped4",
                      "ped6",
                      "ped8",
                      "ped10",
                      "ped12",
                      "ped14",
                      "ped16",
                      "ped18",
                      "ped20",
                      "ped22",
                      "ped24",
                    ],
                    pid: "https://content.helloviewer.io/uploads/d96d5d30-6304-4c63-9713-848248538b41/14_5_2024/1718373595217/1D-1F-with-fabric.glb",
                  },

                  {
                    type: hide,
                    node: [
                      "ihandle",
                      "front_1",
                      "groovehandle",
                      "flushhandle",
                      "dhandle",
                      "lock_1",
                      "lock_2",
                      "numberlock_1",
                      "numberlock_2",

                      "sidewood_1",
                      "sidemetal_1",
                    ],
                  },

                  ...sideTableLegRules,
                ],
              },
              {
                name: "METAL PEDESTAL (2D+1F) DRAWER",
                thumbnail: "assets/system/placeholder.svg",
                disableOptions: [
                  "I Handle",
                  "D Handle",
                  "2 Individual KeyLocks",
                  "2 Individual Numeric Locks",
                ],
                enableFeatures: [
                  "Handles",
                  "Locks",
                  "LeGs",
                  "Facia Powder CoAt",
                  "Carcass Powder CoAt",
                ],
                disableFeatures: ["Facia Woods", "Carcass Woods"],
                rules: [
                  {
                    type: show,
                    node: ["ped"],
                  },

                  {
                    type: replacePart,
                    node: [
                      "ped1",
                      "ped3",
                      "ped5",
                      "ped7",
                      "ped9",
                      "ped11",
                      "ped13",
                      "ped15",
                      "ped17",
                      "ped19",
                      "ped21",
                      "ped23",
                      "ped2",
                      "ped4",
                      "ped6",
                      "ped8",
                      "ped10",
                      "ped12",
                      "ped14",
                      "ped16",
                      "ped18",
                      "ped20",
                      "ped22",
                      "ped24",
                    ],
                    pid: "https://content.helloviewer.io/uploads/796d8ddd-bb66-444c-9cf1-b9bee62bbb3d/18_5_2024/1718693946834/2D-1F-.glb",
                  },

                  {
                    type: hide,
                    node: [
                      "ihandle",
                      "front_1",
                      "groovehandle",
                      "flushhandle",
                      "dhandle",
                      "lock_1",
                      "lock_2",
                      "numberlock_1",
                      "numberlock_2",

                      "sidewood_1",
                      "sidemetal_1",
                    ],
                  },

                  ...sideTableLegRules,
                ],
              },
              {
                name: "Metal Pedestal 4 EQUAL DRAWER WITH LEVELLER",
                thumbnail: "assets/system/placeholder.svg",
                disableOptions: [
                  "I Handle",
                  "D Handle",
                  "2 Individual KeyLocks",
                  "2 Individual Numeric Locks",
                ],
                enableFeatures: [
                  "Handles",
                  "Locks",
                  "LeGs",
                  "Facia Powder CoAt",
                  "Carcass Powder CoAt",
                ],
                disableFeatures: ["Facia Woods", "Carcass Woods"],
                rules: [
                  {
                    type: show,
                    node: ["ped"],
                  },

                  {
                    type: replacePart,
                    node: [
                      "ped1",
                      "ped3",
                      "ped5",
                      "ped7",
                      "ped9",
                      "ped11",
                      "ped13",
                      "ped15",
                      "ped17",
                      "ped19",
                      "ped21",
                      "ped23",
                      "ped2",
                      "ped4",
                      "ped6",
                      "ped8",
                      "ped10",
                      "ped12",
                      "ped14",
                      "ped16",
                      "ped18",
                      "ped20",
                      "ped22",
                      "ped24",
                    ],
                    pid: "https://content.helloviewer.io/uploads/eb60a3be-1e72-4398-882b-9aac17a377ea/14_5_2024/1718373489628/4D-2Locks.glb",
                  },

                  {
                    type: hide,
                    node: [
                      "ihandle",
                      "front_1",
                      "groovehandle",
                      "flushhandle",
                      "dhandle",
                      "lock_1",
                      "lock_2",
                      "numberlock_1",
                      "numberlock_2",

                      "sidewood_1",
                      "sidemetal_1",
                    ],
                  },

                  ...sideTableLegRules,
                ],
              },

              {
                name: "Metal Pedestal 4 EQUAL DRAWER WITH LEVELLER 4 Locks",
                thumbnail: "assets/system/placeholder.svg",
                disableOptions: [
                  "I Handle",
                  "D Handle",
                  "2 Individual KeyLocks",
                  "2 Individual Numeric Locks",
                ],
                enableFeatures: [
                  "Handles",
                  "Locks",
                  "LeGs",
                  "Facia Powder CoAt",
                  "Carcass Powder CoAt",
                ],
                disableFeatures: ["Facia Woods", "Carcass Woods"],
                rules: [
                  {
                    type: show,
                    node: ["ped"],
                  },

                  {
                    type: replacePart,
                    node: [
                      "ped1",
                      "ped3",
                      "ped5",
                      "ped7",
                      "ped9",
                      "ped11",
                      "ped13",
                      "ped15",
                      "ped17",
                      "ped19",
                      "ped21",
                      "ped23",
                      "ped2",
                      "ped4",
                      "ped6",
                      "ped8",
                      "ped10",
                      "ped12",
                      "ped14",
                      "ped16",
                      "ped18",
                      "ped20",
                      "ped22",
                      "ped24",
                    ],
                    pid: "https://content.helloviewer.io/uploads/99eb6779-652f-4676-a993-5c5336a4e6de/14_5_2024/1718373432536/4D-4Locks.glb",
                  },

                  {
                    type: hide,
                    node: [
                      "ihandle",
                      "front_1",
                      "groovehandle",
                      "flushhandle",
                      "dhandle",
                      "lock_1",
                      "lock_2",
                      "numberlock_1",
                      "numberlock_2",

                      "sidewood_1",
                      "sidemetal_1",
                    ],
                  },

                  ...sideTableLegRules,
                ],
              },
            ],
          },
        ],
      },
      ,
    ],
  },
  {
    name: "Pedestal Features",
    optional: false,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Handles",

            options: [
              {
                name: "I Handle",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: show,
                    node: ["ihandle", "front_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "groovehandle",
                      "flushhandle",
                      "dhandle",
                      "horizontalgroove",
                    ],
                  },
                ],
              },
              {
                name: "Groove Handle",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: show,
                    node: ["groovehandle"],
                  },
                  {
                    type: hide,
                    node: [
                      "front_1",
                      "ihandle",
                      "dhandle",
                      "flushhandle",
                      "horizontalgroove",
                    ],
                  },
                ],
              },
              {
                name: "Horizontal Groove",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: show,
                    node: ["horizontalgroove"],
                  },
                  {
                    type: hide,
                    node: [
                      "front_1",
                      "ihandle",
                      "dhandle",
                      "flushhandle",
                      "groovehandle",
                    ],
                  },
                ],
              },
              {
                name: "Flush Handle",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: show,
                    node: ["flushhandle"],
                  },
                  {
                    type: hide,
                    node: [
                      "groovehandle",
                      "front_1",
                      "ihandle",
                      "dhandle",
                      "horizontalgroove",
                    ],
                  },
                ],
              },
              {
                name: "D Handle",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: show,
                    node: ["dhandle", "front_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "groovehandle",
                      "ihandle",
                      "flushhandle",
                      "horizontalgroove",
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "Locks",
            multiSelect: false,
            optional: false,

            options: [
              {
                name: "none",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: hide,
                    node: ["lock_1", "lock_2", "numberlock_1", "numberlock_2"],
                  },
                ],
              },
              {
                name: "Key Lock",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: show,
                    node: ["lock_1"],
                  },
                  {
                    type: hide,
                    node: ["lock_2", "numberlock_1", "numberlock_2"],
                  },
                ],
              },
              {
                name: "2 Individual KeyLocks",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: show,
                    node: ["lock_1", "lock_2"],
                  },
                  {
                    type: hide,
                    node: ["numberlock_1", "numberlock_2"],
                  },
                ],
              },
              {
                name: "Numeric Lock",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: show,
                    node: ["numberlock_1"],
                  },
                  {
                    type: hide,
                    node: ["lock_1", "lock_2", "numberlock_2"],
                  },
                ],
              },
              {
                name: "2 Individual Numeric Locks",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: show,
                    node: ["numberlock_1", "numberlock_2"],
                  },
                  {
                    type: hide,
                    node: ["lock_1", "lock_2"],
                  },
                ],
              },
            ],
          },
          {
            name: "LeGs",
            multiSelect: false,
            optional: false,

            options: [
              // {
              //   name: "none",
              //   thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
              //   rules: [
              //     {
              //       type: hide,
              //       node: ["castor", "leveller"],
              //     },
              //   ],
              // },
              {
                name: "Castor",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: show,
                    node: ["castor", "sidewood", "sidemetal"],
                  },
                  {
                    type: hide,
                    node: ["sidewood_1", "sidemetal_1"],
                  },
                ],
              },
              {
                name: "Leveller",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: hide,
                    node: ["castor", "sidewood", "sidemetal"],
                  },
                  {
                    type: show,
                    node: ["sidewood_1", "sidemetal_1"],
                  },
                ],
              },
            ],
          },
          {
            name: "Fabrics",
            multiSelect: false,
            optional: false,
            options: [
              {
                name: "Caramel",
                thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Caramel.jpg",
                  },
                ],
              },
              {
                name: "Clouds",
                thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Clouds.jpg",
                  },
                ],
              },
              {
                name: "Dago Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
                  },
                ],
              },
              {
                name: "Dago Orange",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
                  },
                ],
              },
              {
                name: "Flame",
                thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Flame.jpg",
                  },
                ],
              },
              {
                name: "Granada",
                thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Granada.jpg",
                  },
                ],
              },
              {
                name: "Greysto",
                thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Greysto.jpg",
                  },
                ],
              },
              {
                name: "Lemon",
                thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Lemon.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
                  },
                ],
              },
              {
                name: "Mink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Mink.jpg",
                  },
                ],
              },
              {
                name: "Olive",
                thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Olive.jpg",
                  },
                ],
              },
              {
                name: "P Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/P_Green.jpg",
                  },
                ],
              },
              {
                name: "Peppermint",
                thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
                  },
                ],
              },
              {
                name: "Pink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Pink.jpg",
                  },
                ],
              },
              {
                name: "Planet",
                thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Planet.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Sangria.jpg",
                  },
                ],
              },
              {
                name: "Stone",
                thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Stone.jpg",
                  },
                ],
              },
              {
                name: "Tangerine",
                thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
                  },
                ],
              },
              {
                name: "Waterfall",
                thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Handles",

            options: [
              {
                name: "I Handle",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: show,
                    node: ["ihandle", "front_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "groovehandle",
                      "flushhandle",
                      "dhandle",
                      "horizontalgroove",
                    ],
                  },
                ],
              },
              {
                name: "Groove Handle",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: show,
                    node: ["groovehandle"],
                  },
                  {
                    type: hide,
                    node: [
                      "front_1",
                      "ihandle",
                      "dhandle",
                      "flushhandle",
                      "horizontalgroove",
                    ],
                  },
                ],
              },
              {
                name: "Horizontal Groove",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: show,
                    node: ["horizontalgroove"],
                  },
                  {
                    type: hide,
                    node: [
                      "front_1",
                      "ihandle",
                      "dhandle",
                      "flushhandle",
                      "groovehandle",
                    ],
                  },
                ],
              },
              {
                name: "Flush Handle",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: show,
                    node: ["flushhandle"],
                  },
                  {
                    type: hide,
                    node: [
                      "groovehandle",
                      "front_1",
                      "ihandle",
                      "dhandle",
                      "horizontalgroove",
                    ],
                  },
                ],
              },
              {
                name: "D Handle",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: show,
                    node: ["dhandle", "front_1"],
                  },
                  {
                    type: hide,
                    node: [
                      "groovehandle",
                      "ihandle",
                      "flushhandle",
                      "horizontalgroove",
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "Locks",
            multiSelect: false,
            optional: false,

            options: [
              {
                name: "none",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: hide,
                    node: ["lock_1", "lock_2", "numberlock_1", "numberlock_2"],
                  },
                ],
              },
              {
                name: "Key Lock",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: show,
                    node: ["lock_1"],
                  },
                  {
                    type: hide,
                    node: ["lock_2", "numberlock_1", "numberlock_2"],
                  },
                ],
              },
              {
                name: "2 Individual KeyLocks",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: show,
                    node: ["lock_1", "lock_2"],
                  },
                  {
                    type: hide,
                    node: ["numberlock_1", "numberlock_2"],
                  },
                ],
              },
              {
                name: "Numeric Lock",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: show,
                    node: ["numberlock_1"],
                  },
                  {
                    type: hide,
                    node: ["lock_1", "lock_2", "numberlock_2"],
                  },
                ],
              },
              {
                name: "2 Individual Numeric Locks",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: show,
                    node: ["numberlock_1", "numberlock_2"],
                  },
                  {
                    type: hide,
                    node: ["lock_1", "lock_2"],
                  },
                ],
              },
            ],
          },
          {
            name: "LeGs",
            multiSelect: false,
            optional: false,

            options: [
              // {
              //   name: "none",
              //   thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
              //   rules: [
              //     {
              //       type: hide,
              //       node: ["castor", "leveller"],
              //     },
              //   ],
              // },
              {
                name: "Castor",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: show,
                    node: ["castor", "sidewood", "sidemetal"],
                  },
                  {
                    type: hide,
                    node: ["sidewood_1", "sidemetal_1"],
                  },
                ],
              },
              {
                name: "Leveller",
                thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
                rules: [
                  {
                    type: hide,
                    node: ["castor", "sidewood", "sidemetal"],
                  },
                  {
                    type: show,
                    node: ["sidewood_1", "sidemetal_1"],
                  },
                ],
              },
            ],
          },
          {
            name: "Fabrics",
            multiSelect: false,
            optional: false,
            options: [
              {
                name: "Caramel",
                thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Caramel.jpg",
                  },
                ],
              },
              {
                name: "Clouds",
                thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Clouds.jpg",
                  },
                ],
              },
              {
                name: "Dago Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
                  },
                ],
              },
              {
                name: "Dago Orange",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
                  },
                ],
              },
              {
                name: "Flame",
                thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Flame.jpg",
                  },
                ],
              },
              {
                name: "Granada",
                thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Granada.jpg",
                  },
                ],
              },
              {
                name: "Greysto",
                thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Greysto.jpg",
                  },
                ],
              },
              {
                name: "Lemon",
                thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Lemon.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
                  },
                ],
              },
              {
                name: "Mink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Mink.jpg",
                  },
                ],
              },
              {
                name: "Olive",
                thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Olive.jpg",
                  },
                ],
              },
              {
                name: "P Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/P_Green.jpg",
                  },
                ],
              },
              {
                name: "Peppermint",
                thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
                  },
                ],
              },
              {
                name: "Pink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Pink.jpg",
                  },
                ],
              },
              {
                name: "Planet",
                thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Planet.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Sangria.jpg",
                  },
                ],
              },
              {
                name: "Stone",
                thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Stone.jpg",
                  },
                ],
              },
              {
                name: "Tangerine",
                thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
                  },
                ],
              },
              {
                name: "Waterfall",
                thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Prelam Pedestal Color Options",
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Facia Woods",
            multiSelect: false,
            optional: false,
            options: [
              {
                // name: "Options",
                // required: false,
                // options: [
                //   {
                name: "Frosty White",
                thumbnail: "/assets/textures/Wood/thumbnail/white.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["frontwood"],
                    textureUrl: "/assets/textures/Wood/2k/white.jpg",
                  },
                ],
              },
              {
                name: "Slate Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/slategrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontwood",
                    textureUrl: "/assets/textures/Wood/2k/slategrey.jpg",
                  },
                ],
              },
              {
                name: "Gothic Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/gothicgrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontwood",
                    textureUrl: "/assets/textures/Wood/2k/gothicgrey.jpg",
                  },
                ],
              },
              {
                name: "Silver Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/silvergrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontwood",
                    textureUrl: "/assets/textures/Wood/2k/silvergrey.jpg",
                  },
                ],
              },
              {
                name: "Highland Pine",
                thumbnail: "/assets/textures/Wood/thumbnail/highland.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontwood",
                    textureUrl: "/assets/textures/Wood/2k/highland.jpg",
                  },
                ],
              },
              {
                name: "Moldau Acacia Light",
                thumbnail: "/assets/textures/Wood/thumbnail/acacialight.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontwood",
                    textureUrl: "/assets/textures/Wood/2k/acacialight.jpg",
                  },
                ],
              },
              {
                name: "Moldau Acacia Dark",
                thumbnail: "/assets/textures/Wood/thumbnail/acaciadark.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontwood",
                    textureUrl: "/assets/textures/Wood/2k/acaciadark.jpg",
                  },
                ],
              },
              {
                name: "Thansau Maple",
                thumbnail: "/assets/textures/Wood/thumbnail/thansau.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontwood",
                    textureUrl: "/assets/textures/Wood/2k/thansau.jpg",
                  },
                ],
              },
              {
                name: "Mangfall Beech",
                thumbnail: "/assets/textures/Wood/thumbnail/mangfall.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontwood",
                    textureUrl: "/assets/textures/Wood/2k/mangfall.jpg",
                  },
                ],
              },
              {
                name: "Flowery Wenge",
                thumbnail: "/assets/textures/Wood/thumbnail/flowerywenge.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontwood",
                    textureUrl: "/assets/textures/Wood/2k/flowerywenge.jpg",
                  },
                ],
              },
              {
                name: "Persian Walnut",
                thumbnail: "/assets/textures/Wood/thumbnail/persian.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontwood",
                    textureUrl: "/assets/textures/Wood/2k/persian.jpg",
                  },
                ],
              },
              {
                name: "Urban Teak",
                thumbnail: "/assets/textures/Wood/thumbnail/urban.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontwood",
                    textureUrl: "/assets/textures/Wood/2k/urban.jpg",
                  },
                ],
              },
              // ],
              // },
            ],
          },
          {
            name: "Carcass Woods",
            multiSelect: false,
            optional: false,
            options: [
              {
                // name: "Options",
                // required: false,
                // options: [
                //   {
                name: "Frosty White",
                thumbnail: "/assets/textures/Wood/thumbnail/white.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["sidewood", "topwood"],
                    textureUrl: "/assets/textures/Wood/2k/white.jpg",
                  },
                ],
              },
              {
                name: "Slate Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/slategrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["sidewood", "topwood"],
                    textureUrl: "/assets/textures/Wood/2k/slategrey.jpg",
                  },
                ],
              },
              {
                name: "Gothic Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/gothicgrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["sidewood", "topwood"],
                    textureUrl: "/assets/textures/Wood/2k/gothicgrey.jpg",
                  },
                ],
              },
              {
                name: "Silver Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/silvergrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["sidewood", "topwood"],
                    textureUrl: "/assets/textures/Wood/2k/silvergrey.jpg",
                  },
                ],
              },
              {
                name: "Highland Pine",
                thumbnail: "/assets/textures/Wood/thumbnail/highland.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["sidewood", "topwood"],
                    textureUrl: "/assets/textures/Wood/2k/highland.jpg",
                  },
                ],
              },
              {
                name: "Moldau Acacia Light",
                thumbnail: "/assets/textures/Wood/thumbnail/acacialight.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["sidewood", "topwood"],
                    textureUrl: "/assets/textures/Wood/2k/acacialight.jpg",
                  },
                ],
              },
              {
                name: "Moldau Acacia Dark",
                thumbnail: "/assets/textures/Wood/thumbnail/acaciadark.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["sidewood", "topwood"],
                    textureUrl: "/assets/textures/Wood/2k/acaciadark.jpg",
                  },
                ],
              },
              {
                name: "Thansau Maple",
                thumbnail: "/assets/textures/Wood/thumbnail/thansau.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["sidewood", "topwood"],
                    textureUrl: "/assets/textures/Wood/2k/thansau.jpg",
                  },
                ],
              },
              {
                name: "Mangfall Beech",
                thumbnail: "/assets/textures/Wood/thumbnail/mangfall.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["sidewood", "topwood"],
                    textureUrl: "/assets/textures/Wood/2k/mangfall.jpg",
                  },
                ],
              },
              {
                name: "Flowery Wenge",
                thumbnail: "/assets/textures/Wood/thumbnail/flowerywenge.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["sidewood", "topwood"],
                    textureUrl: "/assets/textures/Wood/2k/flowerywenge.jpg",
                  },
                ],
              },
              {
                name: "Persian Walnut",
                thumbnail: "/assets/textures/Wood/thumbnail/persian.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["sidewood", "topwood"],
                    textureUrl: "/assets/textures/Wood/2k/persian.jpg",
                  },
                ],
              },
              {
                name: "Urban Teak",
                thumbnail: "/assets/textures/Wood/thumbnail/urban.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["sidewood", "topwood"],
                    textureUrl: "/assets/textures/Wood/2k/urban.jpg",
                  },
                ],
              },
              // ],
              // },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Facia Woods",
            multiSelect: false,
            optional: false,
            options: [
              {
                // name: "Options",
                // required: false,
                // options: [
                //   {
                name: "Frosty White",
                thumbnail: "/assets/textures/Wood/thumbnail/white.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["frontwood"],
                    textureUrl: "/assets/textures/Wood/2k/white.jpg",
                  },
                ],
              },
              {
                name: "Slate Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/slategrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontwood",
                    textureUrl: "/assets/textures/Wood/2k/slategrey.jpg",
                  },
                ],
              },
              {
                name: "Gothic Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/gothicgrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontwood",
                    textureUrl: "/assets/textures/Wood/2k/gothicgrey.jpg",
                  },
                ],
              },
              {
                name: "Silver Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/silvergrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontwood",
                    textureUrl: "/assets/textures/Wood/2k/silvergrey.jpg",
                  },
                ],
              },
              {
                name: "Highland Pine",
                thumbnail: "/assets/textures/Wood/thumbnail/highland.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontwood",
                    textureUrl: "/assets/textures/Wood/2k/highland.jpg",
                  },
                ],
              },
              {
                name: "Moldau Acacia Light",
                thumbnail: "/assets/textures/Wood/thumbnail/acacialight.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontwood",
                    textureUrl: "/assets/textures/Wood/2k/acacialight.jpg",
                  },
                ],
              },
              {
                name: "Moldau Acacia Dark",
                thumbnail: "/assets/textures/Wood/thumbnail/acaciadark.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontwood",
                    textureUrl: "/assets/textures/Wood/2k/acaciadark.jpg",
                  },
                ],
              },
              {
                name: "Thansau Maple",
                thumbnail: "/assets/textures/Wood/thumbnail/thansau.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontwood",
                    textureUrl: "/assets/textures/Wood/2k/thansau.jpg",
                  },
                ],
              },
              {
                name: "Mangfall Beech",
                thumbnail: "/assets/textures/Wood/thumbnail/mangfall.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontwood",
                    textureUrl: "/assets/textures/Wood/2k/mangfall.jpg",
                  },
                ],
              },
              {
                name: "Flowery Wenge",
                thumbnail: "/assets/textures/Wood/thumbnail/flowerywenge.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontwood",
                    textureUrl: "/assets/textures/Wood/2k/flowerywenge.jpg",
                  },
                ],
              },
              {
                name: "Persian Walnut",
                thumbnail: "/assets/textures/Wood/thumbnail/persian.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontwood",
                    textureUrl: "/assets/textures/Wood/2k/persian.jpg",
                  },
                ],
              },
              {
                name: "Urban Teak",
                thumbnail: "/assets/textures/Wood/thumbnail/urban.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontwood",
                    textureUrl: "/assets/textures/Wood/2k/urban.jpg",
                  },
                ],
              },
              // ],
              // },
            ],
          },
          {
            name: "Carcass Woods",
            multiSelect: false,
            optional: false,
            options: [
              {
                // name: "Options",
                // required: false,
                // options: [
                //   {
                name: "Frosty White",
                thumbnail: "/assets/textures/Wood/thumbnail/white.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["sidewood", "topwood"],
                    textureUrl: "/assets/textures/Wood/2k/white.jpg",
                  },
                ],
              },
              {
                name: "Slate Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/slategrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["sidewood", "topwood"],
                    textureUrl: "/assets/textures/Wood/2k/slategrey.jpg",
                  },
                ],
              },
              {
                name: "Gothic Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/gothicgrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["sidewood", "topwood"],
                    textureUrl: "/assets/textures/Wood/2k/gothicgrey.jpg",
                  },
                ],
              },
              {
                name: "Silver Grey",
                thumbnail: "/assets/textures/Wood/thumbnail/silvergrey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["sidewood", "topwood"],
                    textureUrl: "/assets/textures/Wood/2k/silvergrey.jpg",
                  },
                ],
              },
              {
                name: "Highland Pine",
                thumbnail: "/assets/textures/Wood/thumbnail/highland.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["sidewood", "topwood"],
                    textureUrl: "/assets/textures/Wood/2k/highland.jpg",
                  },
                ],
              },
              {
                name: "Moldau Acacia Light",
                thumbnail: "/assets/textures/Wood/thumbnail/acacialight.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["sidewood", "topwood"],
                    textureUrl: "/assets/textures/Wood/2k/acacialight.jpg",
                  },
                ],
              },
              {
                name: "Moldau Acacia Dark",
                thumbnail: "/assets/textures/Wood/thumbnail/acaciadark.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["sidewood", "topwood"],
                    textureUrl: "/assets/textures/Wood/2k/acaciadark.jpg",
                  },
                ],
              },
              {
                name: "Thansau Maple",
                thumbnail: "/assets/textures/Wood/thumbnail/thansau.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["sidewood", "topwood"],
                    textureUrl: "/assets/textures/Wood/2k/thansau.jpg",
                  },
                ],
              },
              {
                name: "Mangfall Beech",
                thumbnail: "/assets/textures/Wood/thumbnail/mangfall.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["sidewood", "topwood"],
                    textureUrl: "/assets/textures/Wood/2k/mangfall.jpg",
                  },
                ],
              },
              {
                name: "Flowery Wenge",
                thumbnail: "/assets/textures/Wood/thumbnail/flowerywenge.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["sidewood", "topwood"],
                    textureUrl: "/assets/textures/Wood/2k/flowerywenge.jpg",
                  },
                ],
              },
              {
                name: "Persian Walnut",
                thumbnail: "/assets/textures/Wood/thumbnail/persian.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["sidewood", "topwood"],
                    textureUrl: "/assets/textures/Wood/2k/persian.jpg",
                  },
                ],
              },
              {
                name: "Urban Teak",
                thumbnail: "/assets/textures/Wood/thumbnail/urban.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: ["sidewood", "topwood"],
                    textureUrl: "/assets/textures/Wood/2k/urban.jpg",
                  },
                ],
              },
              // ],
              // },
            ],
          },
          {
            name: "Fabrics",
            multiSelect: false,
            optional: false,
            options: [
              {
                name: "Caramel",
                thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Caramel.jpg",
                  },
                ],
              },
              {
                name: "Clouds",
                thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Clouds.jpg",
                  },
                ],
              },
              {
                name: "Dago Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
                  },
                ],
              },
              {
                name: "Dago Orange",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
                  },
                ],
              },
              {
                name: "Dolphin",
                thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
                  },
                ],
              },
              {
                name: "Flame",
                thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Flame.jpg",
                  },
                ],
              },
              {
                name: "Granada",
                thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Granada.jpg",
                  },
                ],
              },
              {
                name: "Greysto",
                thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Greysto.jpg",
                  },
                ],
              },
              {
                name: "Lemon",
                thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Lemon.jpg",
                  },
                ],
              },
              {
                name: "Med Blue",
                thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
                  },
                ],
              },
              {
                name: "Mink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Mink.jpg",
                  },
                ],
              },
              {
                name: "Olive",
                thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Olive.jpg",
                  },
                ],
              },
              {
                name: "P Green",
                thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/P_Green.jpg",
                  },
                ],
              },
              {
                name: "Peppermint",
                thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
                  },
                ],
              },
              {
                name: "Pink",
                thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Pink.jpg",
                  },
                ],
              },
              {
                name: "Planet",
                thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Planet.jpg",
                  },
                ],
              },
              {
                name: "Sangria",
                thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Sangria.jpg",
                  },
                ],
              },
              {
                name: "Stone",
                thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Stone.jpg",
                  },
                ],
              },
              {
                name: "Tangerine",
                thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
                  },
                ],
              },
              {
                name: "Waterfall",
                thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "fabric.002",
                    textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Metal Pedestal Color Options",
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Carcass Powder Coat",
            multiSelect: false,
            optional: false,
            options: [
              {
                name: "AFC Black",
                thumbnail: "/assets/textures/Metal/thumbnail/black.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "sidemetal",
                    textureUrl: "/assets/textures/Metal/black.jpeg",
                  },
                ],
              },
              {
                name: "AFC White",
                thumbnail: "/assets/textures/Metal/thumbnail/white.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "sidemetal",
                    textureUrl: "/assets/textures/Metal/white.jpeg",
                  },
                ],
              },
              {
                name: "AFC Munshell Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/munshellgrey.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "sidemetal",
                    textureUrl: "/assets/textures/Metal/munshellgrey.jpeg",
                  },
                ],
              },
              {
                name: "AFC Graphite Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/graphitegrey.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "sidemetal",
                    textureUrl: "/assets/textures/Metal/graphitegrey.jpeg",
                  },
                ],
              },
              {
                name: "AFC Silver Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/silvergrey.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "sidemetal",
                    textureUrl: "/assets/textures/Metal/silvergrey.jpeg",
                  },
                ],
              },
              {
                name: "AFC DA Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/dagrey.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "sidemetal",
                    textureUrl: "/assets/textures/Metal/dagrey.jpeg",
                  },
                ],
              },
              {
                name: "AFC Sky Blue",
                thumbnail: "/assets/textures/Metal/thumbnail/blue.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "sidemetal",
                    textureUrl: "/assets/textures/Metal/blue.jpeg",
                  },
                ],
              },
              {
                name: "AFC Sea Green",
                thumbnail: "/assets/textures/Metal/thumbnail/green.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "sidemetal",
                    textureUrl: "/assets/textures/Metal/green.jpeg",
                  },
                ],
              },
              {
                name: "AFC Light Orange",
                thumbnail: "/assets/textures/Metal/thumbnail/orange.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "sidemetal",
                    textureUrl: "/assets/textures/Metal/orange.jpeg",
                  },
                ],
              },
              {
                name: "AFC Misty Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/mistygrey.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "sidemetal",
                    textureUrl: "/assets/textures/Metal/mistygrey.jpeg",
                  },
                ],
              },
              {
                name: "AFC Nut Brown",
                thumbnail: "/assets/textures/Metal/thumbnail/nutbrown.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "sidemetal",
                    textureUrl: "/assets/textures/Metal/nutbrown.jpeg",
                  },
                ],
              },
            ],
          },
          {
            name: "Facia Powder Coat",
            multiSelect: false,
            optional: false,
            options: [
              {
                name: "AFC Black",
                thumbnail: "/assets/textures/Metal/thumbnail/black.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontmetal",
                    textureUrl: "/assets/textures/Metal/black.jpeg",
                  },
                ],
              },
              {
                name: "AFC White",
                thumbnail: "/assets/textures/Metal/thumbnail/white.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontmetal",
                    textureUrl: "/assets/textures/Metal/white.jpeg",
                  },
                ],
              },
              {
                name: "AFC Munshell Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/munshellgrey.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontmetal",
                    textureUrl: "/assets/textures/Metal/munshellgrey.jpeg",
                  },
                ],
              },
              {
                name: "AFC Graphite Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/graphitegrey.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontmetal",
                    textureUrl: "/assets/textures/Metal/graphitegrey.jpeg",
                  },
                ],
              },
              {
                name: "AFC Silver Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/silvergrey.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontmetal",
                    textureUrl: "/assets/textures/Metal/silvergrey.jpeg",
                  },
                ],
              },
              {
                name: "AFC DA Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/dagrey.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontmetal",
                    textureUrl: "/assets/textures/Metal/dagrey.jpeg",
                  },
                ],
              },
              {
                name: "AFC Sky Blue",
                thumbnail: "/assets/textures/Metal/thumbnail/blue.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontmetal",
                    textureUrl: "/assets/textures/Metal/blue.jpeg",
                  },
                ],
              },
              {
                name: "AFC Sea Green",
                thumbnail: "/assets/textures/Metal/thumbnail/green.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontmetal",
                    textureUrl: "/assets/textures/Metal/green.jpeg",
                  },
                ],
              },
              {
                name: "AFC Light Orange",
                thumbnail: "/assets/textures/Metal/thumbnail/orange.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontmetal",
                    textureUrl: "/assets/textures/Metal/orange.jpeg",
                  },
                ],
              },
              {
                name: "AFC Misty Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/mistygrey.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontmetal",
                    textureUrl: "/assets/textures/Metal/mistygrey.jpeg",
                  },
                ],
              },
              {
                name: "AFC Nut Brown",
                thumbnail: "/assets/textures/Metal/thumbnail/nutbrown.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontmetal",
                    textureUrl: "/assets/textures/Metal/nutbrown.jpeg",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Carcass Powder Coat",
            multiSelect: false,
            optional: false,
            options: [
              {
                name: "AFC Black",
                thumbnail: "/assets/textures/Metal/thumbnail/black.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "sidemetal",
                    textureUrl: "/assets/textures/Metal/black.jpeg",
                  },
                ],
              },
              {
                name: "AFC White",
                thumbnail: "/assets/textures/Metal/thumbnail/white.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "sidemetal",
                    textureUrl: "/assets/textures/Metal/white.jpeg",
                  },
                ],
              },
              {
                name: "AFC Munshell Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/munshellgrey.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "sidemetal",
                    textureUrl: "/assets/textures/Metal/munshellgrey.jpeg",
                  },
                ],
              },
              {
                name: "AFC Graphite Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/graphitegrey.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "sidemetal",
                    textureUrl: "/assets/textures/Metal/graphitegrey.jpeg",
                  },
                ],
              },
              {
                name: "AFC Silver Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/silvergrey.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "sidemetal",
                    textureUrl: "/assets/textures/Metal/silvergrey.jpeg",
                  },
                ],
              },
              {
                name: "AFC DA Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/dagrey.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "sidemetal",
                    textureUrl: "/assets/textures/Metal/dagrey.jpeg",
                  },
                ],
              },
              {
                name: "AFC Sky Blue",
                thumbnail: "/assets/textures/Metal/thumbnail/blue.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "sidemetal",
                    textureUrl: "/assets/textures/Metal/blue.jpeg",
                  },
                ],
              },
              {
                name: "AFC Sea Green",
                thumbnail: "/assets/textures/Metal/thumbnail/green.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "sidemetal",
                    textureUrl: "/assets/textures/Metal/green.jpeg",
                  },
                ],
              },
              {
                name: "AFC Light Orange",
                thumbnail: "/assets/textures/Metal/thumbnail/orange.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "sidemetal",
                    textureUrl: "/assets/textures/Metal/orange.jpeg",
                  },
                ],
              },
              {
                name: "AFC Misty Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/mistygrey.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "sidemetal",
                    textureUrl: "/assets/textures/Metal/mistygrey.jpeg",
                  },
                ],
              },
              {
                name: "AFC Nut Brown",
                thumbnail: "/assets/textures/Metal/thumbnail/nutbrown.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "sidemetal",
                    textureUrl: "/assets/textures/Metal/nutbrown.jpeg",
                  },
                ],
              },
            ],
          },
          {
            name: "Facia Powder Coat",
            multiSelect: false,
            optional: false,
            options: [
              {
                name: "AFC Black",
                thumbnail: "/assets/textures/Metal/thumbnail/black.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontmetal",
                    textureUrl: "/assets/textures/Metal/black.jpeg",
                  },
                ],
              },
              {
                name: "AFC White",
                thumbnail: "/assets/textures/Metal/thumbnail/white.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontmetal",
                    textureUrl: "/assets/textures/Metal/white.jpeg",
                  },
                ],
              },
              {
                name: "AFC Munshell Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/munshellgrey.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontmetal",
                    textureUrl: "/assets/textures/Metal/munshellgrey.jpeg",
                  },
                ],
              },
              {
                name: "AFC Graphite Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/graphitegrey.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontmetal",
                    textureUrl: "/assets/textures/Metal/graphitegrey.jpeg",
                  },
                ],
              },
              {
                name: "AFC Silver Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/silvergrey.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontmetal",
                    textureUrl: "/assets/textures/Metal/silvergrey.jpeg",
                  },
                ],
              },
              {
                name: "AFC DA Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/dagrey.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontmetal",
                    textureUrl: "/assets/textures/Metal/dagrey.jpeg",
                  },
                ],
              },
              {
                name: "AFC Sky Blue",
                thumbnail: "/assets/textures/Metal/thumbnail/blue.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontmetal",
                    textureUrl: "/assets/textures/Metal/blue.jpeg",
                  },
                ],
              },
              {
                name: "AFC Sea Green",
                thumbnail: "/assets/textures/Metal/thumbnail/green.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontmetal",
                    textureUrl: "/assets/textures/Metal/green.jpeg",
                  },
                ],
              },
              {
                name: "AFC Light Orange",
                thumbnail: "/assets/textures/Metal/thumbnail/orange.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontmetal",
                    textureUrl: "/assets/textures/Metal/orange.jpeg",
                  },
                ],
              },
              {
                name: "AFC Misty Grey",
                thumbnail: "/assets/textures/Metal/thumbnail/mistygrey.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontmetal",
                    textureUrl: "/assets/textures/Metal/mistygrey.jpeg",
                  },
                ],
              },
              {
                name: "AFC Nut Brown",
                thumbnail: "/assets/textures/Metal/thumbnail/nutbrown.jpeg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "frontmetal",
                    textureUrl: "/assets/textures/Metal/nutbrown.jpeg",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]);

export type ConfigData = typeof ConfData3;
